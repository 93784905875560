import { DashboardLayout } from "app/layout"
import { StyledItem } from "../clientsProfile/style"
import { BackButton } from "shared/components/BackButton"
import { useNavigate } from "react-router-dom"
import { BookingsProfile } from "features/BookingsProfile"

export const Booking = () => {

    const navigate = useNavigate();

    const onBackPage = () => {
        navigate(-1);
    };
    
    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton
                        text="Назад"
                        onClick={onBackPage}
                    />
                    <div className="dashboard__header-title">Заказ</div>
                </StyledItem>
            </div>
        }>
            <BookingsProfile />
        </DashboardLayout>
    )
}