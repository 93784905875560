export const PaymentEndpoint = {
    GetPayments: '/api/v1/payments/payment/',
    CreateCashPayment: '/api/v1/payments/payment/cash',
    CreateCertificatePayment: '/api/v1/payments/payment/certificate',
    CreateOnlinePayment: '/api/v1/payments/payment/online',
    CreateAccountPayment: '/api/v1/payments/payment/account',
    ConfirmOnlinePayment: '/api/v1/payments/payment/account/confirm/',
    GetPaymentsByOrderId: '/api/v1/payments/payment/order/',
    GetPaymentMethods: '/api/v1/payments/payment/methods',
    RefundPayment: (order_id: number) => `/api/v1/payments/payment/order/${order_id}/refund`,
}
