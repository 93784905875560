import { axiosApi } from 'shared/api/axios'
import { BookingEndpoint } from './constants'
import { UpdateNomenclatureQuery, UpdateNomenclatureRequest } from '../model'
import { adaptUpdateNomenclatureQuery } from '../model/adapters/adaptUpdateNomenclatureQuery'

export const updateBookingNomenclature = async (
    query: UpdateNomenclatureQuery,
    data: UpdateNomenclatureRequest,
) => {
    try {
        const response = await axiosApi.patch(
            `${BookingEndpoint.nomenclature}${query.bookingNomenclatureId}`,
            {
                count: data.count,
                absolut_discount: data.absolutDiscount,
                percent_discount: data.percentDiscount,
            },
            {
                params: adaptUpdateNomenclatureQuery(query),
            },
        )
        return response.data
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
