import { CreateOnlinePaymentRequestDTO } from 'entities/payments/model/dtoTypes'
import { CreateOnlinePaymentRequest } from 'entities/payments/model/types'

export const adaptCreateOnlinePaymentRequest = (
    body: CreateOnlinePaymentRequest,
): CreateOnlinePaymentRequestDTO => ({
    redirect_url: body.redirectUrl,
    amount: body.amount,
    order_id: body.orderId,
    comment: body.comment,
})
