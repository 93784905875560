export const columns = [
    {
        header: "",
        id: "photo",
        cell: (row: any) => {
            const value = row.renderValue();
            return (
                <div className="bodyCellImg">
                    {typeof value === 'object' ? <div className="bodyCellImgBg"></div> : <img src={value} alt={"Фото"} />}
                </div>
            )
        },
        accessorKey: "user_ad_personal_info.profile_photo.read_url"
    },
    {
        header: "ФИО",
        id: "surname",
        cell: (row: any) => {
            const {surname, first_name, patronymic} = row.row.original.user_ad_personal_info;
            return `${surname} ${first_name} ${patronymic}`;
        },
        accessorKey: "user_ad_personal_info.first_name"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "phone"
    },
    {
        header: "Почта",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "email"
    },
    {
        header: "Роль",
        id: "name",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "role.name"
    },
]