import { axiosApi } from "shared/api/axios";

export interface ApiResponse {
    status: number;
    data?: any;
    error?: string;
}

export const postNomenclature = async (values: object): Promise<ApiResponse> => {
    try {
        const response = await axiosApi.post("/api/v1/nomenclatures/nomenclature/", values);
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error: any) {
        return {
            status: error.response?.status || 500,
            error: error.message,
        };
    }
}