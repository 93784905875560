import React from 'react'

const PlusIcon = () => (
    <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5 8.99951H13M9 4.99951L9 12.9995'
            stroke='#363853'
            stroke-width='1.5'
            strokeLinecap='round'
        />
    </svg>
)

export default PlusIcon
