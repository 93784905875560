import { CreateCashPaymentRequestDTO } from 'entities/payments/model/dtoTypes'
import { CreateCashPaymentRequest } from 'entities/payments/model/types'

export const adaptCreateCashPaymentRequest = (
    body: CreateCashPaymentRequest,
): CreateCashPaymentRequestDTO => ({
    amount: body.amount,
    order_id: body.orderId,
    comment: body.comment,
})
