import { AddNomenclaturesResponse } from '../types'
import { AddNomenclaturesResponseDTO } from '../dtoTypes'

export const adaptAddNomenclaturesResponseDTO = (
    addNomenclaturesResponse: AddNomenclaturesResponseDTO,
): AddNomenclaturesResponse => ({
    id: addNomenclaturesResponse.id,
    bookingId: addNomenclaturesResponse.booking_id,
    nomenclatureId: addNomenclaturesResponse.nomenclature_id,
    name: addNomenclaturesResponse?.name ?? null,
    absolutDiscount: addNomenclaturesResponse.absolut_discount,
    percentDiscount: addNomenclaturesResponse.percent_discount,
    count: addNomenclaturesResponse.count,
    totalPrice: parseFloat(addNomenclaturesResponse?.total_price ?? 0),
    totalPriceWithDiscount: parseFloat(
        addNomenclaturesResponse?.total_price_with_discount ?? 0,
    ),
})
