import { useThemeContext } from "app/providers/providers";

const SettingIcon = () => {
    const { theme } = useThemeContext();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M17.1897 5.86661C17.1897 4.82299 16.427 3.93604 15.3951 3.77975C15.1332 3.74008 14.8668 3.74008 14.6049 3.77975C13.573 3.93604 12.8103 4.82299 12.8103 5.86662V7.34246C12.2208 7.51072 11.6592 7.74522 11.1341 8.03741L10.0901 6.99339C9.3521 6.25543 8.18561 6.16759 7.34547 6.78671C7.13222 6.94386 6.94386 7.13222 6.78671 7.34547C6.16759 8.18562 6.25542 9.35212 6.99338 10.0901L8.0374 11.1341C7.74522 11.6592 7.51072 12.2208 7.34246 12.8103H5.86662C4.82299 12.8103 3.93604 13.573 3.77975 14.6049C3.74008 14.8668 3.74008 15.1332 3.77975 15.3951C3.93604 16.427 4.82299 17.1897 5.86661 17.1897H7.34245C7.51071 17.7792 7.74522 18.3408 8.0374 18.8659L6.99339 19.9099C6.25543 20.6479 6.16759 21.8144 6.78672 22.6545C6.94386 22.8678 7.13223 23.0562 7.34547 23.2133C8.18562 23.8324 9.35211 23.7446 10.0901 23.0066L11.1341 21.9626C11.6592 22.2548 12.2208 22.4893 12.8103 22.6576V24.1334C12.8103 25.177 13.573 26.064 14.6049 26.2203C14.8668 26.2599 15.1332 26.2599 15.3951 26.2203C16.427 26.064 17.1897 25.177 17.1897 24.1334V22.6576C17.7792 22.4893 18.3408 22.2548 18.8659 21.9626L19.9099 23.0066C20.6479 23.7446 21.8144 23.8324 22.6545 23.2133C22.8678 23.0562 23.0562 22.8678 23.2133 22.6545C23.8324 21.8144 23.7446 20.6479 23.0066 19.91L21.9626 18.8659C22.2548 18.3408 22.4893 17.7792 22.6576 17.1897H24.1334C25.177 17.1897 26.064 16.427 26.2203 15.3951C26.2599 15.1332 26.2599 14.8668 26.2203 14.6049C26.064 13.573 25.177 12.8103 24.1334 12.8103H22.6576C22.4893 12.2208 22.2548 11.6592 21.9626 11.1341L23.0066 10.0901C23.7446 9.35211 23.8324 8.18561 23.2133 7.34547C23.0562 7.13222 22.8678 6.94386 22.6546 6.78671C21.8144 6.16759 20.6479 6.25542 19.9099 6.99338L18.8659 8.0374C18.3408 7.74522 17.7792 7.51072 17.1897 7.34245V5.86661Z" stroke={theme.themeColors.main}strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.875 14.7656C11.875 13.0667 13.2741 11.6895 15 11.6895C16.7259 11.6895 18.125 13.0667 18.125 14.7656C18.125 16.4645 16.7259 17.8418 15 17.8418C13.2741 17.8418 11.875 16.4645 11.875 14.7656Z" stroke={theme.themeColors.main}/>
        </svg>
    )
}

export default SettingIcon;