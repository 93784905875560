import { OrganisationWithKPPDTO } from 'entities/organisations/model/dtoTypes'
import { OrganisationWithKPP } from 'entities/organisations/model/types'

export const adaptOrganisationWithKPPDto = (
    source: OrganisationWithKPPDTO,
): OrganisationWithKPP => ({
    id: source.id,
    name: source.name,
    ceo: source.ceo,
    inn: source.inn,
    kpp: source.kpp,
    legalAddress: source.legal_address,
})
