import { useThemeContext } from 'app/providers/providers';
import React from 'react';

const ExitIcon = () => {
    const { theme } = useThemeContext();
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M24.1526 15.4935L10.8406 15.4935M10.8406 15.4935L16.3932 20.3342M10.8406 15.4935L16.3932 10.6528" stroke={theme.themeColors.main} strokeWidth="1.21018" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0509 7.02222H9.63056C7.62546 7.02222 6 8.64767 6 10.6528V20.3343C6 22.3394 7.62545 23.9648 9.63055 23.9648H12.0509" stroke={theme.themeColors.main} strokeWidth="1.21018" strokeLinecap="round"/>
    </svg>
    )
};

export default ExitIcon;