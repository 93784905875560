import { Formik } from 'formik'
import { FC } from 'react'

import { usePaymentAccountsFormController } from 'entities/paymentAccount/model/controllers/usePaymentAccountsFormController'
import { Input } from 'shared/components/Input'
import { WrapperFormRow } from 'shared/components/WrapperFormRow'
import { Button } from 'shared/components/Button'
import { Icon } from 'shared/components/Icons'
import { WrapperFormProfile } from 'shared/styles/GlobalStyle'

import { GeneralData } from 'entities/paymentAccount/model/controllers/formData'
import { validateSchema } from 'entities/paymentAccount/model/controllers/validateSchema'
import { PaymentAccounsStyled } from './style'

interface PaymentAccountsFormProps {
    organizationId?: number
}

export const PaymentAccountsForm: FC<PaymentAccountsFormProps> = ({
    organizationId,
}) => {
    const {
        loading,
        paymentAccounts,
        isNewPaymentAccount,
        formikRef,
        addPaymentAccounts,
        onRemovePaymentAccountModalOpen,
        onRemoveNewPaymentAccountModalOpen,
        handleSubmit,
        handleSaveClick,
    } = usePaymentAccountsFormController(organizationId)

    return (
        <PaymentAccounsStyled>
            <WrapperFormProfile className='wrapperSerteficate'>
                <div className='wrapperSerteficateHeader'>
                    <div className='title'>Банковские реквизиты</div>
                    <div onClick={addPaymentAccounts} className='editButton'>
                        Добавить реквизиты
                        <div className='icon-add'>
                            {' '}
                            <Icon name='plus' />
                        </div>
                    </div>
                </div>

                {(paymentAccounts ?? []).map(item => (
                        <div key={item.id} className='paymentAccounts'>
                            <div className='container'>
                                <div className='row'>
                                    <Input
                                        name='bank_name'
                                        label='Название банка'
                                        value={item.bankName}
                                        placeholder='Название банка'
                                        className='inputForm serteficateNumber'
                                        disabled
                                    />
                                    <Input
                                        name='bik'
                                        label='БИК банка'
                                        value={item.bik}
                                        placeholder='БИК банка'
                                        className='inputForm serteficateNumber'
                                        disabled
                                    />
                                    <Input
                                        name='number'
                                        label='Расчетный счет'
                                        value={item.number}
                                        placeholder='Расчетный счет'
                                        className='inputForm serteficateNumber'
                                        disabled
                                    />
                                    <Input
                                        name='сorrespondent_number'
                                        label='Кореспонденческий счет'
                                        value={item.correspondentNumber}
                                        placeholder='Кореспонденческий счет'
                                        className='inputForm serteficateNumber'
                                        disabled
                                    />
                                </div>
                                <div className='row-btn'>
                                    <Button
                                        loading={loading === item.id}
                                        themeType='outline-2'
                                        style={{
                                            borderColor: '#FC3F1D',
                                            background: '#FFF1EF',
                                            width: '39px',
                                            padding: 0,
                                        }}
                                        bgLoading='#FC3F1D'
                                        onClick={() =>
                                            onRemovePaymentAccountModalOpen(
                                                item.id,
                                            )
                                        }
                                    >
                                        <Icon name='delete' />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}

                {isNewPaymentAccount && (
                    <Formik
                        innerRef={formikRef as any}
                        key='certificates'
                        initialValues={{
                            bankName: '',
                            bik: '',
                            number: '',
                            correspondentNumber: '',
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={validateSchema}
                    >
                        <div className='certificateItem'>
                            <WrapperFormRow formData={GeneralData} />
                            <div className='row-btn'>
                                <Button
                                    onClick={handleSaveClick}
                                    style={{ width: '39px' }}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='14'
                                        height='12'
                                        viewBox='0 0 14 12'
                                        fill='none'
                                    >
                                        <path
                                            d='M13 1.00195L4.42857 11.002L1 7.00195'
                                            stroke='white'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </Button>
                                <Button
                                    themeType='outline-2'
                                    style={{
                                        borderColor: '#FC3F1D',
                                        background: '#FFF1EF',
                                        width: '39px',
                                        padding: 0,
                                    }}
                                    bgLoading='#FC3F1D'
                                    onClick={onRemoveNewPaymentAccountModalOpen}
                                >
                                    <Icon name='delete' />
                                </Button>
                            </div>
                        </div>
                    </Formik>
                )}
            </WrapperFormProfile>
        </PaymentAccounsStyled>
    )
}
