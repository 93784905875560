
// Функция для Yup, валидирует строку с временем
export const validateTimeRange = (value: any) => {
    if (!value) return true; 
    const ranges = value.split(',').map((range: any) => range.trim());

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/;

    // Проверяем каждый временной диапазон
    for (const range of ranges) {
        if (!timeRegex.test(range)) {
            return false;
        }

        const [start, end] = range.split(" - ");
        if (start >= end) {
            return false;
        }
    }
    return true;
}