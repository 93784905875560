import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { QueryKeys } from 'entities/paymentAccount/model/hooks/constants'
import { PaymentAccountApi } from 'entities/paymentAccount/api/api'
import { PaymentAccount } from 'entities/paymentAccount/model/types'

export const useGetPaymentAccountsQuery = (
    organisationId: number | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isFetching, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetAllPaymentAccounts, organisationId],
            queryFn: organisationId
                ? () => PaymentAccountApi.getPaymentAccounts(organisationId)
                : () => {},
            enabled: !!organisationId,
            ...(options ?? {}),
        })

    return {
        paymentAccounts: data as PaymentAccount[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isFetching,
        isError,
    }
}
