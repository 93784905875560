import IMask from "imask";

export const FormData = [
    [
        {
            name: "name",
            label: "Название", 
            placeholder: "Название", 
            type: "text",
            disabled: false,
            required: true,
        },
        { 
            name: "count", 
            label: "В наличии, шт", 
            placeholder: "В наличии, шт", 
            type: "text",
            disabled: false,
            required: true,
            maskConfig: {
                mask: Number
            },
        },
        { 
            name: "price", 
            label: "Цена", 
            placeholder: "Цена", 
            type: "number",
            disabled: false,
            required: true,
            maskConfig: {
                scale: 2,
                mask: Number,
                radix: '.',
                mapToRadix: [',']
            },
        },
    ],
    [
        {
            name: "comment", 
            label: "Комментарий", 
            placeholder: "Комментарий", 
            type: "text",
            disabled: false,
        }
    ]
];

export const FormDataEquipment = [
    [
        {
            name: "name",
            label: "Название", 
            placeholder: "Название", 
            type: "text",
            disabled: false,
            required: true,
        },
        { 
            name: "count", 
            label: "В наличии, шт", 
            placeholder: "В наличии, шт", 
            type: "number",
            disabled: false,
            required: true,
            maskConfig: {
                mask: Number
            },
        },
        { 
            name: "price", 
            label: "Цена", 
            placeholder: "Цена", 
            type: "number",
            disabled: false,
            required: true,
            maskConfig: {
                scale: 2,
                mask: Number,
                radix: '.',
                mapToRadix: [','],
            },
        },
    ],
    [
        { 
            name: "ad_price_info.more_than_three_hour_price_per_hour", 
            label: "Цена >3ч, ₽/час", 
            placeholder: "₽ 0", 
            type: "number",
            disabled: false,
            required: false,
            maskConfig: {
                scale: 2,
                mask: Number,
                radix: '.',
                mapToRadix: [','],
            },
        },
        { 
            name: "ad_price_info.morning_price_per_hour", 
            label: "Утро, ₽/час", 
            placeholder: "₽ 0", 
            type: "number",
            disabled: false,
            required: false,
            maskConfig: {
                scale: 2,
                mask: Number,
                radix: '.',
                mapToRadix: [','],
            },
        },
        { 
            name: "ad_price_info.evening_price_per_hour", 
            label: "Вечер, ₽/час", 
            placeholder: "₽ 0", 
            type: "number",
            disabled: false,
            required: false,
            maskConfig: {
                scale: 2,
                mask: Number,
                radix: '.',
                mapToRadix: [','],
            },
        },
    ],
    [
        { 
            name: "ad_price_info.morning_hours", 
            label: "Утренние часы", 
            placeholder: "08:00 - 10:00", 
            type: "text",
            disabled: false,
            required: false,
            maskConfig: {
                mask: 'HH:MM - HH:MM',
                blocks: {
                    HH: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 23,
                        maxLength: 2,
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 59,
                        maxLength: 2,
                    },
                },
            },
        },
        { 
            name: "ad_price_info.evening_hours", 
            label: "Вечерние часы", 
            placeholder: "20:00 - 00:00", 
            type: "text",
            disabled: false,
            required: false,
            maskConfig: {
                mask: 'HH:MM - HH:MM',
                blocks: {
                    HH: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 23,
                        maxLength: 2,
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 59,
                        maxLength: 2,
                    },
                },
            },
        },
    ],
    [
        {
            name: "comment", 
            label: "Комментарий", 
            placeholder: "Комментарий", 
            type: "text",
            disabled: false,
        }
    ]
];