import { FC } from 'react'

// TODO: fix items
interface FileDetailsProps {
    item: any
    themeType: any
}

export const FileDetails: FC<FileDetailsProps> = ({
    item,
    themeType,
}: FileDetailsProps) => (
    <div className='fileName'>
        {themeType === 'onlytitle' ? (
            <div className='onlytitle'>{item.name}</div>
        ) : (
            <>
                <div className='box'></div>
                <span>{item.name ? item.name : item.file_name}</span>
            </>
        )}
    </div>
)
