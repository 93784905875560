import { axiosApi } from 'shared/api/axios'
import { OrganisationsEndpoint } from 'entities/organisations/api/constants'
import { OrganisationWithKPPDTO } from 'entities/organisations/model/dtoTypes'
import { adaptOrganisationWithKPPDto } from 'entities/organisations/model/adapters/adaptOrganisationWithKppDto'

export const getOrganisationById = async (id: number) => {
    try {
        const response = await axiosApi.get<OrganisationWithKPPDTO>(
            `${OrganisationsEndpoint.organization}${id}`,
        )
        return adaptOrganisationWithKPPDto(response.data)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получения организаций')
    }
}
