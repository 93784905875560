import { useEffect, useMemo, useState } from 'react'
import {
    BookingMode,
    BookingStep,
    BookingStepType,
    OrderViewMode,
    selectBookingCurrentStep,
    selectBookingFormMode,
    setBookingCurrentStep,
    setBookingFormOrderViewMode,
} from 'features/Booking/model/slices'
import { useDispatch, useSelector } from 'react-redux'

export const useAccordionStateHelper = (isPreview: boolean) => {
    const dispatch = useDispatch()
    const formCurrentStep = useSelector(selectBookingCurrentStep)
    const mode = useSelector(selectBookingFormMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])

    const [currentAccordionStep, setCurrentAccordionStep] =
        useState<BookingStepType | null>(formCurrentStep)

    useEffect(() => {
        setCurrentAccordionStep(formCurrentStep)
    }, [formCurrentStep])

    const createHandleOpen = (step: BookingStepType) => (isOpen: boolean) => {
        if (!isOpen) {
            return setCurrentAccordionStep(null)
        }

        setCurrentAccordionStep(step)
    }

    const onGoToPayment = () => {
        dispatch(
            setBookingFormOrderViewMode({ orderViewMode: OrderViewMode.Edit }),
        )
        dispatch(setBookingCurrentStep({ step: BookingStep.Payment }))
        setCurrentAccordionStep(BookingStep.Payment)
    }

    const steps = [
        {
            key: BookingStep.GeneralInfo,
            title: '1. Общая информация',
            disabledSteps: [] as BookingStepType[],
        },
        {
            key: BookingStep.Booking,
            title: '2. Бронирование',
            disabledSteps: isPreview
                ? []
                : ([BookingStep.GeneralInfo] as BookingStepType[]),
        },
        {
            key: BookingStep.Payment,
            title: '3. Оплата',
            disabledSteps: isPreview
                ? []
                : ([
                      BookingStep.GeneralInfo,
                      BookingStep.Booking,
                  ] as BookingStepType[]),
        },
        {
            key: BookingStep.Documents,
            title: '4. Документы',
            disabledSteps: isPreview
                ? []
                : ([
                      BookingStep.GeneralInfo,
                      BookingStep.Booking,
                  ] as BookingStepType[]),
        },
    ]

    return {
        AccordionState: steps.reduce(
            (acc, { key, title, disabledSteps }) => {
                const isDisabled =
                    !isEdit && disabledSteps.includes(formCurrentStep)
                const isOpen = currentAccordionStep === key

                acc[key] = {
                    isOpen,
                    isDisabled,
                    title,
                    handleOpen: createHandleOpen(key),
                }

                return acc
            },
            {} as Record<
                BookingStepType,
                {
                    isOpen?: boolean
                    isDisabled: boolean
                    title: string
                    handleOpen: (isOpen: boolean) => void
                }
            >,
        ),
        onGoToPayment,
    }
}
