import { useQuery } from "@tanstack/react-query";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { getNomenclatureSingle } from "../api/getNomenclatureSingle";
import { patchNomenclature } from "../api/patchNomenclature";
import { WrapperAvatar } from "./style";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { Avatar } from "shared/components/Avatar";
import { StyledLine } from "shared/styles/GlobalStyle";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { FormData, FormDataEquipment } from "../model/formData";
import { getInitialValues } from "../model/getInitialValues";
import { ValidateSchemaCreate } from "../model/validateSchema";
import { parseTimeRange } from "../utils/parseTimeRange";

interface IPropsNomenclatureProfile {
    isEditable: boolean;
    formikRef: React.Ref<FormikHelpers<any>>;
    setIsEditable: (e: boolean) => void;
    setEditButtonText: (e: string) => void;
    setType: (e: number) => void;
    setDisabled: (e: boolean) => void;
    setCount: (e: number) => void;
}

export const NomenclatureProfile = ({
    isEditable,
    formikRef,
    setIsEditable,
    setEditButtonText,
    setType,
    setDisabled,
    setCount
}:IPropsNomenclatureProfile) => {
    const {id} = useParams();
    const [photo, setPhoto] = useState<File | null>(null);

    const dispatch = useDispatch();
    const { handleFileUpload } = useFileUploadUp();
    
    const {isLoading, data, refetch} = useQuery({
        queryKey: ["getNomenclatureSingle", id],
        queryFn: () => getNomenclatureSingle(id),
        enabled: !!id,
        refetchOnWindowFocus: false
    });

    const handlePhotoChange = (photo: File) => {
        setPhoto(photo);
    };

    const onSubmit = async(values: any) => {
        const { uploadedPhotoInfo } = await handleFileUpload(photo, []);
        const { ad_price_info } = values;
        const morningHours = ad_price_info && ad_price_info.morning_hours ? parseTimeRange(ad_price_info.morning_hours) : null;
        const eveningHours = ad_price_info && ad_price_info.evening_hours ? parseTimeRange(ad_price_info.evening_hours) : null;

        const updatedValues = {
            ...values,
            type: data?.type,
            ...(data?.type === 0 ? {
                ad_price_info: {
                    ...ad_price_info,
                    morning_hours: morningHours ? morningHours[0] : null,
                    evening_hours: eveningHours ? eveningHours[0] : null,
                },
            } : {
                ad_price_info: null
            })
        };

        if (uploadedPhotoInfo) {
            updatedValues.nomenclature_photo = {
                hash_name: uploadedPhotoInfo.hash_name,
                file_name: uploadedPhotoInfo.hash_name
            }
        }

        patchNomenclature(
            updatedValues as any,
            refetch,
            setIsEditable,
            setEditButtonText,
            dispatch,
            id
        );
    }

    useEffect(() => {
        if(data) { 
            setType(data.type)
            setCount(data?.count)
        }
    }, [data]);

    return (
        <>
            {!isLoading && data &&
                <Formik
                    innerRef={formikRef as any}
                    initialValues={getInitialValues(data)}
                    onSubmit={onSubmit}
                    validationSchema={ValidateSchemaCreate}
                    enableReinitialize
                >
                    {({ isValid }) => {
                        setDisabled(!isValid);
                        return (
                            <Form>
                                <WrapperAvatar>
                                    {isEditable ? (
                                        <UploadAvatar 
                                            initialPhoto={data?.nomenclature_photo ? data?.nomenclature_photo?.read_url : null}
                                            onPhotoChange={handlePhotoChange}
                                        />
                                    ) : (
                                        <Avatar photo={data?.nomenclature_photo ? data?.nomenclature_photo?.read_url : null} name={data?.name}
                                        jobs={`#${data?.id} ${data?.type === 0 ? "Оборудование" : 'Расходные материалы'}`} />
                                    )}
                                </WrapperAvatar>
                                <StyledLine margin="20px 0"/>
                                {data?.type === 0 ? (
                                    <WrapperFormRow 
                                        title="Общие данные" 
                                        formData={FormDataEquipment.map(row => 
                                            row.map(item => ({ 
                                                ...item, 
                                                disabled: item.name === "created_at" ? true : !isEditable,
                                                placeholder: isEditable ? item.placeholder : "",
                                            })
                                        ))}  
                                    />
                                ) : (
                                    <WrapperFormRow 
                                        title="Общие данные" 
                                        formData={FormData.map(row => 
                                            row.map(item => ({ 
                                                ...item, 
                                                disabled: item.name === "created_at" ? true : !isEditable,
                                                placeholder: isEditable ? item.placeholder : ""
                                            })
                                        ))} 
                                    />
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            }
        </>
    )
}