import styled from "styled-components";

export const WrapperSearch = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    .styledBeforeIcon {
        height: 16px;
    }
    .closeSearch {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
`;