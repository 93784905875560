import styled from 'styled-components'

interface InputProps {
    label?: string
    type?: 'password' | 'text' | 'email' | 'number' | 'date' | 'time'
    placeholder?: string
    $beforeIcon?: string
    $afterIcon?: string
    value?: string
    disabled?: boolean
}

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
    font-size: 16px;
    &.error {
        input {
            border: 1px solid #fc3f1d;
        }
    }
`

export const Label = styled.label`
    font-size: 12px;
    line-height: 21px;
    color: ${props => props.theme.input.label};
    .required {
        color: #fc3f1d;
    }
`

export const StyledInputWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    position: relative;
`

export const StyledInput = styled.input<InputProps>`
    padding: 10px;
    padding-left: ${props => (props.$beforeIcon ? '44px' : '10px')};
    padding-right: ${props => (props.$afterIcon ? '44px' : '10px')};
    border-radius: 8px;
    background: ${props => (props.disabled ? '#EEF1F5' : '#fff')};
    width: 100%;
    border: 1px solid #e1e4ea;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #282a42;
    &::placeholder {
        color: ${props => (props.disabled ? '#8181A5' : '#CDCDDF')};
        font-weight: 500;
    }
    &:focus,
    &:active,
    &:focus-visible {
        outline: none;
    }
    &:disabled::placeholder {
        color: #8181A5;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px
            ${props => (props.disabled ? '#E1E4EA' : '#fff')} inset;
        box-shadow: 0 0 0 30px ${props => (props.disabled ? '#F8F8F8' : '#fff')}
            inset;
        -webkit-text-fill-color: #000;
        transition: background-color 5000s ease-in-out 0s;
    }
`

export const StyleBeforeIcon = styled.div`
    position: absolute;
    left: 10px;
    height: 25px;
`

export const StyleAfterIcon = styled.div`
    position: absolute;
    right: 10px;
    cursor: pointer;
    display: flex;
    div {
        height: 25px;
    }
`
