export const GeneralData = [
    [
        { 
            name: "name", 
            label: "Наименование", 
            placeholder: "Введите наименование", 
            type: "text",
            disabled: false,
            required: true,
        },
        { 
            name: "inn", 
            label: "ИНН", 
            placeholder: "Введите ИНН", 
            type: "text",
            disabled: false,
            maskConfig: { mask: Number },
            required: true,
        },
        { 
            name: "kpp", 
            label: "КПП", 
            placeholder: "Введите КПП", 
            type: "text",
            disabled: false,
            required: true,
            maskConfig: { mask: Number },
        },
        {
            name: "ceo",
            label: "Генеральный директор",
            placeholder: "Генеральный директор",
            type: "text",
            disabled: false,
            required: true,
        },
        { 
            name: "legal_address",
            label: "Юридический адрес", 
            placeholder: "Юридический адрес",
            type: "text",
            disabled: false,
            required: true,
        },
    ],
];