import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getOrgs } from "../api/getOrgs";
import { Table } from "shared/components/Table";
import { columns } from "../model/columns";
import { SETTINGORGPROFILE } from "shared/consts/routingConsts";

interface IPropsSettingOrgTable {
    onSelectedRowsChange: (selectedRows: number[]) => void;
    selectedRows: any[];
}

export const SettingOrg = ({
    onSelectedRowsChange,
    selectedRows
}: IPropsSettingOrgTable) => {

    const handleSelectionChange = (newSelectedRows: any[]) => {
        onSelectedRowsChange(newSelectedRows);
    };

    const { isLoading, isError, data } = useQuery({
        queryKey: ["getSettingOrg"], 
        queryFn: () => {
            return getOrgs();
        },
        placeholderData: keepPreviousData,
        retry: 3,
    });

    return (
        <>
            {!isLoading && data && (
                <Table 
                    className="clientsTable equipmentTable" 
                    data={data.organizations}
                    columns={columns} 
                    columnWidths={['40px', '1fr']}
                    selectedRows={selectedRows}
                    onSelectionChange={handleSelectionChange}
                    navigation={SETTINGORGPROFILE}
                    isLoading={isLoading}
                    isError={isError}
                />
            )}
        </>
    )
}