import { Nomenclature as OrderNomenclature } from '../../orders/model'
import { Nomenclature } from '../../nomenclatures/model'
import { AutocompleteOption } from 'shared/components/Autocomplete'

export const adaptNomenclaturesToOption = (
    nomenclature: OrderNomenclature | Nomenclature,
): AutocompleteOption => ({
    value: nomenclature.id.toString(),
    label: nomenclature.name,
})
