import { axiosApi } from 'shared/api/axios'
import { GetCounterpartyResponseDto, Pagination } from '../model/dtoTypes'
import {
    Counterparty,
    UseCounterpartyAsyncAutocompleteOptionsParams,
} from '../model'
import { adaptCounterpartiesDTO } from '../model/adapters/adaptCounterpartiesDTO'

export const getCounterpartiesOptions = async (
    params: UseCounterpartyAsyncAutocompleteOptionsParams,
): Promise<{ counterparties: Counterparty[]; pagination: Pagination }> => {
    try {
        const response = await axiosApi.get<GetCounterpartyResponseDto>(
            `/api/v1/counterparties/counterparty/`,
            {
                params,
            },
        )
        return {
            counterparties: response.data.counterparties.map(
                adaptCounterpartiesDTO,
            ),
            pagination: response.data.pagination,
        }
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка')
    }
}
