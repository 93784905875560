import moment from "moment";

export const getInitialValues = (data: any) => ({
    id: data.id || '',
    name: data.name || '',
    code: data.code || '',
    photo: data.photo || '',
    count: data.count || '',
    price: data.price || '',
    comment: data.comment || '',
    area_id_id: data.area_id_id || '',
    total_earned: data.total_earned || '',
    avg_sales_per_day: data.avg_sales_per_day || '',
    ad_price_info: data.ad_price_info ? {
        more_than_three_hour_price_per_hour: data.ad_price_info?.more_than_three_hour_price_per_hour || null,
        morning_price_per_hour: data.ad_price_info?.morning_price_per_hour || null,
        evening_price_per_hour: data.ad_price_info?.evening_price_per_hour || null,
        morning_hours: data.ad_price_info.morning_hours ? `${moment.utc(data.ad_price_info?.morning_hours?.start, 'HH:mm:ss').format('HH:mm')} - ${moment.utc(data.ad_price_info?.morning_hours?.end, 'HH:mm:ss').format('HH:mm')}` : "",
        evening_hours: data.ad_price_info.evening_hours ? `${moment.utc(data.ad_price_info?.evening_hours?.start, 'HH:mm:ss').format('HH:mm')} - ${moment.utc(data.ad_price_info?.evening_hours?.end, 'HH:mm:ss').format('HH:mm')}` : "",
    }: null
})