import { PropsWithChildren, useEffect } from "react"
import { createPortal } from "react-dom";
import { useScrollbarWidth } from "shared/hooks/useScrollbarWidth";
import { ModalClose, ModalContentTop, WrapperModal, WrapperModalContent, WrapperStyled } from "./style";
import { Icon } from "../Icons";

interface IPropsBtnsModal {
    onClick: () => void;
    title: string;
    active: boolean;
    loading?: boolean;
    message?: string;
}

interface IPropsModal {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    contentClassName?: string;
    childrenClassName?: string;
    title: string;
    btns?: IPropsBtnsModal[];
}

interface IPropsModalConent {
    onClose: () => void;
    className?: string;
    childrenClassName?: string;
    title: string;
};

export const ModalSliderContent: React.FC<PropsWithChildren<IPropsModalConent>> = ({
    children,
    className,
    childrenClassName,
    title,
    onClose,
}) => {
    const scrollbarWidth = useScrollbarWidth();

    useEffect(() => {
        const listenerHandler = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", listenerHandler);
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
            document.removeEventListener("keydown", listenerHandler);
            document.body.style.removeProperty("overflow");
            document.body.style.paddingRight = "0px";
        };
    }, [scrollbarWidth, onClose]);

    return (
        <WrapperModalContent className={className}>
            <ModalContentTop>
                <div className="modal-title">{title}</div>
                <ModalClose onClick={onClose}>
                    <Icon name="close" />
                </ModalClose>
            </ModalContentTop>
            <div className={childrenClassName}>{children}</div>
        </WrapperModalContent>
    )
}

export const ModalSlider: React.FC<PropsWithChildren<IPropsModal>>= ({
    isOpen = false,
    className,
    contentClassName,
    childrenClassName,
    children,
    title,
    onClose,
}) => {
    if (!isOpen) return null;

    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return createPortal(
        <WrapperModal className={className} onClick={handleBackgroundClick}>
            <ModalSliderContent
                onClose={onClose} 
                title={title}
                className={contentClassName}
                childrenClassName={childrenClassName}
            >
                <WrapperStyled>
                    {children}
                </WrapperStyled>
            </ModalSliderContent>
        </WrapperModal>,
        document.body as HTMLElement
    );
}