import { CertificatesEndpoint } from 'entities/certificates/api/constants'
import { GetCertificateResponseDto } from 'entities/certificates/model/dtoTypes'
import { adaptCertificateDTO } from 'entities/certificates/model/adapters/adaptCertificateDTO'
import { axiosApi } from 'shared/api/axios'

export const getCertificates = async (counterparty_id: number | null) => {
    try {
        const response = await axiosApi.get<GetCertificateResponseDto>(
            `${CertificatesEndpoint.get}`,
            {
                params: {
                    counterparty_id,
                },
            },
        )
        return response.data.certificates.map(adaptCertificateDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получение сертификата')
    }
}
