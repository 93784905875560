import moment from "moment";

export const getInitialValuesPersonal = (data: any) => ({
    id: data?.id,
    type: data?.type,
    phone: data?.phone || "",
    email: data?.email || "",
    is_black_list: data?.is_black_list,
    comment: data?.comment || "",
    additional_data: {
        documents: {
            serial: data?.additional_data?.documents?.serial || "",
            division_code: data?.additional_data?.documents?.division_code || "",
            issued_by: data?.additional_data?.documents?.issued_by || "",
            issue_date: data?.additional_data?.documents?.issue_date || "",
            city: data?.additional_data?.documents?.city || "",
            street: data?.additional_data?.documents?.street || "",
            house_number: data?.additional_data?.documents?.house_number || "",
            apartment_number: data?.additional_data?.documents?.apartment_number || "",
            index: data?.additional_data?.documents?.index || "",
        }
    },
    created_at: moment(data?.created_at).format("DD.MM.YYYY"),
    discount: data?.discount || 0,
    profile_photo: {
        hash_name: data?.profile_photo?.hash_name || "",
        read_url: data?.profile_photo?.read_url || ""
    },
    document_photos: data?.document_photos,
    contact: {
        surname: data?.contacts?.[0].surname || "",
        first_name: data?.contacts?.[0].first_name || "",
        patronymic: data?.contacts?.[0].patronymic || "",
    },
    ...(data?.contacts?.[0]?.phone && { contact: { phone: data?.contacts?.[0].phone } }),
    ...(data?.contacts?.[0]?.email && { contact: { email: data?.contacts?.[0].email } }),
});
