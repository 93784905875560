import { FC } from 'react'

import { StyledDescriptionItem } from 'features/OrderPreview/ui/OrderTotalPricePreview/styles'
import { Nomenclature } from 'entities/orders/model'

interface NomenclatureListPreviewPriceProps {
    nomenclature: Nomenclature
}

export const NomenclatureListPreviewPrice: FC<
    NomenclatureListPreviewPriceProps
> = ({ nomenclature }) => (
    <StyledDescriptionItem>
        ₽
        {nomenclature.price && nomenclature.absolutDiscount > 0 ? (
            <>{nomenclature.price - nomenclature.absolutDiscount}</>
        ) : (
            <>{nomenclature.price}</>
        )}
    </StyledDescriptionItem>
)
