import { useEffect } from "react";
import { NotificationsClose, NotificationsDesc, NotificationsIcon, NotificationsMessage, WrapperNotifications, WrapperNotificationsRow } from "./style";
import { useDispatch } from "react-redux";
import { clearNotification } from "shared/redux/slice/notificationSlice";

interface IPropsNotifications {
    title: "success" | "error" | "info" | null;
    message: string | null;
    errMessage?: string | null;
}

export const Notifications = ({ title = "error", message = "Ошибка", errMessage }: IPropsNotifications) => {
    const dispatch = useDispatch();
    const notificationClass = `notification-${title}`;
    
    useEffect(() => {
        setTimeout(() => {
            dispatch(clearNotification())
        }, 3000)
    }, [title])

    return (
        <WrapperNotifications className={notificationClass}>
            <WrapperNotificationsRow>
                <NotificationsIcon>
                    {title === "success" && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#34BD93"/>
                            <path d="M9.99999 14.5898L16.3 8.28978C16.4924 8.12722 16.7392 8.04328 16.9908 8.05475C17.2425 8.06622 17.4805 8.17224 17.6574 8.35164C17.8343 8.53103 17.9369 8.77057 17.9448 9.02237C17.9527 9.27416 17.8653 9.51966 17.7 9.70978L10.7 16.7098C10.5131 16.893 10.2617 16.9956 9.99999 16.9956C9.73824 16.9956 9.48692 16.893 9.29999 16.7098L6.29999 13.7098C6.13471 13.5197 6.04728 13.2742 6.05518 13.0224C6.06308 12.7706 6.16572 12.531 6.34258 12.3516C6.51945 12.1722 6.75751 12.0662 7.00917 12.0547C7.26083 12.0433 7.50754 12.1272 7.69999 12.2898L9.99999 14.5898Z" fill="white"/>
                        </svg>
                    )}
                    {title === "error" && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#ED4627"/>
                            <path d="M13.4136 12L16.2436 14.83C16.4074 15.0213 16.493 15.2674 16.4833 15.519C16.4736 15.7707 16.3693 16.0095 16.1912 16.1876C16.0131 16.3656 15.7743 16.47 15.5227 16.4797C15.271 16.4894 15.0249 16.4038 14.8336 16.24L12.0036 13.41L9.17361 16.24C9.08396 16.3447 8.97364 16.4297 8.84958 16.4897C8.72551 16.5497 8.59038 16.5835 8.45266 16.5888C8.31494 16.5941 8.17761 16.5709 8.04928 16.5206C7.92096 16.4704 7.80441 16.3941 7.70696 16.2966C7.6095 16.1992 7.53324 16.0826 7.48297 15.9543C7.43269 15.826 7.40949 15.6887 7.41481 15.5509C7.42013 15.4132 7.45386 15.2781 7.51388 15.154C7.5739 15.03 7.65892 14.9196 7.76361 14.83L10.5936 12L7.76361 9.16999C7.59978 8.97869 7.51417 8.73261 7.52389 8.48094C7.53362 8.22926 7.63795 7.99052 7.81604 7.81243C7.99414 7.63433 8.23288 7.53 8.48455 7.52028C8.73623 7.51056 8.9823 7.59616 9.17361 7.75999L12.0036 10.59L14.8336 7.75999C15.0249 7.59616 15.271 7.51056 15.5227 7.52028C15.7743 7.53 16.0131 7.63433 16.1912 7.81243C16.3693 7.99052 16.4736 8.22926 16.4833 8.48094C16.493 8.73261 16.4074 8.97869 16.2436 9.16999L13.4136 12Z" fill="white"/>
                        </svg>
                    )}
                </NotificationsIcon>
                <NotificationsMessage>{message}</NotificationsMessage>
                <NotificationsClose onClick={() => dispatch(clearNotification())}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <path d="M5 1L1 5M5 5L1 1" stroke="white" strokeLinecap="round"/>
                    </svg>
                </NotificationsClose>
            </WrapperNotificationsRow>
            {errMessage && <NotificationsDesc>{errMessage}</NotificationsDesc>}
        </WrapperNotifications>
    )
}
