import moment from "moment";
import { ResponseBooking } from "../types";

export const getInitialValues = (data: ResponseBooking) => ({
    id: data.id,
    created_at: moment(data.created_at).format("DD.MM.YYYY HH:MM"),
    counterparty: {
        id: data.counterparty?.id,
        full_name: data.counterparty?.full_name,
        phone: data.counterparty?.phone,
        email: data.counterparty?.email
    },
    total_price: data.total_price,
    total_price_with_discount: data.total_price_with_discount
})