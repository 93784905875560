import { useRef, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'
import { useGetPaymentAccountsQuery } from 'entities/paymentAccount/model'
import { PaymentAccountApi } from 'entities/paymentAccount/api'
import { showNotification } from 'shared/redux/slice/notificationSlice'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'

export const usePaymentAccountsFormController = (organizationId?: number) => {
    const formikRef = useRef<FormikHelpers<any>>(null)

    const [loading, setLoading] = useState<number | null>()
    const [isNewPaymentAccount, setIsNewPaymentAccount] = useState<boolean>()

    const dispatch = useDispatch()

    const { paymentAccounts, refetch } = useGetPaymentAccountsQuery(
        organizationId ?? null,
    )

    const addPaymentAccounts = () => {
        setIsNewPaymentAccount(true)
    }

    const removePaymentAccounts = async (id?: number) => {
        try {
            dispatch(closeModal())
            if (!id) {
                setIsNewPaymentAccount(false)
            } else {
                setLoading(id)
                await PaymentAccountApi.deletePaymentAccounts(id)
                await refetch()
            }

            dispatch(
                showNotification({
                    message: 'Успешно',
                    type: 'success',
                }),
            )
            setLoading(null)
        } catch (err) {
            dispatch(
                showNotification({
                    message: 'Ошибка',
                    type: 'error',
                }),
            )
        }
    }

    const onRemovePaymentAccountModalOpen = (id: number) => {
        dispatch(
            openModal({
                isOpen: true,
                content: <>Вы точно хотите удалить реквезиты?</>,
                config: {
                    title: 'Вы точно хотите удалить?',
                    btns: [
                        {
                            onClick: () => removePaymentAccounts(id),
                            title: 'Да',
                            active: true,
                        },
                        {
                            onClick: () => dispatch(closeModal()),
                            title: 'Нет',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const onRemoveNewPaymentAccountModalOpen = () => {
        dispatch(
            openModal({
                isOpen: true,
                content: <>Вы точно хотите удалить реквезиты?</>,
                config: {
                    title: 'Вы точно хотите удалить?',
                    btns: [
                        {
                            onClick: () => removePaymentAccounts(),
                            title: 'Да',
                            active: true,
                        },
                        {
                            onClick: () => dispatch(closeModal()),
                            title: 'Нет',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const handleSubmit = async (values: any, actions: any) => {
        try {
            const data = {
                ...values,
                organizationId,
                name: values.bankName,
            }

            await PaymentAccountApi.createPaymentAccount(data)

            actions.resetForm()
            await refetch()

            dispatch(
                showNotification({
                    message: 'Реквезиты успешно созданы',
                    type: 'success',
                }),
            )
            setIsNewPaymentAccount(false)
        } catch (error) {
            console.error(error)
            dispatch(
                showNotification({
                    message: 'Ошибка создания реквезитов',
                    type: 'error',
                }),
            )
        }
    }

    const handleSaveClick = (e: any) => {
        if (formikRef.current) {
            formikRef.current.submitForm()
            e.preventDefault()
        }
    }

    return {
        loading,
        paymentAccounts,
        isNewPaymentAccount,
        formikRef,
        addPaymentAccounts,
        onRemovePaymentAccountModalOpen,
        onRemoveNewPaymentAccountModalOpen,
        handleSubmit,
        handleSaveClick,
    }
}
