import React, { FC, FormEvent } from 'react'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import { Icon } from 'shared/components/Icons'
import { StyledNomenclatureDiscountInputContainer } from './styles'

interface DiscountInputContainer {
    absolutDiscount: number | null
    percentDiscount: number | null
    onAbsolutDiscountChange: (value: FormEvent) => void
    onPercentDiscountChange: (value: FormEvent) => void
    onAdd?: () => void
    disabledAddButton?: boolean
    disabled?: boolean
}

export const DiscountInputContainer: FC<DiscountInputContainer> = ({
    absolutDiscount,
    percentDiscount,
    onAbsolutDiscountChange,
    onPercentDiscountChange,
    disabledAddButton,
    onAdd,
    disabled,
}) => {
    return (
        <StyledNomenclatureDiscountInputContainer>
            <Input
                name='absolutDiscount'
                placeholder='0'
                value={absolutDiscount ?? ''}
                onChange={onAbsolutDiscountChange}
                label='Скидка, ₽'
                disabled={disabled}
            />
            <Input
                name='percentDiscount'
                placeholder='0'
                value={percentDiscount ?? ''}
                onChange={onPercentDiscountChange}
                label='Скидка, %'
                disabled={disabled}
            />
            {onAdd && !disabled && (
                <Button
                    disabled={disabledAddButton}
                    onClick={onAdd}
                    padding={8}
                >
                    <Icon name='addRoundedIcon' />
                </Button>
            )}
        </StyledNomenclatureDiscountInputContainer>
    )
}
