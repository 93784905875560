import { TabLinkStyled, WrapperTabLink } from './style'

export interface IPropsTabLink {
    name: string
    link: string
}

export interface IPropsListTabs {
    list: IPropsTabLink[]
    className?: string
}

export const TabLink = ({ list, className }: IPropsListTabs) => {
    return (
        <WrapperTabLink className={className}>
            {list.map((item, index) => (
                <TabLinkStyled key={index} to={item.link}>
                    {item.name}
                </TabLinkStyled>
            ))}
        </WrapperTabLink>
    )
}
