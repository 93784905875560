import styled from 'styled-components'

// TODO: Rework isFullHeight
export const Box = styled.div<{ isExpanded: boolean; isFullHeight?: boolean }>`
    background: ${props => props.theme.background.white};
    border-radius: 16px 0px 0px 16px;
    width: 100%;
    max-width: ${props => (props.isExpanded ? '386px' : '80px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s linear;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 2px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(94, 129, 244, 0.5);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(94, 129, 244, 0.7);
    }
    .back {
        transition: all 0.2s linear;
    }
    .sibedar-top-row {
        padding-top: 10px;
        ${props =>
            props.isExpanded &&
            `
            width: 100%;
            max-width: 354px;
            display: flex;
            flex-direction: column;
            align-items: end;
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 999;
        `}
        ${props => props.isFullHeight && `height: 100%;`}
    }
    .line {
        width: ${props => (props.isExpanded ? '100%' : '39px')};
        height: 1px;
        background: #e1e4ea;
        margin: 16px 0;
    }
`

export const StyledItem = styled.div`
    display: flex;
    gap: 10px;
    .wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            color: #8181a5;
            font-family: 'SF Pro Text';
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.06px;
        }

        // TODO: setup media query variables
        @media (max-width: 1450px) {
            gap: 5px;
        }
    }
    a,
    button {
        background: none;
        cursor: pointer;
        height: 39px;
        width: 39px;
        border: 1px solid #e1e4ea;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s linear;
        &:hover {
            background: linear-gradient(
                    0deg,
                    rgba(94, 129, 244, 0.1) 0%,
                    rgba(94, 129, 244, 0.1) 100%
                ),
                #fff;
            border: none;
            transition: all 0.4s linear;
        }
        div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // TODO: setup media query variables
    @media (max-width: 1450px) {
        gap: 5px;
    }
`

export const StyledSidebar = styled.div`
    width: 100%;
    max-width: 354px;
    .title {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #282a42;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .badge {
            margin-left: 10px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: #fc3f1d;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #fff;
            text-align: center;
        }
    }
`

export const StyledBookingForm = styled.div`
    width: 100%;
    max-width: 354px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
    gap: 10px;
    .row-form {
        display: flex;
        align-items: end;
        gap: 10px;
    }
    .open {
        color: ${({ theme }) => theme.themeColors.main};
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        cursor: pointer;
    }
`

export const StyledLoader= styled.div`
    margin: 80px auto;
`;

export const StyledWrapperForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .row {
        display: flex;
        gap: 10px;
    }
    .label {
        a {
            color: ${({ theme }) => theme.themeColors.main};
            font-size: 12px;
            line-height: 21px;
            text-decoration: none;
        }
    }
`