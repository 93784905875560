import { Button } from "shared/components/Button"
import { NotFoundStyled, NotFoundTitle, NotFoundImg } from "./style"
import { useNavigate } from "react-router-dom"

export const NotFound = () => {
    const navigation = useNavigate();
    return (
        <NotFoundStyled>
            <NotFoundImg src="/notFound/notFound404.png" width="936px" />
            <NotFoundTitle>Упс... Страница не найдена</NotFoundTitle>
            <Button onClick={() => navigation("/")}>Вернуться на главную</Button>
        </NotFoundStyled>
    )
}