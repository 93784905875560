import { axiosApi } from 'shared/api/axios'
import { BookingEndpoint } from './constants'
import { UpdateBookingQuery, UpdateBookingRequest } from '../model'

export const updateBookings = async (
    queries: UpdateBookingQuery,
    body: UpdateBookingRequest,
) => {
    try {
        const params = {
            booking_id: queries.bookingId,
            order_id: queries.orderId,
        }
        const bodyDTO = {
            area_id: body.areaId,
            start_time: body.startTime.toISOString(),
            end_time: body.endTime.toISOString(),
            comment: body?.comment,
            membersCount: body?.membersCount,
            area_absolut_discount: body?.areaAbsolutDiscount,
            area_percent_discount: body?.areaPercentDiscount,
        }
        await axiosApi.patch(
            `${BookingEndpoint.bookings}${queries.bookingId}`,
            bodyDTO,
            {
                params,
            },
        )
        return true
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
