import React, { FC } from 'react'

import { PaymentsPreview } from 'features/OrderPreview/ui'
import { usePaymentFormController } from 'features/Booking/model/hooks/usePaymentFormController'
import { PaymentMethodName } from 'entities/payments/model'
import { Autocomplete } from 'shared/components/Autocomplete'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import { Label } from 'shared/components/Input/style'

interface PaymentFormProps {
    isPreview: boolean
}

export const PaymentForm: FC<PaymentFormProps> = ({ isPreview }) => {
    const {
        isPaid,
        isButtonDisabled,
        redirectUrl,

        amount,
        onEditAmount,

        certificate,
        currentCertificateBalance,
        certificatesOptions,
        onChangeCertificate,

        currentPayments,
        paymentTypeOption,
        paymentType,
        paymentTypesOptions,
        onChangePaymentType,
        paymentCallbacks,

        comment,
        onChangeComment,

        onGoNext,
        fillAccountPaymentDetails,
    } = usePaymentFormController()

    return (
        <div className='content'>
            <PaymentsPreview
                payments={currentPayments}
                redirectUrl={redirectUrl}
                paymentCallbacks={isPreview ? undefined : paymentCallbacks}
                isOpenByDefault={isPreview}
            />

            {isPaid ? (
                <Button disabled onClick={onGoNext}>
                    Оплачено
                </Button>
            ) : (
                <>
                    {!isPreview && (
                        <>
                            <Autocomplete
                                value={paymentTypeOption ?? null}
                                onChange={onChangePaymentType}
                                options={paymentTypesOptions}
                                label='Способ оплаты'
                                placeholder='Выберете способ оплаты'
                                required
                            />
                            {(paymentType === PaymentMethodName.Online ||
                                paymentType === PaymentMethodName.Cash) && (
                                <Input
                                    value={amount}
                                    onChange={onEditAmount}
                                    label='Сумма'
                                    placeholder='Введите сумму'
                                    required
                                />
                            )}
                            {paymentType === PaymentMethodName.Certificate && (
                                <>
                                    <Autocomplete
                                        value={certificate ?? null}
                                        onChange={onChangeCertificate}
                                        options={certificatesOptions}
                                        label='Сертификат'
                                        placeholder='Введите название...'
                                        required
                                    />
                                    <Label>
                                        Баланс сертификата: ₽
                                        {currentCertificateBalance}
                                    </Label>
                                </>
                            )}
                            <Input
                                label='Комментарий'
                                value={comment}
                                onChange={onChangeComment}
                            />
                            {paymentType === PaymentMethodName.Account && (
                                <Button onClick={fillAccountPaymentDetails}>
                                    Составить счет на оплату
                                </Button>
                            )}
                            <Button
                                disabled={isButtonDisabled}
                                onClick={onGoNext}
                            >
                                Внести оплату
                            </Button>
                        </>
                    )}
                </>
            )}
        </div>
    )
}
