import * as Yup from 'yup';

export const ValidateSchemaPersonal = Yup.object().shape({
    contact: Yup.object().shape({
        first_name: Yup.string().min(2).max(20).required(),
        surname: Yup.string().min(2).max(20).required(),
    }),
    phone: Yup.string().required().matches(/^\+7-\d{3}-\d{3}-\d{2}-\d{2}$/),
    email: Yup.string().email().required('Required'),
    discount: Yup.string().min(0).max(100),
});