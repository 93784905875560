import { axiosApi } from 'shared/api/axios'
import { NomenclaturesEndpoint } from './constants'
import { GetNomenclaturesResponseDTO } from '../model/dtoTypes'
import { adaptNomenclatureDTO } from '../model/adapters/adaptNomenclatureDTO'
import { UseCounterpartyAsyncAutocompleteOptionsParams } from '../model/hooks/useNomenclaturesAsyncAutocompleteQueryOptions'
import { Pagination } from '../../counterparties/model/dtoTypes'
import { Nomenclature } from '../model'

export const getNomenclaturesOptions = async (
    params: UseCounterpartyAsyncAutocompleteOptionsParams,
): Promise<{ nomenclatures: Nomenclature[]; pagination: Pagination }> => {
    try {
        const response = await axiosApi.get<GetNomenclaturesResponseDTO>(
            NomenclaturesEndpoint.nomenclature,
            {
                params,
            },
        )

        return {
            nomenclatures:
                response.data.nomenclatures.map(adaptNomenclatureDTO),
            pagination: response.data.pagination,
        }
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
