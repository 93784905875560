import { FC } from 'react'
import { HeaderContentItem, HeaderContainer } from './styles'
import { DropdownButton } from 'shared/components/Dropdown/DropdownButton'
import { BackButton } from 'shared/components/BackButton'

interface HeaderProps {
    handleCloseSidebar: () => void
    onDeleteOrder: () => void
    isPreview: boolean
    goFromPreviewToEdit: () => void
    onRemoveBooking: () => void
    onGoToPaymentRedirectUrl: () => void
    onGoToPayment: () => void
    onGoRefund: () => void
    isPaid: boolean
}

export const Header: FC<HeaderProps> = ({
    isPreview,
    goFromPreviewToEdit,
    handleCloseSidebar,
    onDeleteOrder,
    onRemoveBooking,
    onGoToPaymentRedirectUrl,
    onGoToPayment,
    onGoRefund,
    isPaid,
}) => {
    const buttons = isPaid
        ? [
              {
                  title: 'Редактировать бронь',
                  onClick: goFromPreviewToEdit,
              },
              {
                  title: 'Возврат',
                  onClick: onGoRefund,
              },
              {
                  title: 'Ссылка на оплату',
                  onClick: onGoToPaymentRedirectUrl,
              },
              {
                  title: 'Удалить бронирование',
                  isErrorColor: true,
                  onClick: onRemoveBooking,
              },
              {
                  title: 'Удалить заказ',
                  isErrorColor: true,
                  onClick: onDeleteOrder,
              },
          ]
        : [
              {
                  title: 'Редактировать бронь',
                  onClick: goFromPreviewToEdit,
              },
              {
                  title: 'Оплатить',
                  onClick: onGoToPayment,
              },
              {
                  title: 'Ссылка на оплату',
                  onClick: onGoToPaymentRedirectUrl,
              },
              {
                  title: 'Удалить бронирование',
                  isErrorColor: true,
                  onClick: onRemoveBooking,
              },
              {
                  title: 'Удалить заказ',
                  isErrorColor: true,
                  onClick: onDeleteOrder,
              },
          ]

    return (
        <HeaderContainer>
            <HeaderContentItem>
                <div className='wrapper'>
                    <BackButton
                        text='Назад'
                        onClick={handleCloseSidebar}
                        goBack={false}
                    />
                </div>
            </HeaderContentItem>
            <HeaderContentItem>
                {isPreview && <DropdownButton buttons={buttons} />}
                {!isPreview && (
                    <button
                        onClick={() => {
                            onDeleteOrder()
                        }}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='39'
                            height='39'
                            viewBox='0 0 39 39'
                            fill='none'
                        >
                            <rect
                                x='38.5'
                                y='38.5'
                                width='38'
                                height='38'
                                rx='7.5'
                                transform='rotate(-180 38.5 38.5)'
                            />
                            <path
                                d='M13.3431 13.6569L24.6569 24.9706M24.6569 13.6569L13.3431 24.9706'
                                stroke='#FC3F1D'
                                strokeLinecap='round'
                            />
                        </svg>
                    </button>
                )}
            </HeaderContentItem>
        </HeaderContainer>
    )
}
