import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const SideBar = {
    Booking: 'BookingComponent',
} as const

type SideBarsComponentType = typeof SideBar[keyof typeof SideBar]

interface SidebarState {
    isOpen: boolean;
    component: SideBarsComponentType | null;
}

const initialState: SidebarState = {
    isOpen: false,
    component: null,
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        openSidebar: (state, action: PayloadAction<SideBarsComponentType | null>) => {
            state.isOpen = true;
            state.component = action.payload;
        },
        closeSidebar: state => {
            state.isOpen = false;
            state.component = null;
        },
        toggleSidebar: state => {
            state.isOpen = !state.isOpen;
        },
    },
});

export const {openSidebar, closeSidebar, toggleSidebar} = sidebarSlice.actions;

export default sidebarSlice.reducer;
