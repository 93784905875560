import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { QueryKeys } from 'entities/payments/model/hooks/constants'
import { PaymentApi } from 'entities/payments/api'
import { Payment } from 'entities/payments/model'

export const useGetPaymentsQuery = (
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isFetching, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetAllPayments],
            queryFn: () => PaymentApi.getAllPaymentsList(),
            ...(options ?? {}),
        })

    return {
        payments: data as Payment[],
        error,
        status,
        refetch,
        isFetching,
        isSuccess,
        isLoading,
        isError,
    }
}
