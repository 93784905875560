import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'

export const confirmAccountPayment = async (payment_id: number) => {
    try {
        await axiosApi.post(
            `${PaymentEndpoint.ConfirmOnlinePayment}/${payment_id}`,
        )
        return true
    } catch (err) {
        console.error('Ошибка подтверждения платежа', err)
        throw new Error('Ошибка подтверждения платежа')
    }
}
