import { useEffect, useState } from 'react'

interface UseCalendarScrollControllerParams {
    depsForUpdate: Iterable<any>
}

export const useCalendarScrollController = (
    props: UseCalendarScrollControllerParams,
) => {
    const [needsScroll, setNeedsScroll] = useState(false)

    useEffect(() => {
        const headerElement = document.querySelector('.rbc-time-content')

        const checkScrollNecessity = () => {
            if (headerElement) {
                setNeedsScroll(
                    headerElement.scrollWidth > headerElement.clientWidth,
                )
            }
        }

        checkScrollNecessity()

        const resizeObserver = new ResizeObserver(() => {
            checkScrollNecessity()
        })

        if (headerElement) {
            resizeObserver.observe(headerElement)
        }

        return () => {
            if (headerElement) {
                resizeObserver.unobserve(headerElement)
            }
        }
    }, [...props.depsForUpdate])

    const scrollLeft = () => {
        const container = document.querySelector('.rbc-time-content')
        container?.scrollBy({ left: -500, behavior: 'smooth' })
    }

    const scrollRight = () => {
        const container = document.querySelector('.rbc-time-content')
        container?.scrollBy({ left: 500, behavior: 'smooth' })
    }

    return {
        needsScroll,
        scrollLeft,
        scrollRight,
    }
}
