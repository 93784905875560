import styled from "styled-components";

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
`;

export const ToggleButton = styled.button<{ isActive: boolean }>`
    width: 41px;
    height: 20px;
    background-color: ${({ isActive, theme }) => (isActive ? theme.themeColors.main : '#EEF3F9')};
    border: none;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    outline: none;

    &::before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${({isActive}) => isActive ? "white" : "#8181A5"};
        position: absolute;
        top: 2px;
        left: ${({ isActive }) => (isActive ? '23px' : '2px')};
        transition: left 0.2s ease-in-out;
    }
`;

export const ToggleLabel = styled.span`
    font-size: 14px;
    color: #282A42;
    font-weight: 300;
`;