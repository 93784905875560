export const columns = [
    {
        header: "Наименование",
        id: "name",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "name"
    },
    {
        header: "ИНН",
        id: "inn",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "inn"
    },
    {
        header: "Юридический адрес",
        id: "legal_address",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "legal_address"
    },
]