import styled from 'styled-components'

export const StyledAreaButton = styled.button<{ isActive: boolean }>`
    background: none;
    cursor: pointer;
    height: 39px;
    min-width: fit-content;
    border: 1px solid #e1e4ea;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s linear;
    gap: 10px;
    ${props =>
        props.isActive &&
        `
        border: 1px solid rgba(94, 129, 244, 0.50);
        background: linear-gradient(0deg, rgba(94, 129, 244, 0.10) 0%, rgba(94, 129, 244, 0.10) 100%),#FFF;
    `}
`

export const AutocompleteRow = styled.div`
    display: flex;
    align-items: end;
    flex-wrap: nowrap;
    gap: 10px;
`

export const StyledCrossIconContainer = styled.div`
    background: #8181a5;
    cursor: pointer;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledBookingsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 10px;
`

export const BoldText = styled.div`
    color: #282a42;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`

export const Text = styled.div`
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
`

export const StyledFileInputText = styled.span`
    font-size: 14px;
    font-weight: 700;
    color: #8181A5;
`

export const StyledFileInput = styled.div`
    background: #EEF3F9;
    border: 1px dashed #8181A5;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`

export const StyledFilePreviewText = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #8181A5;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
`

export const StyledFilePreview = styled.div<{ isPreview: boolean }>`
    border: 1px solid #E1E4EA;
    box-sizing: border-box;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    max-height: 40px;
    min-height: 40px;
    flex: ${props => props.isPreview ? '0 0 85%' : '0 0 70%'};
    max-width: ${props => props.isPreview ? '85%' : '70%'};
    display: flex;
`

export const StyledFileDownloadButton = styled.button`
    border: 1px solid #E1E4EA;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    flex: 0 0 15%;
`

export const StyledFileRemoveButton = styled.button`
    border: 1px solid #ED4627;
    background: #FFF1EF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    flex: 0 0 15%;

`