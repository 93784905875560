import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CertificatesApi } from 'entities/certificates/api'
import { Certificate } from 'entities/certificates/model/types'
import { QueryKeys } from 'entities/certificates/model/hooks/constants'

export const useCertificatesQuery = (
    counterpartyId: number | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetCertificates, counterpartyId],
            queryFn: () => CertificatesApi.getCertificates(counterpartyId),
            ...(options ?? {}),
        })

    return {
        certificates: data as Certificate[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
