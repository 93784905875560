import { AreaDTO } from '../dtoTypes'
import { Area } from '../types'
import { adaptAdBookingInfoDTO } from './adaptAdBookingInfoDTO'
import { adaptAdPriceInfoDTO } from './adaptAdPriceInfoDTO'
import { adaptPhotoDTO } from './adaptPhotoDTO'

export const adaptAreaDTO = (areaDTO: AreaDTO): Area => {
    return {
        id: areaDTO.id,
        name: areaDTO.name,
        square: areaDTO.square,
        pricePerHour: areaDTO.price_per_hour,
        prepayment: areaDTO.prepayment,
        adBookingInfo: areaDTO?.ad_booking_info
            ? adaptAdBookingInfoDTO(areaDTO.ad_booking_info)
            : null,
        adPriceInfo: areaDTO?.ad_price_info
            ? adaptAdPriceInfoDTO(areaDTO.ad_price_info)
            : null,
        photos: areaDTO?.photos?.map(adaptPhotoDTO) ?? [],
    }
}
