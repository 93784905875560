import { CertificateDTO } from '../dtoTypes'
import { Certificate } from '../types'

export const adaptCertificateDTO = (source: CertificateDTO): Certificate => ({
    id: source.id,
    balance: source.balance,
    counterpartyId: source.counterparty_id_,
    expiredAt: new Date(source.expired_at),
    user: {
        id: source?.user?.id ?? null,
        surname: source?.user?.surname ?? null,
        firstName: source?.user?.first_name ?? null,
        patronymic: source?.user?.patronymic ?? null,
    },
    name: source.name,
})