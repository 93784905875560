import { axiosApi } from 'shared/api/axios'
import { GetAllPaymentMethodsResponseDto } from 'entities/payments/model/dtoTypes'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { adaptPaymentMethodDTO } from 'entities/payments/model/adapters/adaptPaymentMethodDTO'

export const getPaymentMethods = async (is_active: boolean | null = true) => {
    try {
        const response = await axiosApi.get<GetAllPaymentMethodsResponseDto>(
            PaymentEndpoint.GetPaymentMethods,
            {
                params: {
                    is_active,
                },
            },
        )
        return response.data.map(adaptPaymentMethodDTO)
    } catch (err) {
        console.error('Ошибка получения платежей', err)
        return null
    }
}
