import styled from "styled-components";

export const PaymentAccounsStyled = styled.div`
    .editButton {
        display: flex;
        gap: 8px;
        align-items: center;
        .icon-add {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme.themeColors.main};
            border-radius: 8px;
        }
    }
    .certificateItem {
        display: flex;
        width: 100%;
        .row-btn {
            display: flex;
            align-items: center;
            margin-left: 10px;
            gap: 10px;
        }
    }
    .paymentAccounts {
        width: 100%;
        .container {
            display: flex;
            flex-direction: row;
            .row:first-child {
                width: 100%;
            }
            .row-btn {
                display: flex;
                align-items: end;
                margin-left: 10px;
                gap: 10px;
            }
        }
    }
`