import { AggregatedData, BookingData } from "shared/types/metricsTypes";

export const aggregateDataByHour = (data: BookingData[]): AggregatedData[] => {
    const aggregated: Record<string, AggregatedData> = {};
    data.forEach(item => {
        const date = new Date(item.created_at);
        if (isNaN(date.getTime())) {
            console.warn(`Неверный формат даты: ${item.created_at}`);
            return;
        }

        const hour = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            0,
            0,
            0
        );
        const hourStr = hour.toISOString();

        if (!aggregated[hourStr]) {
            aggregated[hourStr] = {
                hour: hourStr,
                revenue: 0,
                canceled_bookings: 0,
                returns: 0,
                total_orders: 0
            };
        }

        aggregated[hourStr].revenue += item.revenue;
        aggregated[hourStr].canceled_bookings += item.canceled_bookings;
        aggregated[hourStr].returns += item.returns;
        aggregated[hourStr].total_orders += item.total_orders;
    });

    const aggregatedArray: AggregatedData[] = Object.values(aggregated).sort((a, b) => {
        return new Date(a.hour).getTime() - new Date(b.hour).getTime();
    });

    return aggregatedArray;
}
