import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AreasApi } from '../../api'
import { QueryKeys } from './constants'
import { Area } from '../index'

export const useAreasResourcesQuery = (options?: UseQueryOptions) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetAllAreas],
            queryFn: () => AreasApi.getAreas(),
            ...(options ?? {}),
        })

    return {
        areasResources:
            (data as Area[])?.map(area => ({
                id: area.id,
                title: area.name,
            })) ?? [],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
