import { AdBookingInfoDTO } from '../dtoTypes'
import { AdBookingInfo } from '../types'
import { adaptTimePeriodDTO } from './adaptTimePeriodDTO'

export const adaptAdBookingInfoDTO = (
    infoDTO: AdBookingInfoDTO,
): AdBookingInfo => {
    return {
        availableBookingTime:
            infoDTO.available_booking_time?.map(adaptTimePeriodDTO),
        availableOnlineBookingTime:
            infoDTO.available_online_booking_time?.map(adaptTimePeriodDTO),
        unavailableRefundPeriodDays: infoDTO.unavailable_refund_period_days,
        blockingBeforeMinutes: infoDTO.blocking_before_minutes,
        blockingAfterMinutes: infoDTO.blocking_after_minutes,
    }
}
