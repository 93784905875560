import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'shared/redux/store'
import { useBlocker } from 'react-router-dom'
import { CLIENTSCREATE } from 'shared/consts/routingConsts'
import { useEffect } from 'react'
import { closeSidebar } from 'shared/redux/slice/sidebar'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'
import {
    clearBookingState,
    selectIsDirtyBookingForm,
} from '../../../Booking/model/slices'

export const useNavigationBlocker = () => {
    const dispatch = useDispatch()
    const { isOpen } = useSelector((state: RootState) => state.sidebar)
    const isDirtyBookingForm = useSelector(selectIsDirtyBookingForm)

    const blocker = useBlocker(({ nextLocation }) => {
        if (nextLocation.pathname.includes('calendar')) {
            return false
        }

        if (nextLocation.pathname === CLIENTSCREATE) {
            return false
        }
        return isOpen
    })

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const resetBooking = () => {
                dispatch(clearBookingState())
                dispatch(closeSidebar())
                blocker.proceed()
            }

            if (isDirtyBookingForm) {
                dispatch(
                    openModal({
                        isOpen: true,
                        content: (
                            <>
                                У вас есть несохраненные изменения. Вы уверены,
                                что хотите уйти?
                            </>
                        ),
                        config: {
                            title: 'Вы точно хотите покинуть страницу?',
                            btns: [
                                {
                                    onClick: () => {
                                        resetBooking()
                                        dispatch(closeModal())
                                    },
                                    title: 'Да',
                                    active: true,
                                },
                                {
                                    onClick: () => dispatch(closeModal()),
                                    title: 'Нет',
                                    active: false,
                                },
                            ],
                        },
                    }),
                )
                return
            }

            resetBooking()
        }
    }, [blocker.state])
}
