import { FC } from 'react'
import { DetailOrder } from 'entities/orders/model'
import {
    CheckoutPaymentsStyled,
    DetailsPaymentsTitle,
    Total,
    WrapperItems,
} from './style'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import { Counterparty } from 'entities/counterparties/model'

interface CheckoutPaymentsProps {
    detailOrder: DetailOrder
    counterparty: Counterparty
}

export const CheckoutPayments: FC<CheckoutPaymentsProps> = ({
    detailOrder,
    counterparty,
}) => {
    return (
        <CheckoutPaymentsStyled>
            <div className='row'>
                <DetailsPaymentsTitle>Заказ</DetailsPaymentsTitle>
                <WrapperItems>
                    {detailOrder.bookings.map((booking, index) => (
                        <>
                            <div key={index} className='item'>
                                <span className='item-title'>
                                    {booking?.area?.name} (
                                    {getBookingRange(booking).label})
                                </span>
                                <div className='item__left'>
                                    <div className='item-price'>
                                        {booking.areaTotalPriceWithDiscount} ₽
                                    </div>
                                </div>
                            </div>
                            {booking.nomenclatures.map(nomenclature => (
                                <div key={index} className='item'>
                                    <span className='item-title'>
                                        {nomenclature.name} (x
                                        {nomenclature.count})
                                    </span>
                                    <div className='item__left'>
                                        <div className='item-price'>
                                            ₽
                                            {
                                                nomenclature.totalPriceWithDiscount
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ))}
                    <div className='item'>
                        <span className='item-title'>Скидка клиента</span>
                        <div className='item__left'>
                            <div className='item-price'>
                                {counterparty.discount} ₽
                            </div>
                        </div>
                    </div>
                </WrapperItems>
                <div className='line'></div>
                <Total>
                    <div>Итого</div>
                    <div>{detailOrder.totalPriceWithDiscount} ₽</div>
                </Total>
            </div>
        </CheckoutPaymentsStyled>
    )
}
