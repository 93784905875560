import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { QueryKeys } from 'entities/organisations/model/hooks/constants'
import { OrganisationsApi } from 'entities/organisations/api/api'
import { OrganisationWithKPP } from 'entities/organisations/model/types'

export const useGetOrganisationByIdQuery = (
    id: number | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetOrganisationById, id],
            queryFn: id
                ? () => OrganisationsApi.getOrganisationById(id)
                : () => {},
            enabled: !!id,
            ...(options ?? {}),
        })

    return {
        organisation: data as OrganisationWithKPP,
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
