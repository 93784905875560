import { OrderBookingDTO } from '../dtoTypes'
import { OrderBooking } from '../types'
import { adaptNomenclatureDTO } from './adaptNomenclatureDTO'

export const adaptBookingDTO = (bookingDTO: OrderBookingDTO): OrderBooking => ({
    id: bookingDTO.id,
    area: {
        id: bookingDTO?.area?.id ?? null,
        name: bookingDTO?.area?.name ?? null,
    },
    membersCount: bookingDTO?.members_count ?? null,
    comment: bookingDTO.comment,
    endTime: new Date(bookingDTO.end_time),
    startTime: new Date(bookingDTO.start_time),
    areaPrice: parseFloat(bookingDTO.area_price),
    areaAbsolutDiscount: bookingDTO?.area_absolut_discount
        ? parseFloat(bookingDTO.area_absolut_discount)
        : null,
    areaPercentDiscount: bookingDTO?.area_percent_discount
        ? parseFloat(bookingDTO.area_percent_discount)
        : null,
    areaTotalPrice: bookingDTO?.area_total_price
        ? parseFloat(bookingDTO.area_total_price)
        : null,
    areaTotalPriceWithDiscount: bookingDTO?.area_total_price_with_discount
        ? parseFloat(bookingDTO.area_total_price_with_discount)
        : null,
    nomenclaturePrice: bookingDTO?.nomenclature_price
        ? parseFloat(bookingDTO.nomenclature_price)
        : null,
    nomenclaturePriceWithDiscount: bookingDTO?.nomenclature_price_with_discount
        ? parseFloat(bookingDTO.nomenclature_price_with_discount)
        : null,
    totalPriceWithDiscount: parseFloat(bookingDTO.total_price_with_discount),
    totalPrice: parseFloat(bookingDTO.total_price),
    nomenclatures: bookingDTO.nomenclatures?.map(adaptNomenclatureDTO) ?? [],
})
