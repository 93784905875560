import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, { memo } from 'react'
import moment, { Moment } from 'moment'
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import {
    WrapperCalendar,
    StyledEditEventsWrapper,
    StyledNewEventsWrapper,
    StyledReadyEventsWrapper,
} from './style'

export interface CalendarEvent {
    allDay?: boolean
    id?: number | null
    orderId: number | null
    counterpartyName: string | null
    counterpartyPhone: string | null
    comment: string | null
    isCompany: boolean
    title: React.ReactNode | null
    start: Date | null
    end: Date | null
    resourceId: number | null
    resourceName: string
    isNew: boolean
    isEdit: boolean
}

// TODO: fix types
interface IPropsReactBigCalendar {
    views: any
    defaultView: View
    min?: Date
    max?: Date
    currentDate: Moment
    onDateChange: (date: Moment) => void
    events?: CalendarEvent[] | undefined
    resources?: { id: number; title: string }[]
    components?: any
    onEventResize?: (props: any) => void
    onDropSlot?: (props: any) => void
    onSelectSlot?: (props: any) => void
    onClickEvent?: (props: any) => void
    onShowMore?: (props: any) => void
}

const DnDCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

const ReactBigCalendarComponent = ({
    views,
    defaultView,
    min,
    max,
    currentDate,
    onDateChange,
    events,
    resources,
    components,
    onDropSlot,
    onEventResize,
    onSelectSlot,
    onClickEvent,
    onShowMore,
}: IPropsReactBigCalendar) => {
    const eventsWrapper = ({ event, children }: any) => {
        if (event.isEdit) {
            return <StyledEditEventsWrapper>{children}</StyledEditEventsWrapper>
        }

        if (event.isNew) {
            return <StyledNewEventsWrapper>{children}</StyledNewEventsWrapper>
        }

        return <StyledReadyEventsWrapper>{children}</StyledReadyEventsWrapper>
    }

    const event = ({ event }: { event: CalendarEvent }) => {
        if (defaultView === 'month') {
            if (event.resourceName) {
                return (
                    <span style={{ fontSize: 12 }}>
                        {event.resourceName ?? ''}
                    </span>
                )
            }
            return null
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    paddingTop: 10,
                }}
            >
                {event.counterpartyName ? (
                    <span style={{ fontSize: 12 }}>
                        {event.counterpartyName ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.counterpartyPhone ? (
                    <span style={{ fontSize: 12 }}>
                        {event.counterpartyPhone ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.comment && event.comment?.length ? (
                    <span style={{ fontSize: 12 }}>{event.comment ?? ''}</span>
                ) : (
                    <></>
                )}
            </div>
        )
    }

    return (
        <WrapperCalendar>
            <DnDCalendar
                onDragStart={onClickEvent}
                onEventResize={onEventResize}
                onEventDrop={onDropSlot}
                onSelectSlot={onSelectSlot}
                localizer={localizer}
                views={views}
                defaultView={defaultView}
                timeslots={1}
                step={60}
                min={min ?? moment().startOf('day').toDate()}
                max={max ?? moment().endOf('day').toDate()}
                date={currentDate.toDate()}
                onNavigate={date => onDateChange(moment(date))}
                toolbar={false}
                events={events}
                resources={resources}
                components={{
                    eventWrapper: eventsWrapper,
                    event,
                    ...components,
                }}
                tooltipAccessor={null}
                enableAutoScroll={false}
                selectable
                onShowMore={onShowMore}
                messages={{
                    showMore: (total: number) => `еще ${total}`,
                }}
            />
        </WrapperCalendar>
    )
}

export const ReactBigCalendar = memo(ReactBigCalendarComponent)
