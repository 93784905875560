import styled from "styled-components";

export const WrapperOrderTable = styled.div`
    width: 100%;
    .styledTableHeadColumn:not(:first-child) {
        justify-content: center;
    }
    .bodyCell:not(:first-child) {
        justify-content: center;
    }
`;

export const StyledStatusChips = styled.div<{ background?: string; color?: string; }>`
    color: ${({ color }) => color};
    background-color: ${({ background }) => background};
    padding: 6px 10px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
`;