import { FC } from 'react'

import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import { OrderBooking } from 'entities/orders/model'
import { Line } from 'shared/components/Line'

import {
    StyledNestedTableCell,
    StyledNestedTableRow,
    StyledSecondaryText,
    StyledTable,
    StyledTableCell,
    StyledTableHeader,
    StyledTableRow,
    StyledTableWrapper,
} from './styles'

const InvoiceDetailTableColumns = [
    { key: 'service', title: 'Услуги', textAlign: 'left', width: '40%' },
    { key: 'price', title: 'Цена', textAlign: 'left', width: '15%' },
    { key: 'count', title: 'Количество', textAlign: 'center', width: '20%' },
    { key: 'discount', title: 'Скидка', textAlign: 'left' },
    { key: 'total', title: 'Сумма', textAlign: 'end' },
]

interface InvoiceDetailsProps {
    bookings: OrderBooking[]
}

export const InvoiceDetailsTable: FC<InvoiceDetailsProps> = ({ bookings }) => (
    <StyledTableWrapper>
        <StyledTable>
            <thead>
                <tr>
                    {InvoiceDetailTableColumns.map(column => (
                        <StyledTableHeader
                            key={column.key}
                            style={{
                                textAlign: column.textAlign as any,
                                width: column?.width ?? undefined,
                            }}
                        >
                            {column.title}
                        </StyledTableHeader>
                    ))}
                </tr>
            </thead>
            <tbody>{renderRows(bookings)}</tbody>
        </StyledTable>
    </StyledTableWrapper>
)

const renderRows = (orderBookings: OrderBooking[]) => {
    return orderBookings?.map(booking => (
        <>
            <StyledTableRow key={booking.id}>
                <StyledTableCell>{booking?.area?.name}</StyledTableCell>
                <StyledTableCell>₽ {booking.areaPrice}</StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    <StyledSecondaryText>
                        x{getBookingRange(booking).diff}
                    </StyledSecondaryText>
                </StyledTableCell>
                <StyledTableCell>
                    ₽ {booking.areaAbsolutDiscount}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'end' }}>
                    ₽ {calculateTotal(booking)}
                </StyledTableCell>
            </StyledTableRow>

            {booking.nomenclatures?.length > 0 && (
                <>
                    <tr>
                        <StyledTableCell
                            colSpan={InvoiceDetailTableColumns.length}
                        >
                            <StyledSecondaryText>
                                Дополнения
                            </StyledSecondaryText>
                        </StyledTableCell>
                    </tr>

                    {booking.nomenclatures?.map(nomenclature => (
                        <StyledNestedTableRow
                            key={nomenclature.bookingNomenclatureId}
                        >
                            <StyledNestedTableCell>
                                {nomenclature.name}
                            </StyledNestedTableCell>
                            <StyledTableCell>
                                ₽ {nomenclature.price}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: 'center' }}>
                                <StyledSecondaryText>
                                    x{nomenclature.count}
                                </StyledSecondaryText>
                            </StyledTableCell>
                            <StyledTableCell>
                                ₽ {nomenclature.absolutDiscount}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: 'end' }}>
                                ₽ {nomenclature.totalPriceWithDiscount}
                            </StyledTableCell>
                        </StyledNestedTableRow>
                    ))}
                </>
            )}

            <tr>
                <StyledTableCell colSpan={InvoiceDetailTableColumns.length}>
                    <Line />
                </StyledTableCell>
            </tr>
        </>
    ))
}

const calculateTotal = (booking: OrderBooking) => {
    const hours = getBookingRange(booking).diff
    return (booking.areaPrice || 0) * hours - (booking.areaAbsolutDiscount || 0)
}
