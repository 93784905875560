import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"
import { AreasTabs } from "features/AreasTabs"
import { useNavigate } from "react-router-dom"
import { AREASCREATE } from "shared/consts/routingConsts"

export const AreasListPages = () => {
    
    const navigate = useNavigate();

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                    <Button onClick={() => navigate(AREASCREATE)}>+Добавить зал</Button>
                </div>
            </div>
        }>
            <AreasTabs />
        </DashboardLayout>
    )
}