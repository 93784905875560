import styled from "styled-components";

export const WrapperSettingOrg = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .styledButton {
        margin-left: auto;
    }
`