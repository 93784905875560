import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { OrdersApi } from '../../api'
import { QueryKeys } from './constants'
import { DetailOrder } from '../index'

export const useGetDetailOrderQuery = (
    id: number | null,
    options?: UseQueryOptions,
) => {
    const { data, error, isError, isLoading, isFetching, isSuccess, refetch, status } =
        useQuery({
            initialData: null,
            queryKey: [QueryKeys.GetAllOrdersBookings, id],
            queryFn: id ? () => OrdersApi.getDetailOrder(id) : () => null,
            ...(options ?? {}),
        })

    return {
        detailOrder: data as DetailOrder,
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isFetching,
        isError,
    }
}
