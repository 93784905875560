import { axiosApi } from 'shared/api/axios'
import { PaymentAccountsEndpoint } from 'entities/paymentAccount/api/constants'

export const deletePaymentAccounts = async (orgId: number) => {
    try {
        await axiosApi.delete(
            `${PaymentAccountsEndpoint.paymentAccount}${orgId}`,
        )
        return true
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка удаления реквезитов')
    }
}
