import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Navigation, Pagination } from 'swiper/modules'
import 'swiper/scss'

import { DeleteButton } from 'entities/uploadFiles/ui/DeleteButton'
import { DownloadButton } from 'entities/uploadFiles/ui/DownloadButton'
import { FileDetails } from 'entities/uploadFiles/ui/FileDetails'
import { PhotoPreview } from 'entities/uploadFiles/ui/PhotoPreview'
import { Button } from 'shared/components/Button'
import { Icon } from 'shared/components/Icons'
import useDownloadFile from 'shared/hooks/useDownloadFile'
import { ModalSlider } from 'shared/components/ModalSlider'
import {
    StyledUploadFile,
    UploadFileButton,
    UploadFileList,
    WrapperLocalImage,
} from './style'

interface IPropsFile {
    id: number
    name: string
    hash_name?: string
    read_url?: string
    file_name?: string
    file?: File
}

interface IPropsUploadFile {
    file: IPropsFile[]
    disabled?: boolean
    themeType?: 'default' | 'onlytitle'
    onChangeFile?: (files: IPropsFile[]) => void
    onNewFilesChange?: (newFiles: IPropsFile[]) => void
    onRemovedFilesChange?: (removedFiles: IPropsFile[]) => void
    displayPhoto?: boolean
}

export const UploadFile = ({
    file: initialFiles,
    disabled = true,
    themeType = 'default',
    onChangeFile,
    onNewFilesChange,
    onRemovedFilesChange,
    displayPhoto,
}: IPropsUploadFile) => {
    const [files, setFiles] = useState<IPropsFile[]>([])
    const [newFiles, setNewFiles] = useState<IPropsFile[]>([])
    const [removedFiles, setRemovedFiles] = useState<IPropsFile[]>([])
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [isOpen, setIsOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const openModal = (index: number) => {
        setCurrentIndex(index)
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const downloadFile = useDownloadFile()

    useEffect(() => {
        if (initialFiles.length > 0) {
            const filesWithIds = initialFiles.map(file => ({
                ...file,
                id: Math.random(),
            }))
            setFiles(filesWithIds)
        }
    }, [initialFiles])

    useEffect(() => {
        onChangeFile && onChangeFile(files)
    }, [files, onChangeFile])

    useEffect(() => {
        onNewFilesChange && onNewFilesChange(newFiles)
    }, [newFiles, onNewFilesChange])

    useEffect(() => {
        onRemovedFilesChange && onRemovedFilesChange(removedFiles)
    }, [removedFiles, onRemovedFilesChange])

    useEffect(() => {
        !disabled && setNewFiles([])
        !disabled && setRemovedFiles([])
    }, [disabled])

    const handleDelete = (id: number, e?: MouseEvent) => {
        e?.preventDefault()
        const fileToDelete = files.find(file => file.id === id)
        if (fileToDelete) {
            if (files.some(file => file.id === id)) {
                setRemovedFiles(prevRemovedFiles => [
                    ...prevRemovedFiles,
                    fileToDelete,
                ])
            }
        }
        const updatedFiles = files.filter(file => file.id !== id)
        setFiles(updatedFiles)
    }

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    const getUniqueFileName = (
        name: string,
        existingFiles: IPropsFile[],
    ): string => {
        let newName = name
        let counter = 1
        const fileExtension = name.includes('.')
            ? name.slice(name.lastIndexOf('.'))
            : ''
        const baseName = name.replace(fileExtension, '')

        while (existingFiles.some(file => file.name === newName)) {
            newName = `${baseName}(${counter})${fileExtension}`
            counter++
        }
        return newName
    }

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files
        if (fileList) {
            const newFilesToAdd: IPropsFile[] = []
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]
                const uniqueFileName = getUniqueFileName(file.name, files)
                const newFile = {
                    id: Math.random(),
                    name: uniqueFileName,
                    file: file,
                }
                newFilesToAdd.push(newFile)
            }
            const updatedFiles = [...files, ...newFilesToAdd]

            resetFileInput()

            setFiles(updatedFiles)
            setNewFiles(prevNewFiles => [...prevNewFiles, ...newFilesToAdd])
        }
    }

    const handleAddFileClick = () => {
        fileInputRef.current?.click()
    }

    const downloadUploadFile = async (
        readUrl: string,
        fileName: string,
        e?: MouseEvent,
    ) => {
        e?.preventDefault()
        downloadFile(readUrl, fileName).then()
    }

    const getFilePreviewUrl = (file: IPropsFile): string | undefined => {
        if (file.read_url) {
            return file.read_url
        }
        if (file.file) {
            return URL.createObjectURL(file.file)
        }
        return undefined
    }

    return (
        <>
            <StyledUploadFile>
                {files.length !== 0 && (
                    <UploadFileList isPreviewPhoto={!!displayPhoto}>
                        {files.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    displayPhoto ? 'row-preview-img' : 'row'
                                }
                            >
                                {displayPhoto ? (
                                    <WrapperLocalImage>
                                        <PhotoPreview
                                            src={getFilePreviewUrl(item)}
                                            alt={item.name}
                                            onClick={() => openModal(index)}
                                        />
                                        {disabled && (
                                            <DeleteButton
                                                itemId={item.id}
                                                handleDelete={handleDelete}
                                            />
                                        )}
                                    </WrapperLocalImage>
                                ) : (
                                    <>
                                        <FileDetails
                                            item={item}
                                            themeType={themeType}
                                        />
                                        {item.read_url && !displayPhoto && (
                                            <DownloadButton
                                                readUrl={item.read_url}
                                                fileName={item.file_name ?? ''}
                                                downloadUploadFile={
                                                    downloadUploadFile
                                                }
                                            />
                                        )}
                                        {disabled && (
                                            <Button
                                                onClick={e =>
                                                    handleDelete(item.id, e)
                                                }
                                                className='buttonFile'
                                                themeType='outline-2'
                                                style={{
                                                    borderColor: '#FC3F1D',
                                                    background: '#FFF1EF',
                                                }}
                                            >
                                                <Icon name='delete' />
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </UploadFileList>
                )}
                <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    multiple
                />
                {disabled && (
                    <UploadFileButton onClick={handleAddFileClick}>
                        +Добавить фотографии документов
                    </UploadFileButton>
                )}
            </StyledUploadFile>
            <ModalSlider title='Фото зала' isOpen={isOpen} onClose={closeModal}>
                <Swiper
                    initialSlide={currentIndex}
                    spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides
                    pagination={{ clickable: true }}
                    keyboard
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    onSlideChange={(swiper: any) =>
                        setCurrentIndex(swiper.activeIndex)
                    }
                    modules={[Navigation, Pagination, Keyboard]}
                >
                    {files.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img
                                rel='prefetch'
                                src={getFilePreviewUrl(item)}
                                alt={`Image ${index}`}
                            />
                        </SwiperSlide>
                    ))}
                    <div className='swiper-arrow swiper-button-prev'>
                        <Icon name='arrowRight' />
                    </div>
                    <div className='swiper-arrow swiper-button-next'>
                        <Icon
                            name='arrowRight'
                            style={{
                                display: 'flex',
                                transform: 'scaleX(-1)',
                            }}
                        />
                    </div>
                </Swiper>
            </ModalSlider>
        </>
    )
}
