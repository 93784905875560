import { Icon } from 'shared/components/Icons'
import { StyledContainer } from 'shared/components/SocialLinks/style'

const SocialLinksData = [
    {
        id: 'site',
        icon: 'site',
        link: 'https://backg.ru',
    },
    {
        id: 'telegram',
        icon: 'telegram',
        link: 'https://t.me/backg_chat',
    },
    {
        id: 'vk',
        icon: 'vk',
        link: 'https://vk.com/backgstudio',
    },
    {
        id: 'instagram',
        icon: 'instagram',
        link: 'https://www.instagram.com/backg.ru/?igsh=a2Y1c3RmOTNlN2t3',
    },
    {
        id: 'youtube',
        icon: 'youtube',
        link: 'https://www.youtube.com/@BackG',
    },
]

export const SocialLinks = () => (
    <StyledContainer>
        {SocialLinksData.map(socialNetwork => (
            <a
                key={socialNetwork.id}
                href={socialNetwork.link}
                target='_blank'
                rel='noopener noreferrer'
            >
                <Icon name={socialNetwork.icon} />
            </a>
        ))}
    </StyledContainer>
)
