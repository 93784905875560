import { RootState } from 'shared/redux/store'
import { ViewMode } from '../../../BookingCalendarController/model'
import moment from 'moment/moment'

export const selectBookings = (state: RootState) =>
    state.bookingSidebarForm.bookings

export const selectActiveBookingId = (state: RootState) =>
    state.bookingSidebarForm.activeBookingId

export const selectBookingIdToEdit = (state: RootState) =>
    state.bookingSidebarForm.bookingIdToEdit

export const selectBookingCurrentStep = (state: RootState) =>
    state.bookingSidebarForm.currentStep

export const selectBookingMode = (state: RootState) =>
    state.bookingSidebarForm.mode

export const selectBookingCounterpartyId = (state: RootState) =>
    state.bookingSidebarForm.counterpartyId

export const selectBookingCounterpartyName = (state: RootState) =>
    state.bookingSidebarForm.counterpartyName

export const selectBookingOrderId = (state: RootState) =>
    state.bookingSidebarForm.orderId

export const selectBookingFormMode = (state: RootState) =>
    state.bookingSidebarForm.mode

export const selectBookingFormOrderViewMode = (state: RootState) =>
    state.bookingSidebarForm.orderViewMode

export const selectBookingOriginalOrder = (state: RootState) =>
    state.bookingSidebarForm.originalOrder

export const selectActiveBookingFormTab = (state: RootState) =>
    state.bookingSidebarForm.bookingActiveTab

export const selectOrderEditStateInitialising = (state: RootState) =>
    state.bookingSidebarForm.isEditStateInitialising

export const selectOrderTotalPrice = (state: RootState) =>
    state.bookingSidebarForm.totalPrice

export const selectToday = (state: RootState) => state.bookingSidebarForm.today

export const selectView = (state: RootState) => state.bookingSidebarForm.view

export const selectTimeFilter = (state: RootState) => {
    const today = moment(state.bookingSidebarForm.today)
    const view = state.bookingSidebarForm.view

    if (view === ViewMode.Day) {
        const startDayTime = today.startOf('day').toDate()
        const endWeekTime = today.endOf('week').toDate()
        return {
            startTime: startDayTime,
            endTime: endWeekTime,
        }
    }
    if (view === ViewMode.Week) {
        const startWeekTime = today.startOf('week').toDate()
        const endWeekTime = today.endOf('week').toDate()

        return {
            startTime: startWeekTime,
            endTime: endWeekTime,
        }
    }
    if (view === ViewMode.Month) {
        const startMonthTime = today.startOf('month').toDate()
        const endMonthTime = today.endOf('month').toDate()
        return {
            startTime: startMonthTime,
            endTime: endMonthTime,
        }
    }
    return
}

export const selectIsDirtyBookingForm = (state: RootState) =>
    state.bookingSidebarForm.isDirty

export const selectIsCompanyBookingForm = (state: RootState) =>
    state.bookingSidebarForm.isCompany
