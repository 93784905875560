import { NomenclatureDTO } from '../dtoTypes'
import { Nomenclature } from '../types'

export const adaptNomenclatureDTO = (
    nomenclatureDTO: NomenclatureDTO,
): Nomenclature => ({
    id: nomenclatureDTO.id,
    bookingNomenclatureId: nomenclatureDTO.booking_nomenclature_id,
    name: nomenclatureDTO.name,
    absolutDiscount: nomenclatureDTO?.absolut_discount ?? 0,
    percentDiscount: nomenclatureDTO?.percent_discount ?? 0,
    count: nomenclatureDTO.count,
    price: parseFloat(nomenclatureDTO?.price ?? 0),
    totalPrice: parseFloat(nomenclatureDTO?.total_price ?? 0),
    totalPriceWithDiscount: parseFloat(
        nomenclatureDTO?.total_price_with_discount ?? 0,
    ),
})
