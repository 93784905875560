import styled from "styled-components";

export const StyledTabsBooking = styled.div`
    .underline {
        display: none;
    }
    .title {
        color: #282A42;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    .tabContainer {
        margin-top: 10px;
        .tabHeaders {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            .tabHeader {
                border: 1px solid #E1E4EA;
                border-radius: 8px;
                padding: 10px;
                color: #282A42;
                font-size: 14px;
                font-weight: 500;
                width: auto;
                &.active {
                    border: 1px solid ${({ theme }) => theme.themeColors.main};
                }
            }
        }
    }
    label {
        font-size: 12px;
        line-height: 20px;
    }
    input {
        font-size: 14px;
    } 
    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .row {
            display: flex;
            gap: 10px;
        }
    }
    .nomenclatureBlock {
        width: 100%;
        .title {
            font-size: 12px;
            line-height: 20px;
            color: #8181A5;
            margin-bottom: 5px;
            font-weight: 400;
        }
        .chips {
            display: flex;
            gap: 4px;
            flex-wrap: wrap;
        }
    }
`

export const StyledChips = styled.div`
    border: 1px solid #E1E4EA;
    border-radius: 8px;
    padding: 4px 8px;
    color: #282A42;
    display: flex;
    font-size: 12px;
    gap: 4px;
    font-weight: 300;
    .discountChips {
        color: #8181A5;
    }
    .totalPriceDiscountChips {
        font-weight: 400;
    }
`