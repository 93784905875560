import { StyledWrapperAvatar } from "./style"

interface IPropsAvatar {
    photo: string | undefined | null;
    name: string | undefined;
    jobs?: string;
}

export const Avatar = ({
    photo,
    name,
    jobs
}: IPropsAvatar) => {
    return (
        <StyledWrapperAvatar>
            <div className="avatarPhoto">
                {photo && <img src={photo} alt="" />}
            </div>
            <div className="avatarContent">
                <div className="avatarContent__name">{name}</div>
                {jobs && <div className="avatarContent__jobs">{jobs}</div>}
            </div>
        </StyledWrapperAvatar>
    )
}