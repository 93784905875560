import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import {
    TabsContainer,
    TabHeaders,
    TabHeader,
    Underline,
    TabContent,
} from './style'

interface IPropsTab {
    label: string
    children: React.ReactNode
}

interface TPropsTabs {
    activeTabIndex?: number
    disabledTabIndex?: number
    children: React.ReactElement<IPropsTab> | React.ReactElement<IPropsTab>[]
    onTabChange?: (activeTab: number) => void
}

export const Tabs = ({
    children,
    onTabChange,
    activeTabIndex,
    disabledTabIndex,
}: TPropsTabs) => {
    const [activeTab, setActiveTab] = useState(0)
    const tabRefs = useRef<(HTMLDivElement | null)[]>([])

    const childrenArray = React.Children.toArray(children) as React.ReactElement<IPropsTab>[]

    useEffect(() => {
        if (activeTabIndex !== null && activeTabIndex !== undefined) {
            setActiveTab(activeTabIndex)
        }
    }, [activeTabIndex])

    useLayoutEffect(() => {
        if (tabRefs.current[activeTab]) {
            const { offsetWidth, offsetLeft } = tabRefs.current[activeTab]!
            setUnderlineStyle({ width: offsetWidth, left: offsetLeft })
        }
    }, [activeTab])

    const [underlineStyle, setUnderlineStyle] = useState({
        width: 0,
        left: 0,
    })

    const handleTabClick = (index: number) => {
        if (disabledTabIndex === index) {
            return
        }

        setActiveTab(index)
        if (onTabChange) {
            onTabChange(index)
        }
    }

    return (
        <TabsContainer className='tabContainer'>
            <TabHeaders className='tabHeaders'>
                {childrenArray.map((child, index) => (
                    <TabHeader
                        key={index}
                        ref={el => (tabRefs.current[index] = el)}
                        onClick={() => handleTabClick(index)}
                        isActive={index === activeTab}
                        className={`tabHeader ${index === activeTab ? 'active' : ''}`}
                    >
                        <span>{child.props.label}</span>
                    </TabHeader>
                ))}
                <Underline
                    className='underline'
                    animate={underlineStyle}
                    transition={{ duration: 0.3 }}
                />
            </TabHeaders>
            <TabContent className='tabContent'>
                {childrenArray[activeTab]}
            </TabContent>
        </TabsContainer>
    )
}

export const Tab = ({ children }: IPropsTab) => {
    return <>{children}</>
}
