import { createOrder } from './createOrder'
import { addBookingToOrder } from './addBookingToOrder'
import { getOrdersList } from './getOrdersList'
import { getDetailOrder } from './getDetailOrder'
import { deleteOrder } from './deleteOrder'
import { deleteBookingFromOrder } from './deleteBookingFromOrder'
import { updateOrder } from './updateOrder'
import { getOrderPaymentUrl } from './getOrderPaymentUrl'

export const OrdersApi = {
    createOrder,
    addBookingToOrder,
    getOrdersList,
    getDetailOrder,
    deleteOrder,
    deleteBookingFromOrder,
    updateOrder,
    getOrderPaymentUrl,
}
