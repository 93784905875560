import { axiosApi } from 'shared/api/axios'
import { BookingEndpoint } from 'entities/bookings/api/constants'
import { DetailBookingDTO } from 'entities/bookings/model/dtoTypes'
import { adaptDetailBookingDto } from 'entities/bookings/model/adapters/adaptDetailBookingDto'

export const getDetailBooking = async (id: number) => {
    try {
        const response = await axiosApi.get<DetailBookingDTO>(
            `${BookingEndpoint.bookings}${id}`,
        )
        return adaptDetailBookingDto(response.data)
    } catch (err) {
        console.error('Ошибка получения бронирований', err)
        return null
    }
}
