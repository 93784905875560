import { axiosApi } from 'shared/api/axios'
import { AttachmentsEndpoint } from 'entities/orderAttachments/api/constants'

export const deleteDocumentFromOrder = async (orderAttachmentId: number) => {
    try {
        await axiosApi.delete(
            `${AttachmentsEndpoint.attachment}/${orderAttachmentId}`,
        )
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получение документов')
    }
}
