import { validateTimeRange } from 'shared/utils/validateTimeRange';
import * as Yup from 'yup';

export const validateSchema = Yup.object().shape({
    name: Yup.string().required().min(3),
    price_per_hour: Yup.number().required().min(0),
    square: Yup.number().required(),
    ad_price_info: Yup.object().shape({
        morning_hours: Yup.string()
            .notRequired()
            .test("is-valid-time-range", "Invalid time format. Use HH:mm - HH:mm", validateTimeRange),
        evening_hours: Yup.string()
            .notRequired()
            .test("is-valid-time-range", "Invalid time format. Use HH:mm - HH:mm", validateTimeRange),
    }).nullable(),
    ad_booking_info: Yup.object().shape({
        available_online_booking_time: Yup.string()
            .notRequired()
            .test("is-valid-time-range", "Invalid time format. Use HH:mm - HH:mm", validateTimeRange),
        available_booking_time: Yup.string()
            .notRequired()
            .test("is-valid-time-range", "Invalid time format. Use HH:mm - HH:mm", validateTimeRange),
    }).nullable(),
});