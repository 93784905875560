import React, { FC, SyntheticEvent } from 'react'
import moment from 'moment'

import { InputContainer, Label } from 'shared/components/Input/style'
import { StyledDatePicker } from 'shared/components/DatePickerInput/style'

import './styles.css'

export type CalendarEvent = SyntheticEvent<Element, Event>
interface DatePickerInputProps {
    value?: Date | null
    onChange?: (date: Date | null, e: CalendarEvent) => void
    onSelect?: (date: Date | null, e?: CalendarEvent) => void
    placeholder?: string
    label?: string
    loading?: boolean
    disabled?: boolean
    required?: boolean
    editable?: boolean
    minDate?: Date
    maxDate?: Date
}

export const isDisabledBasedOnMinMaxDate = (
    date: Date,
    minDate?: Date,
    maxDate?: Date,
) => {
    if (minDate || maxDate) {
        if (minDate && !maxDate) {
            return moment(date).add(1, 'day').isBefore(minDate)
        }
        if (!minDate && maxDate) {
            return moment(date).subtract(1, 'day').isAfter(maxDate)
        }
        return (
            moment(date).subtract(1, 'day').isBefore(minDate) &&
            moment(date).add(1, 'day').isAfter(maxDate)
        )
    }
    return false
}

export const Locale = {
    sunday: 'Вск',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    ok: 'OK',
    today: 'Сегодня',
    yesterday: 'Вчера',
    hours: 'Час',
    minutes: 'Минут',
    seconds: 'Секунд',
}

export const DatePickerInput: FC<DatePickerInputProps> = ({
    value,
    onChange,
    onSelect,
    placeholder,
    label,
    loading,
    disabled,
    required,
    editable,
    minDate,
    maxDate,
}) => (
    <InputContainer>
        {label && (
            <Label>
                {required && <span className='required'>*</span>}
                {label}
            </Label>
        )}
        <StyledDatePicker
            shouldDisableDate={(date: Date) =>
                isDisabledBasedOnMinMaxDate(date, minDate, maxDate)
            }
            max={maxDate}
            format='dd/MM/yyyy'
            size='lg'
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onSelect={onSelect}
            locale={Locale}
            loading={loading}
            editable={editable}
            disabled={disabled}
        />
    </InputContainer>
)
