import { PaymentDTO } from 'entities/payments/model/dtoTypes'
import { Payment } from 'entities/payments/model/types'

export const adaptPaymentDTO = (source: PaymentDTO): Payment => ({
    id: source.id,
    amount: parseFloat(source.amount),
    orderId: source.order_id,
    createdAt: new Date(source.created_at),
    status: source.status,
    method: {
        type: source.method.type,
    },
    comment: source.comment,
    paymentUrl: source.payment_url,
})
