import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { SortingState } from "@tanstack/react-table";
import { TabsWrapper } from "pages/clients/style";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "shared/components/Tabs";
import { useDebounce } from "shared/hooks/useDebounce";
import { getNomenclature } from "../api/getNomenclature";
import { Table } from "shared/components/Table";
import { columns } from "../model/columns";
import { NOMENCLATUREPROFILE } from "shared/consts/routingConsts";
import { useSearchParams } from "react-router-dom";

interface IPropsNomenclatureTable {
    onSelectedRowsChange: (selectedRows: number[]) => void;
    search: string;
    selectedRows: any[];
}

export const NomenclatureTable = ({
    onSelectedRowsChange,
    search,
    selectedRows
}:IPropsNomenclatureTable) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    
    const [sorting, setSorting] = useState<SortingState>([]);
    const [typeNomenclature, setTypeNomenclature] = useState(0);

    const debouncedSearchTerm = useDebounce(search, 1000);

    const handleSelectionChange = (newSelectedRows: any[]) => {
        onSelectedRowsChange(newSelectedRows);
    };

    const handleTabChange = (index: number) => {
        setTypeNomenclature(index);
        handleSelectionChange([]);
    };

    const { isLoading, isError, data, refetch} = useQuery({
        queryKey: ["getNomenclature", currentPage, typeNomenclature, search, sorting], 
        queryFn: () => {
            const order_by = sorting.length ? sorting[0].id : "created_at";
            const order_asc = sorting.length ? sorting[0].desc === true : false;
            return getNomenclature(typeNomenclature, currentPage, search, order_by, order_asc);
        },
        enabled: debouncedSearchTerm !== null,
        placeholderData: keepPreviousData,
        retry: 3,
    });
    
    useEffect(() => {
        if (debouncedSearchTerm === "") {
            refetch();
        }
    }, [debouncedSearchTerm, refetch]);

    return (
        <TabsWrapper>
            <Tabs onTabChange={handleTabChange}>
                <Tab label="Оборудование">
                    {!isLoading && data && (
                        <Table 
                            className="clientsTable equipmentTable" 
                            data={data.nomenclatures}
                            columns={columns} 
                            columnWidths={['40px', '69px']}
                            selectedRows={selectedRows}
                            onSelectionChange={handleSelectionChange}
                            navigation={NOMENCLATUREPROFILE}
                            totalPages={data.pagination.total_pages}
                            page={currentPage}
                            onPageChange={(page) => setSearchParams({ page: (page).toString() })}
                            isLoading={isLoading}
                            isError={isError}
                            onSortChange={setSorting}
                        />
                    )}
                </Tab>
                <Tab label="Расходные материалы">
                    {!isLoading && data && (
                        <Table 
                            className="clientsTable equipmentTable" 
                            data={data.nomenclatures}
                            columns={columns} 
                            columnWidths={['40px', '69px']}
                            selectedRows={selectedRows}
                            onSelectionChange={handleSelectionChange}
                            navigation={NOMENCLATUREPROFILE}
                            totalPages={data.pagination.total_pages}
                            page={currentPage}
                            onPageChange={(page) => setSearchParams({ page: (page).toString() })}
                            isLoading={isLoading}
                            isError={isError}
                            onSortChange={setSorting}
                        />
                    )}
                </Tab>
            </Tabs>
        </TabsWrapper>
    )
}