import { axiosApi } from "shared/api/axios";
import { ProfileTypes } from "../types/ProfileTypes";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { AxiosError } from "axios";

export const handleEditProfile = async (
    values: ProfileTypes,
    refetch: () => void,
    setIsEditable: any,
    setEditButtonText: any,
    dispatch: any,
    id: any,
) => {
    try {
        const response = await axiosApi.patch(`/api/v1/counterparties/counterparty/${id}`, values);
        if(response.status !== 204) {
            setIsEditable(true);
            setEditButtonText("Сохранить");
        } else {
            refetch();
            setIsEditable(false);
            setEditButtonText("Редактировать");
            dispatch(showNotification({message: "Успешно!", type: "success"}))
        }
    } catch (err) {
        const error = err as AxiosError
        dispatch(showNotification({
            message: `Ошибка`, type: "error", errMessage: `Статус: ${error.status}`
        }))
        setIsEditable(true);
        setEditButtonText("Сохранить");
    }
};