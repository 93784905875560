import styled from 'styled-components'

export const DropdownButton = styled.div`
    color: #282a42;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > div {
        transform: rotate(-90deg);
    }
`

export const StyledMenuItems = styled.div`
    position: absolute;
    z-index: 9999;
    gap: 10px;
    border: 1px solid #e1e4ea;
    border-radius: 8px;
    margin-top: 5px;
    background: #fff;
`

export const StyledMenuItem = styled.div<{ isErrorColor: boolean }>`
    padding: 15px;
    cursor: pointer;
    color: ${props => (props.isErrorColor ? '#ED4627' : undefined)};

    &:hover {
        background: linear-gradient(
                0deg,
                rgba(94, 129, 244, 0.1) 0%,
                rgba(94, 129, 244, 0.1) 100%
            ),
            #fff;
    }
`

export const StyledLine = styled.div`
    width: 100%;
    border-bottom: 1px solid #f6f6f6;
`
