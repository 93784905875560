import { CreatePaymentAccountRequestDTO } from 'entities/paymentAccount/model/dtoTypes'
import { CreatePaymentAccountRequest } from 'entities/paymentAccount/model/types'

export const adaptCreatePaymentAccountRequestDTO = (
    source: CreatePaymentAccountRequest,
): CreatePaymentAccountRequestDTO => ({
    name: source.name,
    bik: source.bik,
    number: source.number,
    correspondent_number: source.correspondentNumber,
    bank_name: source.bankName,
    organization_id: source.organizationId,
})
