import styled from 'styled-components'
import { PaymentStatus, PaymentStatusType } from 'entities/payments/model'

export const StyledTextStatus = styled.span<{ status: PaymentStatusType, iscallback?: boolean }>`
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: ${props =>
        props.status === PaymentStatus.Completed
            ? '#34BD93'
            : props.status === PaymentStatus.Pending
              ? '#FB8E3D'
              : '#ED4627'};
    text-decoration: ${props => props.iscallback ? 'underline' : undefined};
    cursor: ${props => props.iscallback ? 'pointer' : undefined};
`

export const Text = styled.div`
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
`
