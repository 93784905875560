import { AddNomenclaturesRequestDTO } from '../dtoTypes'
import { AddNomenclaturesRequest } from '../types'

export const adaptAddNomenclaturesRequest = (
    addNomenclaturesRequest: AddNomenclaturesRequest,
): AddNomenclaturesRequestDTO => ({
    booking_id: addNomenclaturesRequest.bookingId,
    nomenclature_id: addNomenclaturesRequest.nomenclatureId,
    count: addNomenclaturesRequest.count,
    absolut_discount: addNomenclaturesRequest?.absolutDiscount,
    percent_discount: addNomenclaturesRequest?.percentDiscount,
})
