import styled from "styled-components";

export const FallBackTitle = styled.div`
    font-size: 27.9px;
    font-weight: bold;
    color: #282A42;
    margin-bottom: 30px;
`;

export const FallBackWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;