import { axiosApi } from 'shared/api/axios'
import { adaptCreatePaymentAccountRequestDTO } from 'entities/paymentAccount/model/adapters/adaptCreatePaymentAccountRequestDTO'
import { PaymentAccountsEndpoint } from 'entities/paymentAccount/api/constants'
import { PaymentAccountDTO } from 'entities/paymentAccount/model/dtoTypes'
import { CreatePaymentAccountRequest } from 'entities/paymentAccount/model'
import { adaptPaymentAccountDTO } from 'entities/paymentAccount/model/adapters/adaptPaymentAccountDTO'

export const createPaymentAccount = async (
    data: CreatePaymentAccountRequest,
) => {
    try {
        const response = await axiosApi.post<PaymentAccountDTO>(
            PaymentAccountsEndpoint.paymentAccount,
            adaptCreatePaymentAccountRequestDTO(data),
        )
        return adaptPaymentAccountDTO(response.data)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания реквезитов')
    }
}
