import { Checkbox } from "shared/components/Checkbox";
import { SettingRow, SettingWrapper } from "./style"
import { useEffect, useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { GetMethodPayment } from "../api/getMethodPayments";
import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";
import { Icon } from "shared/components/Icons";
import { PostMethodPayment } from "../api/postMethodPayments";
import { useDispatch } from "react-redux";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { MethodPayments } from "../types";
import { PatchMethodPayments } from "../api/patchMethodPayments";

export const SettingBookings = () => {
    const dispatch = useDispatch();
    
    const [paymentMethods, setPaymentMethods] = useState<MethodPayments[]>([]);

    const [value, setValue] = useState("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    
    const { isLoading, data, refetch } = useQuery({
        queryKey: ["getSettingPaymentMethods"], 
        queryFn: () => {
            return GetMethodPayment();
        },
        placeholderData: keepPreviousData,
        retry: 3,
    });

    useEffect(() => {
        if (data && paymentMethods.length === 0) {
            setPaymentMethods(data);
        }
    }, [data])

    const createPaymentMethods = async (name: string) => {
        setLoading(true);
        const values = {
            name: name,
            type: "CASH"
        }
        try {
            PostMethodPayment(values).then(() => {
                dispatch(showNotification({
                    message: "Успешно",
                    type: "success"
                }));
            }).finally(() => {
                refetch();
                setValue("");
                setLoading(false);
            })
        } catch (err) {
            console.error(err);
        }
    }

    const handleCheckboxChange = (id: number, checked: boolean, name: string) => {
        setPaymentMethods((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, is_active: checked } : item
            )
        );

        const values = {
            "name": name,
            "is_active": checked
        }

        PatchMethodPayments(id, values).then(() => {
            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));
        }).finally(() => {
            refetch();
        })
    };

    return (
        <SettingWrapper>
            <div className="title">Способы оплаты</div>
                {!isLoading && data && (
                    <SettingRow>
                        {paymentMethods.map((item, index) => (
                            <div key={index} className="notificationBlock">
                                <div className="notificationBlock__title">{item.name}</div>
                                <div className="notificationBlock__checkbox-list">
                                    <Checkbox 
                                        name={String(item.id)} 
                                        checked={item.is_active}
                                        onChange={() =>
                                            handleCheckboxChange(item.id, !item.is_active, item.name)
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="notificationBlock">
                            <div className="notificationBlock__title">Свое</div>
                            <div className="notificationBlock__input">
                                <Input value={value}  placeholder="Напишите" onChange={handleChange} />
                                <Button onClick={() => createPaymentMethods(value)} loading={loading} bgLoading="white">
                                    <Icon name="checkTwo" />
                                </Button>
                            </div>
                        </div>
                    </SettingRow>
                )}
        </SettingWrapper>
    )
}