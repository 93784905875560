import styled from "styled-components";

export const NotFoundStyled = styled.div`
    height: 100vh;
    background: #fff url('/notFound/bg.png') no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
        width: 100%;
        max-width: 364px;
        height: 50px;
    }
`;

export const NotFoundTitle = styled.div`
    font-size: 27.9px;
    font-weight: bold;
    color: #282A42;
    margin-bottom: 30px;
`;

export const NotFoundImg = styled.img`
    width: 100%;
    max-width: 936px;
    min-height: 664px;
    height: auto;
    display: block;
    margin: 0 auto;
`;