import { axiosApi } from "shared/api/axios";
import { IPropsCertificate, IPropsCertificates } from "../types/CertificateTypes";

// TODO: Rewrite and add adapters
const getCertificate = async (counterparty_id: string | undefined) => {
    try {
        const response = await axiosApi.get<IPropsCertificates>(`/api/v1/certificates/certificate/?counterparty_id=${counterparty_id}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получение сертификата");
    }
};

const createCertificate = async (certificateData: IPropsCertificate) => {
    try {
        const response = await axiosApi.post(`/api/v1/certificates/certificate/`, certificateData);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка создания сертификата");
    }
};

const deleteCertificate = async (certificateId: number) => {
    try {
        const response = await axiosApi.delete(`/api/v1/certificates/certificate/${certificateId}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка удаления сертификата");
    }
};

export const useCertificates = () => {

    const handleCreateCertificate = async (certificateData: IPropsCertificate) => {
        await createCertificate(certificateData);
    };

    const handleDeleteCertificate = async (certificateId: number) => {
        await deleteCertificate(certificateId);
    };

    const handleGetCertificate = async (counterparty_id: string | undefined) => {
        return await getCertificate(counterparty_id);
    };

    return {
        handleCreateCertificate,
        handleDeleteCertificate,
        handleGetCertificate
    }
}
