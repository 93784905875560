import styled from "styled-components";

export const HallsListWrapper = styled.div`
    .halls {
        &__list {
            display: flex;
            gap: 30px;
            margin-top: 10px;
            margin-bottom: 10px;
            a {
                display: block;
                color: #282A42;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                position: relative;
                text-decoration: none;
                &.active {
                    &:after {
                        content: "";
                        width: 100%;
                        height: 4px;
                        background-color: ${({ theme }) => theme.themeColors.main};
                        border-radius: 2px;
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                    }
                }
            }
        }
        &__info {
            margin-top: 30px;
            display: flex;
            gap: 20px;
            height: 100%;
            &-left {
                width: 100%;
                max-width: 426px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .halls__card {
                    height: 417px;
                }
            }
            &-right {
                width: 100%;
                max-width: 100%;
                .halls__card {
                    height: 100%;
                }
            }
        }
        &__card {
            border-radius: 8px;
            background: #F4F3FA;
            padding: 20px;
        }
    }
`;