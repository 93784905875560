import { MouseEvent, FC } from 'react'
import { Button } from 'shared/components/Button'
import { Icon } from 'shared/components/Icons'

interface DownloadButtonProps {
    readUrl: string
    fileName: string
    downloadUploadFile: (
        readUrl: string,
        fileName: string,
        e?: MouseEvent,
    ) => void
}

export const DownloadButton: FC<DownloadButtonProps> = ({
    readUrl,
    fileName,
    downloadUploadFile,
}) => (
    <Button
        onClick={e => downloadUploadFile(readUrl, fileName, e)}
        className='buttonFile'
        themeType='outline-2'
        style={{ borderColor: '#E1E4EA', background: 'none' }}
    >
        <Icon name='download' />
    </Button>
)
