import moment from "moment";
import { StyledStatusChips } from "../ui/style";

export const columnOrderClients = [
    {
        header: "№ заказа",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "id"
    },
    {
        header: "Заказчик",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "counterparty.full_name"
    },
    {
        header: "Телефон",
        cell: (row: any) => {
            return row.renderValue()
        },
        accessorKey: "counterparty.phone"
    },
    {
        header: "Дата",
        cell: (row: any) => {
            return moment(row.renderValue()).format("DD.MM.YYYY, HH:MM")
        },
        accessorKey: "created_at"
    },
    {
        header: "Статус",
        cell: (row: any) => {
            const status: string = row.renderValue();
            
            let text = "";
            let background = "";
            let color = "";
    
            if (status === "pending") {
                text = "В ожидании";
                background = "#FFF5CC";
                color = "#CCA300";
            } else if (status === "paid") {
                text = "Оплачено";
                background = "#D9F2DD";
                color = "#359742";
            } else if (status === "completed") {
                text = "Завершено";
                background = "#D9F2DD";
                color = "#359742";
            } else if (status === "cancelled") {
                text = "Отменено";
                background = "#FFE5E5";
                color = "#FF6666";
            } else if (status === "refund") {
                text = "Возврат";
                background = "#FFE5E5";
                color = "#FF6666";
            }
    
            return (
                <StyledStatusChips background={background} color={color}>
                    {text}
                </StyledStatusChips>
            );
        },
        accessorKey: "status"
    },    
    {
        header: "Стоимость",
        cell: (row: any) => {
            return row.renderValue();
        },
        accessorKey: "total_price"
    },
]