import styled from "styled-components";

export const StyledItem = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    .wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            color: #8181A5;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.06px;
        }
    }
    a, button {
        background: none;
        cursor: pointer;
        height: 39px;
        width: 39px;
        border: 1px solid #E1E4EA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s linear;
        &:hover {
            background: linear-gradient(0deg, rgba(94, 129, 244, 0.10) 0%, rgba(94, 129, 244, 0.10) 100%),#FFF;
            border: none;
            transition: all 0.4s linear;
        }
        div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const TabsWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    .tabContainer {
        gap: 30px;
        .tabHeaders {
            gap: 30px;
            .tabHeader {
                width: auto;
                font-weight: 500;
                line-height: 20px;
                color: #282A42;
                &.active {
                    color: #282A42;
                }
            }
            .underline {
                border-radius: 2px;
                height: 4px;
                background-color: ${({ theme }) => theme.themeColors.main};
            }
        }
    }
`;

export const WrapperAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const StyledWrapperTable = styled.div`
    margin-top: 20px;
    .orderProfile {
        .row {
            height: 43px;
            .bodyCell {
                
            }
        }
    }
`