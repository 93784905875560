import { FC } from 'react'
import moment from 'moment'

import { Input } from 'shared/components/Input'

import { BookingDetailsProps } from '../BookingPreview/types'
import { getAllNomenclatureNames } from '../OrderTotalPricePreview/utils'

export const BookingDetails: FC<BookingDetailsProps> = ({
    bookingToPreview,
}) => (
    <>
        <Input
            label='Помещение'
            value={bookingToPreview?.area?.name ?? ''}
            disabled
        />
        <Input
            label='Количество человек'
            value={bookingToPreview?.membersCount ?? ''}
            disabled
        />
        <Input
            label='Дата начала'
            value={
                moment(bookingToPreview?.startTime).format(
                    'MMMM Do YYYY, HH:mm',
                ) ?? ''
            }
            disabled
        />
        <Input
            label='Дата конца'
            value={
                moment(bookingToPreview?.endTime).format(
                    'MMMM Do YYYY, HH:mm',
                ) ?? ''
            }
            disabled
        />
        <Input
            label='Дополнения'
            value={
                bookingToPreview?.nomenclatures?.length
                    ? getAllNomenclatureNames(bookingToPreview)
                    : ''
            }
            disabled
        />
        <Input
            label='Комментарий'
            value={bookingToPreview?.comment ?? ''}
            disabled
        />
    </>
)
