import { useThemeContext } from 'app/providers/providers';
import React from 'react';

const FolderIcon = () => {
    const { theme } = useThemeContext();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M4.82801 13.17C4.22939 15.3968 4.22134 17.7696 4.81997 19.9965C5.55192 22.7193 7.77627 24.7997 10.5661 25.3708L10.7603 25.4105C13.5571 25.983 16.4429 25.983 19.2397 25.4105L19.4339 25.3708C22.2237 24.7997 24.4481 22.7193 25.18 19.9965C25.7787 17.7696 25.7706 15.3968 25.172 13.17C24.4513 10.4891 22.2509 8.40222 19.504 7.83999C16.5328 7.23185 13.4672 7.23185 10.496 7.83999M4.82801 13.17C5.54869 10.4891 7.74913 8.40222 10.496 7.83999M4.82801 13.17C4.58017 14.0919 4.43357 15.0389 4.38933 15.9901V8.9235C4.38933 6.59519 5.99608 4.54435 8.2659 3.92598C8.83229 3.77168 9.42469 3.69141 10.0122 3.69141H11.0846C13.1862 3.69141 15.6526 5.30475 16.5579 7.43795C15.05 7.43823 11.9802 7.5362 10.496 7.83999M18.2628 10.7901L18.3161 10.7989C20.5447 11.1663 22.1782 13.0731 22.1782 15.3075M4.38933 17.2221V17.2435L4.3905 17.2467C4.3901 17.2385 4.38971 17.2303 4.38933 17.2221Z" stroke={theme.themeColors.main} strokeLinecap="round"/>
        </svg>
    )
};

export default FolderIcon;