import { FC } from 'react'

import { Input } from 'shared/components/Input'
import { BookingTotalPricePreview } from '../BookingTotalPricePreview/BookingTotalPricePreview'

import {
    CounterpartyDetails,
    StyledContainer,
    BookingDetails,
} from '../components'
import { BookingPreviewProps } from './types'
import { PaymentsPreview } from 'features/OrderPreview/ui'

export const BookingPreview: FC<BookingPreviewProps> = ({
    detailOrder,
    bookingToPreview,
    counterparty,
    payments,
    redirectUrl,
}) => (
    <StyledContainer>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
                gap: 10,
            }}
        >
            <Input
                name=''
                label='Номер заказа'
                value={detailOrder.id}
                disabled
            />
            <CounterpartyDetails counterparty={counterparty} />
            <BookingDetails bookingToPreview={bookingToPreview} />
            {payments && (
                <PaymentsPreview
                    payments={payments}
                    redirectUrl={redirectUrl}
                />
            )}
        </div>
        <BookingTotalPricePreview
            totalPrice={detailOrder.totalPriceWithDiscount}
            booking={bookingToPreview}
        />
    </StyledContainer>
)
