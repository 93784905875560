import { axiosApi } from 'shared/api/axios'
import { OrderEndpoint } from './constants'
import { DeleteBookingRequest } from '../model'

export const deleteBookingFromOrder = async ({
    orderId,
    bookingId,
}: DeleteBookingRequest) => {
    try {
        await axiosApi.delete(`${OrderEndpoint.bookings}${bookingId}`, {
            params: {
                order_id: orderId,
            },
        })
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
