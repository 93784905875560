export enum PaymentStatus {
    Pending = 'PENDING',
    Completed = 'COMPLETED',
    Failed = 'FAILED',
}

export const PaymentStatusLabels = {
    [PaymentStatus.Pending]: 'Ожидание оплаты',
    [PaymentStatus.Completed]: 'Оплачено',
    [PaymentStatus.Failed]: 'Ошибка оплаты',
} as { [key in PaymentStatus]: string }

export enum PaymentMethodName {
    Cash = 'CASH',
    Certificate = 'CERTIFICATE',
    Online = 'ONLINE',
    Account = 'ACCOUNT',
}

export const PaymentMethodLabels = {
    [PaymentMethodName.Cash]: 'Наличными',
    [PaymentMethodName.Certificate]: 'Сертификат',
    [PaymentMethodName.Online]: 'Онлайн',
    [PaymentMethodName.Account]: 'Счет на оплату',
} as { [key in PaymentMethodName]: string }

export type PaymentStatusType =
    (typeof PaymentStatus)[keyof typeof PaymentStatus]

export type PaymentMethodType =
    (typeof PaymentMethodName)[keyof typeof PaymentMethodName]
