import { Form, Formik } from "formik"
import { useState } from "react"
import { Avatar } from "shared/components/Avatar";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { StyledLine, WrapperAvatar } from "shared/styles/GlobalStyle";
import { SettingsBtnsRow, WrapperSettingProfile } from "./style";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { ProfileFormData } from "../model/profileFormData";
import { Button } from "shared/components/Button";

export const SettingProfile = () => {

    const [isEditable, setIsEditable] = useState(false);

    const handlePhotoChange = async () => {
        setIsEditable(true);
    }

    return (
        <WrapperSettingProfile>
            <SettingsBtnsRow>
                <Button>Сохранить</Button>
                <Button themeType="outline-2">Редактировать</Button>
            </SettingsBtnsRow>
            <Formik
                initialValues={{}}
                onSubmit={(values) => console.log(values)}
            >
                {() => {
                    return (
                        <Form>
                            <WrapperAvatar>
                                {isEditable ? (
                                    <UploadAvatar 
                                        initialPhoto={null}
                                        onPhotoChange={handlePhotoChange}
                                    />
                                ) : (
                                    <Avatar photo={null} name={"Дмитрий Трофимов"}
                                    jobs={"Администратор"} />
                                )}
                            </WrapperAvatar>
                            <StyledLine margin="20px 0"/>
                            <WrapperFormRow
                                title="Общие данные" 
                                formData={ProfileFormData.map(row => 
                                    row.map(item => ({ 
                                        ...item, 
                                        disabled: item.name === "created_at" ? true : !isEditable,
                                        placeholder: isEditable ? item.placeholder : ""
                                    })
                                ))} 
                            />
                        </Form>
                    )
                }}
            </Formik>
        </WrapperSettingProfile>
    )
}