import { AttachmentsEndpoint } from 'entities/orderAttachments/api/constants'
import { axiosApi } from 'shared/api/axios'
import { adaptAddDocumentRequest } from 'entities/orderAttachments/model/adapters/adaptAddDocumentRequest'
import { AddDocumentRequest } from 'entities/orderAttachments/model'

export const addDocumentsToOrder = async (orderId: number, body: AddDocumentRequest) => {
    try {
        await axiosApi.post(
            `${AttachmentsEndpoint.order}/${orderId}`,
            adaptAddDocumentRequest(body)
        )
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получение документов')
    }
}
