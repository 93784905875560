import { axiosApi } from "shared/api/axios";

export const getAreasSingle = async (id?: any): Promise<any> => {
    try {
        const response = await axiosApi.get<any>(`/api/v1/areas/area/${id}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получения зала");
    }
};