import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { BookingsApi } from '../../api'
import { QueryKeys } from './constants'
import { Booking, GetBookingsRequest } from '../types'

export const useGetBookingsListQuery = (
    filter?: GetBookingsRequest,
    options?: UseQueryOptions,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetAllBookings, Object.values(filter ?? {})],
            queryFn: () => BookingsApi.getBookings(filter),
            ...(options ?? {}),
        })

    return {
        bookings: data as Booking[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
