import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { OrderBooking } from 'entities/orders/model'
import {
    editBooking,
    OrderBookingWithOptionsFields,
    selectBookingFormMode,
    BookingMode,
} from '../slices'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'

const normalizeDateTime = (newStart: moment.Moment, newEnd: moment.Moment) => {
    const now = moment()

    newStart.set({ second: 0, millisecond: 0 })
    newEnd.set({ second: 0, millisecond: 0 })

    const validStart = newStart.isBefore(now) ? now : newStart
    let validEnd = newEnd.isBefore(now) ? now : newEnd

    if (validStart.isSameOrAfter(validEnd)) {
        validEnd = validStart.clone().add(1, 'hour')
    }

    if (validStart.isSameOrAfter(validEnd.clone().subtract(1, 'hour'))) {
        validEnd = validStart.clone().add(1, 'hour')
    }

    return { validStart, validEnd }
}

export const useBookingFormDateTimeHelper = (
    activeBooking: OrderBooking | null,
) => {
    const dispatch = useDispatch()
    const mode = useSelector(selectBookingFormMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])

    const start = activeBooking?.startTime ?? null
    const end = activeBooking?.endTime ?? null

    const { checkIsBookingAlreadyCreated } = useIsBookingsAlreadyCreated()
    const isPastCompleteEvent = useMemo(() => {
        if (!checkIsBookingAlreadyCreated(activeBooking)) {
            return false
        }
        if (!start || !end || !isEdit) {
            return false
        }
        const now = moment()
        return (
            moment(start).add(5, 'minutes').isBefore(now) ||
            moment(end).add(5, 'minutes').isBefore(now)
        )
    }, [start, end, isEdit])

    const isDisableDateEditing = isEdit && isPastCompleteEvent

    const onEditBooking = (
        bookingId: number,
        body: OrderBookingWithOptionsFields,
    ) => {
        dispatch(editBooking({ bookingId, body }))
    }

    const onEditStartEndDate = (newDateRange: [Date, Date] | null) => {
        if (activeBooking) {
            if (!newDateRange) {
                onEditBooking(activeBooking.id, {
                    startTime: null,
                    endTime: null,
                })
                return
            }
            const [startDate, endDate] = newDateRange

            const startMoment = moment(startDate).set({
                hour: start?.getHours() ?? 0,
                minute: start?.getMinutes() ?? 0,
                second: start?.getSeconds() ?? 0,
            })
            const endMoment = moment(endDate).set({
                hour: end?.getHours() ?? 0,
                minute: end?.getMinutes() ?? 0,
                second: end?.getSeconds() ?? 0,
            })

            const { validStart, validEnd } = normalizeDateTime(
                startMoment,
                endMoment,
            )

            onEditBooking(activeBooking.id, {
                startTime: validStart.toDate(),
                endTime: validEnd.toDate(),
            })
        }
    }

    const onEditStartEndTime = (newDateRange: [Date, Date] | null) => {
        if (activeBooking) {
            if (!newDateRange) {
                onEditBooking(activeBooking.id, {
                    startTime: null,
                    endTime: null,
                })
                return
            }
            const [startTime, endTime] = newDateRange

            const startMoment = moment(start || new Date()).set({
                hour: startTime.getHours(),
                minute: startTime.getMinutes(),
                second: startTime.getSeconds(),
            })
            const endMoment = moment(end || new Date()).set({
                hour: endTime.getHours(),
                minute: endTime.getMinutes(),
                second: endTime.getSeconds(),
            })

            const { validStart, validEnd } = normalizeDateTime(
                startMoment,
                endMoment,
            )

            onEditBooking(activeBooking.id, {
                startTime: validStart.toDate(),
                endTime: validEnd.toDate(),
            })
        }
    }

    return {
        isDisableDateEditing,
        start,
        end,
        onEditStartEndDate,
        onEditStartEndTime,
    }
}
