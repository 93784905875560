import { FormEvent, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { AccountDetails } from 'features/DetailsPayments/ui/DetailsPaymentsModal'
import { useFillStore } from 'features/Booking/model/hooks/useFillStore'
import { DetailsPaymentsModal } from 'features/DetailsPayments/ui'
import { Text } from 'features/Booking/ui/components/Forms/styles'
import { useGetCounterpartyByIdQuery } from 'entities/counterparties/model'
import {
    Payment,
    PaymentMethodName,
    PaymentMethodType,
    useGetPaymentMethodsQuery,
    useGetPaymentsQuery,
} from 'entities/payments/model'
import { PaymentApi } from 'entities/payments/api'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'
import { useCertificatesQuery } from 'entities/certificates/model/hooks'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { showNotification } from 'shared/redux/slice/notificationSlice'

import {
    selectBookingCounterpartyId,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    selectIsCompanyBookingForm,
} from '../slices'

type CertificateOption = AutocompleteOption<{ balance: string }>
type PaymentTypeOption = AutocompleteOption<{ type: PaymentMethodType }>

export const usePaymentFormController = () => {
    const dispatch = useDispatch()
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const orderId = useSelector(selectBookingOrderId)
    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const isCompany = useSelector(selectIsCompanyBookingForm)
    const updateBookings = useFillStore()

    const [paymentTypeOption, setPaymentTypeOption] =
        useState<PaymentTypeOption>()
    const paymentType = paymentTypeOption?.payload?.type
    const [certificate, setCertificate] = useState<CertificateOption>()
    const [accountDetails, setAccountDetails] = useState<AccountDetails>()

    const [comment, setComment] = useState<string>()
    const [amount, setAmount] = useState<number>()

    const isPaid = useMemo(
        () =>
            !!originalOrder?.totalAmountPaid &&
            !!originalOrder?.totalPriceWithDiscount &&
            originalOrder?.totalAmountPaid.toString() ===
                originalOrder?.totalPriceWithDiscount.toString(),
        [originalOrder],
    )

    const { counterparty } = useGetCounterpartyByIdQuery(counterpartyId ?? null)

    const { paymentMethods } = useGetPaymentMethodsQuery(true)
    const paymentTypesOptions: PaymentTypeOption[] = useMemo(
        () =>
            paymentMethods
                ?.filter(method =>
                    isCompany
                        ? true
                        : method.type !== PaymentMethodName.Account,
                )
                .map(method => ({
                    label: method.name,
                    value: method.id.toString(),
                    payload: {
                        type: method.type,
                    },
                })),
        [paymentMethods, isCompany],
    )

    const { certificates } = useCertificatesQuery(counterpartyId, {
        enabled:
            !!counterpartyId && paymentType === PaymentMethodName.Certificate,
    })
    const certificatesOptions: CertificateOption[] = useMemo(
        () =>
            certificates
                ?.filter(cert => moment(cert.expiredAt).isAfter(moment()))
                ?.map(cert => ({
                    label: `${cert.name} (₽${cert.balance})`,
                    value: cert.id.toString(),
                    payload: {
                        balance: cert.balance.toString(),
                    },
                })),
        [certificates],
    )
    const currentCertificateBalance = useMemo(
        () => certificate?.payload?.balance ?? '0',
        [certificate],
    )

    const isButtonDisabled = useMemo(
        () =>
            !paymentType ||
            (paymentType === PaymentMethodName.Account && !accountDetails),
        [paymentTypeOption, accountDetails],
    )

    const { payments, refetch } = useGetPaymentsQuery()
    const currentPayments = payments?.filter(p => p.orderId === orderId) ?? []

    const onChangePaymentType = (option: PaymentTypeOption) => {
        setPaymentTypeOption(option)
    }

    const onChangeCertificate = (option: CertificateOption) => {
        setCertificate(option)
    }

    const onChangeComment = (e: FormEvent) => {
        const newComment = (e.target as HTMLInputElement).value
        setComment(newComment)
    }

    const onEditAmount = (e: FormEvent) => {
        const newAmount = Math.abs(Number((e.target as HTMLInputElement).value))
        if (Number.isNaN(newAmount)) {
            return
        }
        setAmount(newAmount)
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const showErrorAlert = () => {
        dispatch(
            showNotification({
                message: 'Ошибка',
                type: 'error',
            }),
        )
    }

    const makeCashPayment = async () => {
        try {
            await PaymentApi.createCashPayment({
                orderId: orderId!,
                amount: amount!,
                comment,
            })
            showSuccessAlert()
        } catch (e) {
            console.error(e)
            showErrorAlert()
        }
    }

    const makeCertificatePayment = async () => {
        try {
            if (!certificate?.value) {
                return showErrorAlert()
            }

            await PaymentApi.createCertificatePayment({
                orderId: orderId!,
                certificateId: Number(certificate.value),
                comment,
            })
            showSuccessAlert()
        } catch (e) {
            console.error(e)
            showErrorAlert()
        }
    }

    const redirectUrl = useMemo(
        () => `${window.location.origin}/invoice/${orderId}`,
        [orderId],
    )

    const onGoToInvoicePage = () => {
        const redirectUrl = `${window.location.origin}/invoice/${orderId}`
        window?.open(redirectUrl, '_blank')?.focus()
    }

    const makeOnlinePayment = async () => {
        try {
            await PaymentApi.createOnlinePayment({
                orderId: orderId!,
                amount: amount!,
                redirectUrl,
                comment,
            })

            dispatch(
                openModal({
                    isOpen: true,
                    content: (
                        <div>
                            <Text>
                                Отправьте эту ссылку заказчику, чтобы он смог
                                оплатить
                            </Text>
                            <div />
                            <Link to={redirectUrl}>{redirectUrl}</Link>
                        </div>
                    ),
                    config: {
                        title: 'Ссылка на оплату',
                        btns: [
                            {
                                onClick: () => {
                                    navigator.clipboard.writeText(redirectUrl)
                                },
                                title: 'Копировать',
                                active: true,
                            },
                            {
                                onClick: () => dispatch(closeModal()),
                                title: 'Закрыть',
                                active: false,
                            },
                        ],
                    },
                }),
            )
            showSuccessAlert()
        } catch (e) {
            console.error(e)
            showErrorAlert()
        }
    }

    const finishAccountPayment = async () => {
        try {
            if (!accountDetails) {
                return
            }

            await PaymentApi.createAccountPayment({
                orderId: orderId!,
                comment,
                ...accountDetails,
            })
            showSuccessAlert()
        } catch (e) {
            console.error(e)
            showErrorAlert()
        }
    }

    const fillAccountPaymentDetails = async () => {
        dispatch(
            openModal({
                isOpen: true,
                isFullScreen: true,
                content:
                    originalOrder && counterparty ? (
                        <DetailsPaymentsModal
                            detailOrder={originalOrder}
                            counterparty={counterparty}
                            onPaymentProcess={setAccountDetails}
                            details={accountDetails}
                        />
                    ) : (
                        <></>
                    ),
                config: {
                    title: 'Выставление счета',
                    btns: [
                        {
                            onClick: () => dispatch(closeModal()),
                            title: 'Закрыть',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const confirmAccountPayment = async (payment: Payment) => {
        try {
            await PaymentApi.confirmAccountPayment(payment.id)
            showSuccessAlert()
        } catch (e) {
            console.error(e)
            showErrorAlert()
        }
    }
    const openConfirmAccountPaymentModal = async (payment: Payment) => {
        dispatch(
            openModal({
                isOpen: true,
                content: <Text>Подтвердить платеж?</Text>,
                config: {
                    title: 'Подтверждение платежа',
                    btns: [

                        {
                            onClick: () => {
                                confirmAccountPayment(payment).then()
                                dispatch(closeModal())
                            },
                            title: 'Подтвердить платеж',
                            active: true,
                        },
                        {
                            onClick: () => dispatch(closeModal()),
                            title: 'Скачать',
                            active: false,
                        },
                    ],
                },
            }),
        )
    }

    const paymentHandlers: Record<
        PaymentMethodType,
        () => Promise<void> | void
    > = useMemo(
        () => ({
            [PaymentMethodName.Cash]: makeCashPayment,
            [PaymentMethodName.Certificate]: makeCertificatePayment,
            [PaymentMethodName.Online]: makeOnlinePayment,
            [PaymentMethodName.Account]: finishAccountPayment,
        }),
        [
            makeCashPayment,
            makeCertificatePayment,
            makeOnlinePayment,
            finishAccountPayment,
        ],
    )

    const onGoNext = async () => {
        if (!orderId || !paymentType) return

        const paymentHandler = paymentHandlers?.[paymentType] ?? makeCashPayment
        if (paymentHandler) {
            await paymentHandler()
            await updateBookings(orderId, undefined, true)
            await refetch()
        }
    }

    const paymentCallbacks = {
        [PaymentMethodName.Account]: openConfirmAccountPaymentModal,
    }

    return {
        isPaid,
        isButtonDisabled,
        redirectUrl,

        amount,
        onEditAmount,

        certificate,
        currentCertificateBalance,
        certificatesOptions,
        onChangeCertificate,

        currentPayments,
        paymentTypeOption,
        paymentType,
        paymentTypesOptions,
        onChangePaymentType,
        paymentCallbacks,

        comment,
        onChangeComment,

        onGoNext,
        fillAccountPaymentDetails,
        onGoToInvoicePage,
    }
}
