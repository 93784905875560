import { useState } from "react";
import { ToggleButton, ToggleContainer, ToggleLabel } from "./style";

interface IPropsToggleSwitch {
    title: string;
    onChange: () => void;
    active: boolean;
}

export const ToggleSwitch = ({
    title,
    onChange,
    active
}: IPropsToggleSwitch) => {

    const [isActive, setIsActive] = useState<boolean>(active);

    const handleToggle = (e: any) => {
        e.preventDefault();
        setIsActive(prevState => !prevState);
        onChange();
    };

    return (
        <ToggleContainer>
            <ToggleLabel>{title}</ToggleLabel>
            <ToggleButton isActive={isActive} onClick={(e) => handleToggle(e)} />
        </ToggleContainer>
    );
}