import { axiosApi } from "shared/api/axios";
import { EmployeesResponse } from "../types";

export const getEmployees = async (
    currentPage: number, 
    search_param?: string,
    order_by?: any,
    order_asc?: any,
    signal?: AbortSignal
): Promise<EmployeesResponse> => {
    try {
        const baseUrl = `/api/v1/users/user/?page=${currentPage}&size=10&order_asc=${order_asc}&order_by=${order_by}`;
        const searchFilter = search_param ? `&search_param=${encodeURIComponent(search_param)}` : "";
        const url = `${baseUrl}${searchFilter}`;
        const response = await axiosApi.get<EmployeesResponse>(url, {signal});
        return response.data;
    } catch (err) {
        console.error("Error fetching clients", err);
        return { users: [], pagination: { total_pages: 0, current_page: 0, page_size: 0 } };
    }
};