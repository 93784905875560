import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from 'shared/redux/slice/notificationSlice';

const useDownloadFile = () => {
    const dispatch = useDispatch();
    
    const downloadFile = useCallback(async (fileUrl: string, fileName: string) => {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                dispatch(showNotification({ message: 'Не удалось скачать файл', type: 'error' })); 
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            dispatch(showNotification({ message: 'Не удалось скачать файл', type: 'error', errMessage: error.message })); 
        }
    }, []);

    return downloadFile;
};

export default useDownloadFile;
