import { axiosApi } from "shared/api/axios";
import { MethodPayments } from "../types";

export const GetMethodPayment = async (): Promise<MethodPayments[]> => {
    try {
        const url = `/api/v1/payments/payment/methods`;
        const response = await axiosApi.get<MethodPayments[]>(url);
        return response.data;
    } catch (err) {
        console.error("Error fetching methods payments", err);
        return [];
    }
};