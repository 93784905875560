import moment, { Moment } from 'moment'
import { PeriodSelectorWrapper } from './style'
import { Icon } from '../Icons'
import 'moment/locale/ru'
import { useDispatch, useSelector } from 'react-redux'
import { selectToday, setToday } from '../../../features/Booking/model/slices'

moment.locale('ru')

interface IPropsPeriodSelector {
    period: 'day' | 'week' | 'month'
}

export const PeriodSelector = ({ period = 'day' }: IPropsPeriodSelector) => {
    const currentDateString = useSelector(selectToday)
    const currentDate = moment(currentDateString)

    const dispatch = useDispatch()

    const setCurrentDate = (newDate: Moment) => {
        dispatch(setToday({ today: newDate.toDate().toISOString() }))
    }

    const handlePrev = () => {
        switch (period) {
            case 'day':
                setCurrentDate(currentDate.clone().subtract(1, 'days'))
                break
            case 'week':
                setCurrentDate(currentDate.clone().subtract(1, 'weeks'))
                break
            case 'month':
                setCurrentDate(currentDate.clone().subtract(1, 'months'))
                break
            default:
                break
        }
    }

    const handleNext = () => {
        switch (period) {
            case 'day':
                setCurrentDate(currentDate.clone().add(1, 'days'))
                break
            case 'week':
                setCurrentDate(currentDate.clone().add(1, 'weeks'))
                break
            case 'month':
                setCurrentDate(currentDate.clone().add(1, 'months'))
                break
            default:
                break
        }
    }

    const renderDate = () => {
        switch (period) {
            case 'day':
                return currentDate.format('dd, DD MMMM YYYY')
            case 'week': {
                const startOfWeek = currentDate.clone().startOf('isoWeek')
                const endOfWeek = currentDate.clone().endOf('isoWeek')
                return `${startOfWeek.format('dd, DD.MM.YYYY')} - ${endOfWeek.format('dd, DD.MM.YYYY')}`
            }
            case 'month':
                return currentDate.format('MMMM YYYY')
            default:
                return ''
        }
    }

    return (
        <PeriodSelectorWrapper>
            <div className='arrow arrow--left' onClick={handlePrev}>
                <Icon name='arrowRight' />
            </div>
            <span>{renderDate()}</span>
            <div className='arrow arrow--right' onClick={handleNext}>
                <Icon name='arrowRight' />
            </div>
        </PeriodSelectorWrapper>
    )
}
