import { useInfiniteQuery } from '@tanstack/react-query'
import { QueryKeys } from './constants'
import { NomenclaturesApi } from '../../api'

export enum NomenclaturesType {
    AdditionalService = 0,
    Product = 1,
}

export interface UseCounterpartyAsyncAutocompleteOptionsParams {
    page?: number
    size?: number
    search_param?: string
    order_by?: string
    order_asc?: boolean
    type_filter?: NomenclaturesType
}

export const defaultOptions = {
    order_by: 'created_at',
    order_asc: false,
    black_list_filter: false,
}

export const useCounterpartyAsyncAutocompleteOptions = (
    options?: UseCounterpartyAsyncAutocompleteOptionsParams,
) => {
    const keysToWatch = options ? Object.values(options) : []
    const {
        data,
        refetch,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        hasPreviousPage,
        isFetchingNextPage,
        isFetchingPreviousPage,
    } = useInfiniteQuery({
        queryKey: [QueryKeys.GetAllNomenclatureQueryOptions, ...keysToWatch],
        queryFn: ({ pageParam }) =>
            NomenclaturesApi.getNomenclaturesOptions({
                ...defaultOptions,
                ...options,
                page: pageParam,
            }),
        initialPageParam: 1,
        getNextPageParam: lastPage => {
            const currentPage = lastPage.pagination.current_page
            const totalPages = lastPage.pagination.total_pages

            if (currentPage === totalPages) {
                return
            }
            return currentPage + 1
        },
        getPreviousPageParam: firstPage => {
            const currentPage = firstPage.pagination.current_page

            if (currentPage === 1) {
                return
            }
            return currentPage - 1
        },
    })

    const nomenclatures = data?.pages?.map(page => page.nomenclatures)?.flat()

    return {
        nomenclatures,
        refetch,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        hasPreviousPage,
        isFetchingNextPage,
        isFetchingPreviousPage,
        isFetching: isFetchingNextPage || isFetchingPreviousPage,
    }
}
