import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { BookingsApi } from '../../api'
import { QueryKeys } from './constants'
import { DetailBooking } from '../types'

export const useGetDetailBookingQuery = (
    id: number | null,
    options?: UseQueryOptions,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: null,
            queryKey: [QueryKeys.DetailBooking, id],
            queryFn: id ? () => BookingsApi.getDetailBooking(id) : () => null,
            ...(options ?? {}),
        })

    return {
        booking: data as DetailBooking,
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
