import moment from "moment";

export const GeneralData = [
    [
        { 
            name: "contact.first_name", 
            label: "Имя", 
            placeholder: "Иван", 
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            disabled: false,
            required: true,
        },
        { 
            name: "contact.surname", 
            label: "Фамилия", 
            placeholder: "Иванов", 
            type: "text",
            disabled: false,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            required: true,
        },
        { 
            name: "contact.patronymic", 
            label: "Отчество", 
            placeholder: "Иванович", 
            type: "text",
            disabled: false,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
        },
    ],
    [
        { 
            name: "phone", 
            label: "Телефон", 
            placeholder: "+7-999-999-9999", 
            maskConfig: { mask: '+{7}-000-000-00-00' }, 
            disabled: false, 
            type: "text",
            required: true
        },
        { 
            name: "email", 
            label: "Почта", 
            type: "email",
            disabled: false,
            placeholder: "Ivanov.Ivan@gmail.com",
            required: true
        },
        { 
            name: "additional_data.registration_date", 
            label: "Дата регистрации",
            value: moment().format("DD.MM.YYYY"),
            disabled: true,
            placeholder: moment().format("DD.MM.YYYY"),
            type: "text" 
        },
        { 
            name: "discount", 
            label: "Постоянная скидка (%)", 
            placeholder: "10%",
            disabled: false, 
            type: "text",
            maskConfig: {
                mask: Number,
                min: 0,
                max: 100,
            }
        }
    ]
];