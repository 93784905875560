import { MouseEvent, FC } from 'react'
import { DeleteLocalImage } from 'entities/uploadFiles/ui/style'

interface DeleteButtonProps {
    itemId: number
    handleDelete: (itemId: number, e?: MouseEvent) => void
}

export const DeleteButton: FC<DeleteButtonProps> = ({
    itemId,
    handleDelete,
}) => (
    <DeleteLocalImage onClick={e => handleDelete(itemId, e)}>
        <svg
            width='7'
            height='6'
            viewBox='0 0 7 6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.37399 5.27124C6.41393 5.31106 6.44562 5.35836 6.46725 5.41045C6.48887 5.46255 6.5 5.51839 6.5 5.5748C6.5 5.6312 6.48887 5.68705 6.46725 5.73914C6.44562 5.79123 6.41393 5.83854 6.37399 5.87835C6.29315 5.95817 6.18412 6.00293 6.07053 6.00293C5.95693 6.00293 5.84791 5.95817 5.76706 5.87835L3.5 3.61062L1.23294 5.87835C1.15209 5.95817 1.04307 6.00293 0.929473 6.00293C0.815878 6.00293 0.706854 5.95817 0.626008 5.87835C0.586068 5.83854 0.554377 5.79123 0.532754 5.73914C0.511131 5.68705 0.5 5.6312 0.5 5.5748C0.5 5.51839 0.511131 5.46255 0.532754 5.41045C0.554377 5.35836 0.586068 5.31106 0.626008 5.27124L2.89307 3.00351L0.626008 0.735776C0.545524 0.655268 0.500309 0.546076 0.500309 0.432221C0.500309 0.375846 0.511409 0.320022 0.532977 0.267938C0.554545 0.215854 0.586157 0.16853 0.626008 0.128666C0.66586 0.0888029 0.71317 0.0571815 0.765239 0.0356076C0.817308 0.0140337 0.873114 0.00292969 0.929473 0.00292969C1.04329 0.00292969 1.15245 0.0481585 1.23294 0.128666L3.5 2.3964L5.76706 0.128666C5.84755 0.0481585 5.95671 0.00292969 6.07053 0.00292969C6.18435 0.00292969 6.29351 0.0481585 6.37399 0.128666C6.45448 0.209174 6.49969 0.318366 6.49969 0.432221C6.49969 0.546076 6.45448 0.655268 6.37399 0.735776L4.10693 3.00351L6.37399 5.27124Z'
                fill='white'
            />
        </svg>
    </DeleteLocalImage>
)
