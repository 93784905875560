import { PaymentMethodDTO } from 'entities/payments/model/dtoTypes'
import { PaymentMethod } from 'entities/payments/model/types'

export const adaptPaymentMethodDTO = (
    source: PaymentMethodDTO,
): PaymentMethod => ({
    id: source.id,
    name: source.name,
    isActive: source.is_active,
    type: source.type,
})
