import { AddDocumentRequestDTO } from 'entities/orderAttachments/model/dtoTypes'
import { AddDocumentRequest } from 'entities/orderAttachments/model'

export const adaptAddDocumentRequest = (
    body: AddDocumentRequest,
): AddDocumentRequestDTO =>
    body.map(doc => ({
        file_name: doc.fileName,
        hash_name: doc.hashName,
    }))
