import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useEffect } from 'react'
import deepEqual from 'fast-deep-equal'

import { useGetBookingsListQuery } from 'entities/bookings/model'
import { OrdersApi } from 'entities/orders/api'
import { RootState } from 'shared/redux/store'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'
import { closeSidebar } from 'shared/redux/slice/sidebar'
import {
    BookingMode,
    clearBookingState,
    OrderViewMode,
    removeBooking,
    selectBookingCounterpartyId,
    selectBookingFormMode,
    selectBookingFormOrderViewMode,
    selectBookingIdToEdit,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    selectBookings,
    selectIsCompanyBookingForm, selectOrderEditStateInitialising,
    selectTimeFilter,
    setBookingFormOrderViewMode,
    setIsDirty,
} from '../slices'

export const useBookingSidebarController = () => {
    const dispatch = useDispatch()
    const timeFilter = useSelector(selectTimeFilter)
    const orderViewMode = useSelector(selectBookingFormOrderViewMode)
    const isEditStateInitialising = useSelector(
        selectOrderEditStateInitialising,
    )
    const { refetch: refetchBookings } = useGetBookingsListQuery(timeFilter)

    const { isOpen } = useSelector((state: RootState) => state.sidebar)
    const mode = useSelector(selectBookingFormMode)
    const orderId = useSelector(selectBookingOrderId)
    const isCompany = useSelector(selectIsCompanyBookingForm)

    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const currentFormBookings = useSelector(selectBookings)

    const previewBookingId = useSelector(selectBookingIdToEdit)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const isRefund = useMemo(
        () =>
            isEdit &&
            orderViewMode === OrderViewMode.Refund &&
            !!previewBookingId,
        [orderViewMode, isEdit, previewBookingId],
    )

    const isPreview = useMemo(
        () =>
            isEdit &&
            orderViewMode === OrderViewMode.Preview &&
            !!previewBookingId,
        [orderViewMode, isEdit, previewBookingId],
    )

    const isPaid = useMemo(
        () =>
            !!originalOrder?.totalAmountPaid &&
            !!originalOrder?.totalPriceWithDiscount &&
            originalOrder?.totalAmountPaid.toString() ===
            originalOrder?.totalPriceWithDiscount.toString(),
        [originalOrder],
    )

    const goFromPreviewToEdit = () => {
        dispatch(
            setBookingFormOrderViewMode({ orderViewMode: OrderViewMode.Edit }),
        )
    }
    const activeTabIndex = isEdit ? (isCompany ? 1 : 0) : undefined
    const disabledTabIndex = isEdit ? (isCompany ? 0 : 1) : undefined

    const handleCloseSidebar = () => {
        dispatch(clearBookingState())
        dispatch(closeSidebar())
    }

    const onDeleteOrder = async () => {
        if (!orderId) {
            return
        }

        const deleteOrder = async () => {
            await OrdersApi.deleteOrder({ orderId })
            dispatch(clearBookingState())
            await refetchBookings()
        }

        const content = <>Вы уверены, что хотите удалить заказ?</>
        const title =
            'Вы точно хотите удалить этот заказ? Все его бронирования будут удалены.'
        const btns = [
            {
                onClick: () => {
                    deleteOrder()
                    dispatch(closeModal())
                    dispatch(closeSidebar())
                },
                title: 'Да',
                active: true,
            },
            {
                onClick: () => dispatch(closeModal()),
                title: 'Нет',
                active: false,
            },
        ]

        dispatch(
            openModal({
                isOpen: true,
                content,
                config: {
                    title,
                    btns,
                },
            }),
        )
    }

    const onRemoveBooking = async () => {
        if (!previewBookingId || !orderId) {
            return
        }

        const deleteBooking = async () => {
            await OrdersApi.deleteBookingFromOrder({
                orderId,
                bookingId: previewBookingId,
            })
            await refetchBookings()
        }
        const content = <>Вы уверены, что хотите удалить это бронирование?</>
        const title = 'Вы точно хотите удалить это бронирование?'
        const btns = [
            {
                onClick: () => {
                    deleteBooking().then()
                    dispatch(removeBooking({ bookingId: previewBookingId }))
                    dispatch(closeModal())
                    dispatch(closeSidebar())
                },
                title: 'Да',
                active: true,
            },
            {
                onClick: () => dispatch(closeModal()),
                title: 'Нет',
                active: false,
            },
        ]

        dispatch(
            openModal({
                isOpen: true,
                content,
                config: {
                    title,
                    btns,
                },
            }),
        )
    }

    const isDirty = useMemo(() => {
        if (mode === BookingMode.Create) {
            return !!counterpartyId && !orderId
        }

        if (mode === BookingMode.Edit) {
            if (!originalOrder) {
                return false
            }

            if (originalOrder.bookings.length !== currentFormBookings.length) {
                return true
            }

            if (
                counterpartyId &&
                originalOrder.counterpartyId !== counterpartyId
            ) {
                return true
            }

            return originalOrder.bookings.some(originalBooking => {
                const currentBooking = currentFormBookings.find(
                    booking => booking.id === originalBooking.id,
                )
                return !deepEqual(originalBooking, currentBooking)
            })
        }
        return false
    }, [mode, orderId, counterpartyId, currentFormBookings, originalOrder])

    useEffect(() => {
        dispatch(setIsDirty({ isDirty }))
    }, [isDirty])

    const onChangeTab = () => {
        if (isDirty && mode === BookingMode.Create) {
            const content =
                'У вас есть несохраненные изменения. Вы уверены, что хотите закрыть ?'
            const title =
                'Вы точно хотите удалить этот заказ? Все его бронирования будут удалены.'
            const btns = [
                {
                    onClick: () => {
                        dispatch(clearBookingState())
                        dispatch(closeModal())
                    },
                    title: 'Да',
                    active: true,
                },
                {
                    onClick: () => dispatch(closeModal()),
                    title: 'Нет',
                    active: false,
                },
            ]

            dispatch(
                openModal({
                    isOpen: true,
                    content,
                    config: {
                        title,
                        btns,
                    },
                }),
            )
            return
        }
    }

    const onGoToPaymentRedirectUrl = () => {
        const url = `${window.location.origin}/invoice/${orderId}`
        window?.open(url, '_blank')?.focus()
    }

    const onGoRefund = () => {
        dispatch(
            setBookingFormOrderViewMode({ orderViewMode: OrderViewMode.Refund }),
        )
    }

    return {
        isRefund,
        isCompany,
        isEditStateInitialising,
        onGoToPaymentRedirectUrl,
        activeTabIndex,
        disabledTabIndex,
        isOpen,
        isPreview,
        isEdit,
        isPaid,
        goFromPreviewToEdit,
        onChangeTab,
        handleCloseSidebar,
        onDeleteOrder,
        onRemoveBooking,
        onGoRefund,
    }
}
