import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { GetCreatePaymentDto } from 'entities/payments/model/dtoTypes'
import { CreateCashPaymentRequest } from 'entities/payments/model'
import { adaptCreateCashPaymentRequest } from 'entities/payments/model/adapters/adaptCreateCashPaymentRequest'
import { adaptPaymentDTO } from 'entities/payments/model/adapters/adaptPaymentDTO'

export const createCashPayment = async (body: CreateCashPaymentRequest) => {
    try {
        const response = await axiosApi.post<GetCreatePaymentDto>(
            PaymentEndpoint.CreateCashPayment,
            adaptCreateCashPaymentRequest(body),
        )
        return adaptPaymentDTO(response.data)
    } catch (err) {
        console.error('Ошибка создания платежа', err)
        throw new Error('Ошибка создания платежа')
    }
}
