import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { CreateOnlinePaymentResponseDto } from 'entities/payments/model/dtoTypes'
import { CreateOnlinePaymentRequest } from 'entities/payments/model'
import { adaptCreateOnlinePaymentRequest } from 'entities/payments/model/adapters/adaptCreateOnlinePaymentRequest'
import { adaptCreateOnlinePaymentResponseDTO } from 'entities/payments/model/adapters/adaptCreateOnlinePaymentResponseDTO'

export const createOnlinePayment = async (body: CreateOnlinePaymentRequest) => {
    try {
        const response = await axiosApi.post<CreateOnlinePaymentResponseDto>(
            PaymentEndpoint.CreateOnlinePayment,
            adaptCreateOnlinePaymentRequest(body),
        )
        return adaptCreateOnlinePaymentResponseDTO(response.data)
    } catch (err) {
        console.error('Ошибка создания платежа', err)
        throw new Error('Ошибка создания платежа')
    }
}
