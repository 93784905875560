import { FC } from 'react'

import {
    StyledHeader,
    StyledHeaderTitle,
    StyledSecondaryLinkText,
    StyledSecondaryText,
} from './styles'

interface InvoiceHeaderProps {
    id: string
    createdAt: string
}

export const InvoiceHeader: FC<InvoiceHeaderProps> = ({ id, createdAt }) => (
    <StyledHeader>
        <StyledHeaderTitle style={{ minWidth: 200 }}>
            Бронь: {id}
        </StyledHeaderTitle>
        <StyledSecondaryLinkText>
            Посмотреть правила компании
        </StyledSecondaryLinkText>
        <StyledSecondaryText style={{ minWidth: 200 }}>
            Создано: {createdAt}
        </StyledSecondaryText>
    </StyledHeader>
)
