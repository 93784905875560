import styled from "styled-components";

export const WrapperFormRowStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    &:last-child {
        margin-bottom: 0;
    }
    .title {
        color: #282A42;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .row {
            display: flex;
            gap: 10px;
        }
    }
    label {
        font-size: 12px;
        line-height: 20px;
    }
    input {
        font-size: 14px;
    } 
`;