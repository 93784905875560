import { axiosApi } from 'shared/api/axios'
import { OrganisationsEndpoint } from 'entities/organisations/api/constants'
import { GetOrganisationsResponseDTO } from 'entities/organisations/model/dtoTypes'
import { adaptOrganisationDto } from 'entities/organisations/model/adapters/adaptOrganisationDto'

export const getOrganisations = async () => {
    try {
        const response = await axiosApi.get<GetOrganisationsResponseDTO>(
            OrganisationsEndpoint.organization,
        )
        return response.data.organizations.map(adaptOrganisationDto)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получения организаций')
    }
}
