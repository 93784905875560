import { FC } from 'react'

import {
    Payment,
    PaymentMethodLabels,
    PaymentMethodName,
    PaymentStatus,
    PaymentStatusLabels,
} from 'entities/payments/model'
import { Line } from 'shared/components/Line'

import {
    StyledStatus,
    StyledTable,
    StyledTableCell,
    StyledTableHeader,
    StyledTableRow,
    StyledTableWrapper,
} from './styles'
import moment from 'moment'

const InvoiceDetailTableColumns = [
    { key: 'date', title: 'Дата', textAlign: 'left' },
    { key: 'paymentWay', title: 'Способ оплаты', textAlign: 'center' },
    { key: 'total', title: 'Сумма', textAlign: 'center' },
    { key: 'paymentAction', title: '', textAlign: 'center' },
]

interface InvoicePaymentsTableProps {
    payments: Payment[]
}

export const InvoicePaymentsTable: FC<InvoicePaymentsTableProps> = ({
    payments,
}) => (
    <StyledTableWrapper>
        <StyledTable>
            <thead>
                <tr>
                    {InvoiceDetailTableColumns.map(column => (
                        <StyledTableHeader
                            key={column.key}
                            style={{
                                textAlign: column.textAlign as any,
                            }}
                        >
                            {column.title}
                        </StyledTableHeader>
                    ))}
                </tr>
            </thead>
            <tbody>{renderRows(payments)}</tbody>
        </StyledTable>
    </StyledTableWrapper>
)

const renderRows = (payments: Payment[]) => {
    return payments.map(payment => (
        <>
            <StyledTableRow>
                <StyledTableCell>
                    {moment(payment.createdAt).format('DD.MM.YYYY HH:mm')}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    {PaymentMethodLabels[payment.method.type]}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    ₽{payment.amount}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    {payment.method.type === PaymentMethodName.Online &&
                    payment.status === PaymentStatus.Pending ? (
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={payment.paymentUrl}
                            color='#34BD93'
                        >
                            Оплатить ({PaymentMethodLabels[payment.method.type]})
                        </a>
                    ) : (
                        <StyledStatus status={payment.status}>
                            {PaymentStatusLabels[payment.status]}
                        </StyledStatus>
                    )}
                </StyledTableCell>
            </StyledTableRow>

            <tr>
                <StyledTableCell colSpan={InvoiceDetailTableColumns.length}>
                    <Line />
                </StyledTableCell>
            </tr>
        </>
    ))
}
