import { SettingThemeList, SettingThemeWrapper } from "./style"
import { Icon } from "shared/components/Icons";
// import { useLocalStorage } from "@uidotdev/usehooks";
import { useThemeContext } from "app/providers/providers";

const themeColorList = ["#5E81F4", "#FFD400", "#FF4747", "#7856FF", "#FF7A00", "#00BA7C"];

export const SettingTheme = () => {
    const { theme, setThemeColor } = useThemeContext();

    const handleThemeColorChange = (color: string) => {
        setThemeColor(color);
    };

    return (
        <SettingThemeWrapper>
            <div className="title">Основной цвет</div>
            <SettingThemeList>
                {themeColorList.map((item, index) => (
                    <div onClick={() => handleThemeColorChange(item)} key={index} className="block" style={{backgroundColor: item}}>
                        {item === theme.themeColors.main && <Icon name="checkTwo" />}
                    </div>
                ))}
            </SettingThemeList>
        </SettingThemeWrapper>
    )
}