import styled from "styled-components";

export const RightMenu = styled.div`
    display: flex;
    gap: 10px;
`

export const TabsWrapper = styled.div`
    height: 100%;
    .tabContainer {
        position: relative;
        .tabHeaders {
            background: #F4F3FA;
            border-radius: 8px;
            gap: 6px;
            width: 100%;
            max-width: 531px;
            .tabHeader {
                color: #8181A5;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 12px 0;
                &.active {
                    background: inherit;
                    color: #282A42;
                }
                span {
                    position: relative;
                    z-index: 99;
                }
            }
            .underline {
                height: 39px;
                bottom: 0;
                background-color: #FFF;
                border-radius: 8px;
                border: 1px solid ${({ theme }) => theme.themeColors.main};
            }
        }
    }
`;

export const WrapperSearch = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 256px;
    height: 39px;
    z-index: 999;
    .styledWrapperInput {
        .styledBeforeIcon {
            height: 16px;
        }
        .styledInput {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            color: #8181A5;
            padding-left: 30px;
        }
    }
`;