import { DashboardLayout } from "app/layout"
import { useMemo, useState } from "react"
import { Button } from "shared/components/Button"
import { Input } from "shared/components/Input"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"
import { useNavigate } from "react-router-dom"
import { NomenclatureTable } from "features/Nomenclature/ui/NomenclatureTable"
import { WrapperSearch } from "pages/clients/style"
import { NomenclatureWrapper } from "./style"
import { useDispatch } from "react-redux"
import { axiosApi } from "shared/api/axios"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { showNotification } from "shared/redux/slice/notificationSlice"
import { useQueryClient } from "@tanstack/react-query"
import { NOMENCLATURECREATE } from "shared/consts/routingConsts"

export const NomenclatureList = () => {
    const queryClient = useQueryClient()
    
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [search, setSearch] = useState<string>("");

    const hasSelectedRows = useMemo(() => {
        return selectedRows.length > 0;
    }, [selectedRows]);

    const handleDelete = async (selectedRows: any[]) => {
        const arraySelected = selectedRows.map((item) => item.id);
        try {
            const response = await axiosApi.delete(`/api/v1/nomenclatures/nomenclature/`, {
                data: arraySelected
            });
            if (response.status === 200) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                setSelectedRows([]);
                await queryClient.refetchQueries({
                    queryKey: ['getNomenclature'],
                    exact: false,
                });
                dispatch(closeModal());
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (error) {
            dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
        }
    };

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    }

    const handleClick = (to: string) => {
        navigate(to);
    }

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                {!hasSelectedRows ? (
                    <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                        <Button onClick={() => handleClick(NOMENCLATURECREATE)}>+Добавить оборудование</Button>
                    </div>
                ) : (
                    <Button 
                        themeType="delete" 
                        onClick={() => dispatch(openModal({
                            isOpen: true,
                            content: (
                                <>
                                    Вы удалите доп.оборудование: <br/>
                                    <b>
                                        {selectedRows
                                            .map(item => {
                                            const name = item.name;
                                            return [name]
                                                .filter(Boolean)
                                                .join(' ');
                                            })
                                            .filter(Boolean)
                                            .join(', ')
                                        }
                                    </b>
                                </>
                            ),
                            config: {
                                title: "Вы точно хотите удалить?",
                                btns: [
                                    {
                                        onClick: () => handleDelete(selectedRows),
                                        title: "Да",
                                        active: true,
                                    },
                                    {
                                        onClick: () => dispatch(closeModal()),
                                        title: "Нет",
                                        active: false
                                    }
                                ]
                            }
                        }))}
                    >Удалить оборудование</Button>
                )}
            </div>
        }>
            <NomenclatureWrapper>
                <WrapperSearch>
                    <Input 
                        name="" 
                        $beforeIcon="search" 
                        placeholder="Поиск"
                        onChange={onSearch}
                    />
                </WrapperSearch>
                <NomenclatureTable 
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    search={search}
                />
            </NomenclatureWrapper>
        </DashboardLayout>
    )
}