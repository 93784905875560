import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectBookingOriginalOrder } from 'features/Booking/model/slices'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import { Text } from 'features/Booking/ui/components/Refund/styles'
import { RefundBooking, RefundPaymentRequest } from 'entities/payments/model'
import { OrderBooking } from 'entities/orders/model'
import { PaymentApi } from 'entities/payments/api'
import { Checkbox } from 'shared/components/Checkbox'
import { Flex } from 'shared/components/Flex'
import { Button } from 'shared/components/Button'

export const RefundOrder = () => {
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const [bookingsToRefund, setBookingsToRefund] =
        useState<RefundPaymentRequest>({ bookings: [] })

    const onChangeBookingsToRefund = (booking: OrderBooking) => {
        const ifExist = bookingsToRefund?.bookings?.find(
            bkg => bkg.id === booking.id,
        )
        if (ifExist) {
            const newBookingsToRefund = {
                bookings: bookingsToRefund?.bookings?.filter(
                    bkg => bkg.id !== ifExist.id,
                ),
            }
            setBookingsToRefund(newBookingsToRefund)
            return
        }

        const bookingToRefund: RefundBooking = {
            area: {
                count: getBookingRange(booking!).diff,
            },
            id: booking.id,
            nomenclatures: (booking?.nomenclatures ?? []).map(nomenclature => ({
                id: nomenclature.id,
                count: nomenclature.count,
            })),
        }
        const newBookingsToRefund = {
            bookings: [...(bookingsToRefund?.bookings ?? []), bookingToRefund],
        }
        setBookingsToRefund(newBookingsToRefund)
    }

    const isChecked = (booking: OrderBooking) => {
        const ifExist = bookingsToRefund?.bookings?.find(
            bkg => bkg.id === booking.id,
        )
        return !!ifExist
    }

    const refund = async () => {
        if (!originalOrder?.id) {
            return
        }
        await PaymentApi.refundOrder(originalOrder.id, bookingsToRefund)
    }

    const fullRefund = async () => {
        if (!originalOrder?.id) {
            return
        }
        const bookingsToRefund: RefundBooking[] = originalOrder?.bookings?.map(
            booking => ({
                area: {
                    count: getBookingRange(booking!).diff,
                },
                id: booking.id,
                nomenclatures: (booking?.nomenclatures ?? []).map(
                    nomenclature => ({
                        id: nomenclature.id,
                        count: nomenclature.count,
                    }),
                ),
            }),
        )

        const fullBookingsToRefund = {
            bookings: bookingsToRefund
        }
        await PaymentApi.refundOrder(originalOrder.id, fullBookingsToRefund)
    }

    return (
        <Flex flexDirection='column' gap={10}>
            <Flex>
                {(originalOrder?.bookings ?? []).map(booking => (
                    <>
                        <Flex gap={10}>
                            <Checkbox
                                name='checkbox'
                                checked={isChecked(booking)}
                                onChange={() =>
                                    onChangeBookingsToRefund(booking)
                                }
                            />
                            <Text>{booking?.area?.name}</Text>
                        </Flex>
                    </>
                ))}
            </Flex>
            <Button disabled={bookingsToRefund?.bookings?.length < 1} onClick={refund}>Оформить возврат</Button>
            <Button onClick={fullRefund}>Оформить полный возврат</Button>
        </Flex>
    )
}
