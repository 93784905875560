import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
    StyledLine,
    StyledMenuItem,
    StyledMenuItems,
} from 'shared/components/Dropdown/style'
import { ReactNode } from 'react'

interface DropdownButtonProps {
    menuButton?: ReactNode | string
    buttons: {
        title: string
        isErrorColor?: boolean
        onClick: () => void
    }[]
}

export const DropdownButton = ({
    menuButton,
    buttons,
}: DropdownButtonProps) => {
    return (
        <Menu>
            <MenuButton>
                {menuButton ?? (
                    <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z'
                            fill='#8181A5'
                        />
                        <path
                            d='M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z'
                            fill='#8181A5'
                        />
                        <path
                            d='M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z'
                            fill='#8181A5'
                        />
                    </svg>
                )}
            </MenuButton>

            <MenuItems as={StyledMenuItems} transition anchor='bottom end'>
                {buttons.map((button, index) => (
                    <>
                        <MenuItem
                            onClick={button.onClick}
                            isErrorColor={button.isErrorColor ?? false}
                            as={StyledMenuItem}
                        >
                            {button.title}
                        </MenuItem>
                        {index < buttons.length - 1 && <StyledLine />}
                    </>
                ))}
            </MenuItems>
        </Menu>
    )
}
