import { useDispatch, useSelector } from 'react-redux'
import { FormEvent, useMemo } from 'react'

import { OrderBooking } from 'entities/orders/model'
import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { roundNumber } from 'shared/utils/roundNumber'
import { AutocompleteOption } from 'shared/components/Autocomplete'

import {
    addBooking,
    editBooking,
    OrderBookingWithOptionsFields,
    selectBookings,
    setActiveBookingId,
    setCommentToBooking,
    setMembersCountToBooking,
} from '../slices'

export const useBookingFormDateSettings = (
    activeBooking: OrderBooking | null,
) => {
    const dispatch = useDispatch()
    const bookings = useSelector(selectBookings)

    const onEditMembersCount = (e: FormEvent) => {
        if (activeBooking) {
            const newMembersCount = Math.abs(
                Number((e.target as HTMLInputElement).value),
            )
            if (Number.isNaN(newMembersCount)) {
                return
            }
            dispatch(
                setMembersCountToBooking({
                    bookingId: activeBooking.id,
                    newMembersCount,
                }),
            )
        }
    }

    const onEditComment = (e: FormEvent) => {
        if (activeBooking) {
            const newComment = (e.target as HTMLInputElement).value
            dispatch(
                setCommentToBooking({
                    bookingId: activeBooking.id,
                    newComment,
                }),
            )
        }
    }

    const comment = useMemo(() => activeBooking?.comment, [activeBooking])
    const membersCount = useMemo(
        () => activeBooking?.membersCount,
        [activeBooking],
    )

    const { areasResources } = useAreasResourcesQuery()

    const areasOptions: AutocompleteOption[] = useMemo(
        () =>
            areasResources
                ? areasResources.map(area => ({
                      value: area.id.toString(),
                      label: area.title,
                  }))
                : [],
        [areasResources],
    )
    const currentAreasOptions = useMemo(
        () =>
            areasOptions.find(
                area => area.value === activeBooking?.area?.id?.toString(),
            ),
        [activeBooking, areasOptions],
    )

    const onEditBooking = (
        bookingId: number,
        body: OrderBookingWithOptionsFields,
    ) => {
        dispatch(editBooking({ bookingId, body }))
    }

    const onEditArea = (area: AutocompleteOption) => {
        const activeBookingId = activeBooking?.id
        const areaId = Number(area.value)
        const areaName = area.label

        if (activeBookingId) {
            onEditBooking(activeBookingId, {
                area: {
                    id: areaId,
                    name: areaName ?? '',
                },
            })
            return
        }

        if (!activeBookingId || !bookings?.length) {
            const bookingId = new Date().getMilliseconds() ** 2
            dispatch(
                addBooking({
                    id: bookingId,
                    areaPrice: 0,
                    comment: '',
                    nomenclatures: [],
                    nomenclaturePrice: 0,
                    nomenclaturePriceWithDiscount: 0,
                    areaPercentDiscount: null,
                    areaAbsolutDiscount: null,
                    areaTotalPrice: 0,
                    areaTotalPriceWithDiscount: 0,
                    totalPrice: 0,
                    totalPriceWithDiscount: 0,
                    membersCount: null,
                    area: {
                        id: areaId,
                        name: areaName ?? '',
                    },
                    startTime: null,
                    endTime: null,
                }),
            )
            dispatch(setActiveBookingId({ bookingId }))
            return
        }
    }

    const onAbsolutDiscountChange = (e: FormEvent) => {
        const newAbsolutDiscount = Math.abs(
            Number((e.target as HTMLInputElement).value),
        )
        if (Number.isNaN(newAbsolutDiscount) || !activeBooking) {
            return
        }

        const newAreaPercentDiscount = activeBooking.areaPrice
            ? roundNumber((newAbsolutDiscount / activeBooking.areaPrice) * 100)
            : 0
        dispatch(
            editBooking({
                bookingId: activeBooking.id,
                body: {
                    areaPercentDiscount: newAreaPercentDiscount,
                    areaAbsolutDiscount: newAbsolutDiscount,
                },
            }),
        )
    }

    const onPercentDiscountChange = (e: FormEvent) => {
        const newPercentDiscount = Math.abs(
            Number((e.target as HTMLInputElement).value),
        )
        if (Number.isNaN(newPercentDiscount) || !activeBooking) {
            return
        }

        const newAreaAbsolutDiscount = activeBooking.areaPrice
            ? (activeBooking.areaPrice * newPercentDiscount) / 100
            : 0
        dispatch(
            editBooking({
                bookingId: activeBooking.id,
                body: {
                    areaAbsolutDiscount: newAreaAbsolutDiscount,
                    areaPercentDiscount: newPercentDiscount,
                },
            }),
        )
    }
    return {
        activeBooking,
        currentAreasOptions,
        areasOptions,
        membersCount,
        onEditMembersCount,
        onEditArea,
        comment,
        onEditComment,
        onAbsolutDiscountChange,
        onPercentDiscountChange,
    }
}
