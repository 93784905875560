import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBookingCounterpartyId } from 'features/Booking/model/slices'
import { useCounterpartyAsyncAutocompleteOptions } from 'entities/counterparties/model'

import { emptyCall } from './getDynamicCreationReducerHook'

export const useBookingDynamicCreationReducers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { refetch } = useCounterpartyAsyncAutocompleteOptions()

    const postCreateAction = async (id: number) => {
        await refetch()

        dispatch(setBookingCounterpartyId({ id }))
        navigate(-1)
    }

    const onCancel = () => {
        navigate(-1)
    }

    return {
        beforeCreateAction: emptyCall,
        postCreateAction,
        onCancel,
    }
}
