import styled from 'styled-components'

export const WrapperCalendar = styled.div`
    height: 100%;
    .rbc-month-header {
        display: none;
    }
    .rbc-day-slot .rbc-events-container {
        margin-right: 0;
    }
    .rbc-time-header-gutter {
        margin-right: -1px;
    }
    .rbc-time-header-gutter::before {
        content: "Весь день";
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        padding-top: 20px;
        margin-right: -1px;
    }
    .rbc-allday-cell {
        background: #fff;
        border: 1px solid #e1e4ea;
        box-sizing: border-box;
    }
    /* Месяц календарь */
    .rbc-month-view {
        border-radius: 8px;
        border: 1px solid #e1e4ea;
        overflow: hidden;
        .rbc-day-bg {
            border-left: 1px solid #e1e4ea;
        }
        .rbc-off-range-bg {
            background: #f6f9ff;
        }
        .rbc-date-cell {
            text-align: left;
            padding-right: 0;
            padding-left: 12px;
            padding-top: 12px;
            display: flex;
            .rbc-button-link {
                color: #282a42;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .rbc-off-range {
            .rbc-button-link {
                color: #8181a5;
            }
        }
        .rbc-today {
            background: #f6f9ff;
        }
        .rbc-show-more {
            color: #34bd93;
            font-size: 12px;
            font-family: "Inter", sans-serif;
            font-weight: 600;
        }
    }
    /* Конец месяца календаря */

    .rbc-time-view {
        border: none;
        border-right: 1px solid #e1e4ea;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
    }
    .rbc-time-view-resources {
        display: grid !important;
        grid-template-rows: max-content max-content !important;
        overflow-y: auto;
    }
    .rbc-time-view-resources .rbc-label.rbc-time-header-gutter {
        background-color: #e1e4ea;
    }
    .rbc-time-header-content > .rbc-row.rbc-row-resource {
        border-bottom: none;
    }
    .rbc-time-header-content {
        border-left: 1px solid #e1e4ea;
    }
    .rbc-current-time-indicator {
        display: none;
    }
    .rbc-time-header {
        border: none;
        background-color: #e1e4ea;
        border-radius: 8px 8px 0 0;
        .rbc-time-header-gutter {
            min-width: 64px !important;
            max-width: 64px !important;
        }
        .rbc-header {
            border: none;
            color: #282a42;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            padding: 6px 0;
        }
    }
    .rbc-today {
        background: inherit;
    }
    .rbc-time-content {
        overflow-x: auto;
        scroll-behavior: smooth;
        border: none;
        .rbc-time-gutter {
            .rbc-timeslot-group {
                padding: 17px 10px !important;
                min-width: 64px !important;
                max-width: 64px !important;
                border-bottom: 1px solid #e1e4ea !important;
                border-left: none !important;
                span {
                    color: #1c1d21;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }
                &:nth-child(2n) {
                    span {
                        opacity: 0;
                    }
                }
            }
        }
        .rbc-time-column {
            .rbc-timeslot-group {
                min-height: 56px !important;
                max-height: 56px !important;
                border-left: 1px solid #e1e4ea;
                border-bottom: 1px solid #e1e4ea;
            }
        }
    }
`

export const StyledReadyEventsWrapper = styled.div`
    .rbc-event {
        background: #e3f8ef;
        color: #34bd93;
        border-color: transparent;
        border-left: 3px solid #34bd93;
    }
`

export const StyledEditEventsWrapper = styled.div`
    .rbc-event {
        background: #fae1c1;
        color: #bd7234;
        border-color: transparent;
        border-left: 3px solid #bd7234;
    }
`

export const StyledNewEventsWrapper = styled.div`
    .rbc-event {
        background: #e3e6f8;
        color: #4557ab;
        border-color: transparent;
        border-left: 3px solid #4557ab;
    }
`
