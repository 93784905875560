import { useSelector } from 'react-redux'

import { OrderTotalPricePreview } from 'features/OrderPreview/ui'

import {
    selectBookingOriginalOrder,
    selectBookings,
    selectOrderTotalPrice,
} from '../../../model/slices'

export const Footer = () => {
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const totalPrice = useSelector(selectOrderTotalPrice)
    const currentFormBookings = useSelector(selectBookings)

    if (!originalOrder) {
        return null
    }

    const detailOrder = {
        ...originalOrder,
        totalPrice: totalPrice ?? 0,
        bookings: currentFormBookings,
    }

    return <OrderTotalPricePreview detailOrder={detailOrder} />
}
