import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { QueryKeys } from 'entities/payments/model/hooks/constants'
import { PaymentApi } from 'entities/payments/api'
import { PaymentMethod } from 'entities/payments/model'

export const useGetPaymentMethodsQuery = (
    isActive: boolean | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetPaymentsMethods, isActive],
            queryFn: () => PaymentApi.getPaymentMethods(isActive),
            ...(options ?? {}),
        })

    return {
        paymentMethods: data as PaymentMethod[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
