import { useQuery } from '@tanstack/react-query'
import { Form, Formik, FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loader } from 'shared/components/Loader'
import { WrapperFormRow } from 'shared/components/WrapperFormRow'
import { GeneralData } from '../model/formData'
import { validateSchema } from '../model/validateSchema'
import { getOrg } from '../api/getOrg'
import { patchOrg } from '../api/patchOrg'
import {
    WrapperOrgHeader,
    WrapperOrgHeaderDesc,
    WrapperOrgHeaderTitle,
} from './style'
import { StyledLine } from 'shared/styles/GlobalStyle'
import { PaymentAccountsForm } from 'entities/paymentAccount/ui'

interface IPropsSettingOrgProfile {
    isEditable: boolean
    formikRef: React.Ref<FormikHelpers<any>>
    setIsEditable: (e: boolean) => void
    setEditButtonText: (e: string) => void
    setDisabled: (e: boolean) => void
}

export const SettingOrgProfile = ({
    isEditable,
    formikRef,
    setIsEditable,
    setEditButtonText,
    setDisabled,
}: IPropsSettingOrgProfile) => {
    const { id } = useParams()

    const dispatch = useDispatch()

    const { isLoading, data, refetch } = useQuery({
        queryKey: ['getOrgProfile', id],
        queryFn: () => getOrg(id),
        enabled: !!id,
        refetchOnWindowFocus: false,
    })

    const onSubmit = async (values: any) => {
        patchOrg(
            values,
            refetch,
            setIsEditable,
            setEditButtonText,
            dispatch,
            id,
        )
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {!isLoading && data && (
                <>
                    <Formik
                        key={id}
                        innerRef={formikRef as any}
                        initialValues={data}
                        onSubmit={onSubmit}
                        validationSchema={validateSchema}
                        enableReinitialize
                    >
                        {({ isValid }) => {
                            setDisabled(!isValid)
                            return (
                                <Form>
                                    {!isEditable && (
                                        <WrapperOrgHeader>
                                            <WrapperOrgHeaderTitle>
                                                {data.name}
                                            </WrapperOrgHeaderTitle>
                                            <WrapperOrgHeaderDesc>
                                                Поставщик
                                            </WrapperOrgHeaderDesc>
                                        </WrapperOrgHeader>
                                    )}
                                    <StyledLine margin='20px 0' />
                                    <WrapperFormRow
                                        title='Общие данные'
                                        formData={GeneralData.map(row =>
                                            row.map(item => ({
                                                ...item,
                                                disabled: !isEditable,
                                            })),
                                        )}
                                    />
                                </Form>
                            )
                        }}
                    </Formik>
                    <PaymentAccountsForm organizationId={Number(id)} />
                </>
            )}
        </>
    )
}
