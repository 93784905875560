import moment from 'moment'
import { Nomenclature, Nomenclature as OrderNomenclature, OrderBooking } from 'entities/orders/model'

export const getAllNomenclatureNames = (booking: OrderBooking) => {
    const getNomenclatures = () =>
        booking.nomenclatures.reduce((acc, nomenclature) => {
            const existingNomenclatureIndex = acc.findIndex(
                (item: OrderNomenclature) => item?.id === nomenclature.id,
            )
            if (existingNomenclatureIndex !== -1) {
                acc[existingNomenclatureIndex].count += nomenclature.count
            } else {
                acc.push({ ...nomenclature })
            }
            return acc
        }, [] as OrderNomenclature[])

    const getNomenclatureName = (nomenclature: Nomenclature) => {
        if (!nomenclature?.name) {
            return ''
        }

        return `${nomenclature.name} (${nomenclature.count})`
    }

    return getNomenclatures().map(getNomenclatureName).join(', ')
}

export const getBookingRange = (booking: OrderBooking) => {
    if (!booking?.startTime || !booking?.endTime) {
        return {
            diff: 0,
            label: '',
        }
    }

    const start = moment(booking.startTime)
    const end = moment(booking.endTime)
    const duration = moment.duration(end.diff(start))
    const diff = duration.hours()

    if (diff < 1) {
        const diffMinutes = duration.minutes()
        return {
            diff: diffMinutes,
            label: `${diffMinutes} мин.`,
        }
    }

    return {
        diff,
        label: `${diff} ч.`,
    }
}
