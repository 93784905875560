import { FC } from 'react'

import {
    StyledFooterColumn,
    StyledTotalAmount,
} from 'features/OrderPreview/ui/OrderTotalPricePreview/styles'
import { Input } from 'shared/components/Input'

interface OrderPaidStatusProps {
    total: number
    paid: number
}

export const OrderPaidStatus: FC<OrderPaidStatusProps> = ({ total, paid }) => (
    <>
        <StyledFooterColumn>
            <StyledTotalAmount mode='total'>Итого</StyledTotalAmount>
            <Input value={`₽${total}`} />
        </StyledFooterColumn>
        <StyledFooterColumn>
            <StyledTotalAmount mode='paid'>Оплачено</StyledTotalAmount>
            <Input value={`₽${paid}`} />
        </StyledFooterColumn>
        <StyledFooterColumn>
            <StyledTotalAmount mode='not_paid'>К оплате</StyledTotalAmount>
            <Input value={`₽${total - paid}`} />
        </StyledFooterColumn>
    </>
)
