import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { QueryKeys } from 'entities/organisations/model/hooks/constants'
import { OrganisationsApi } from 'entities/organisations/api/api'
import { Organisation } from 'entities/organisations/model/types'

export const useGetOrganisationsQuery = (
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetAllOrganisations],
            queryFn: () => OrganisationsApi.getOrganisations(),
            ...(options ?? {}),
        })

    return {
        organisations: data as Organisation[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
