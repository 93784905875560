import moment from 'moment'
import { FC } from 'react'

import { DetailOrder } from 'entities/orders/model'
import { Flex } from 'shared/components/Flex'

import { StyledHeaderRegularTitle, StyledSecondaryText } from './styles'

interface InvoiceDetailsOverview {
    detailOrder: DetailOrder
}

export const InvoiceDetailsOverview: FC<InvoiceDetailsOverview> = ({
    detailOrder,
}) => (
    <Flex justifyContent='space-between'>
        <Flex flexDirection='column' maxWidth='50%'>
            <StyledSecondaryText>Аренда</StyledSecondaryText>
            {detailOrder.bookings?.map(booking => (
                <StyledHeaderRegularTitle key={booking.id}>
                    {booking?.area?.name}{' '}
                    {moment(booking.startTime).format('DD.MM.YYYY HH:mm')}-
                    {moment(booking.endTime).format('DD.MM.YYYY HH:mm')}
                </StyledHeaderRegularTitle>
            ))}
        </Flex>
        <Flex flexDirection='column'>
            <StyledSecondaryText>Клиент</StyledSecondaryText>
            <StyledHeaderRegularTitle>
                {detailOrder.counterpartyFullName}
            </StyledHeaderRegularTitle>
            <StyledHeaderRegularTitle>
                {detailOrder.counterpartyPhone}
            </StyledHeaderRegularTitle>
            <StyledHeaderRegularTitle>
                {detailOrder.counterpartyEmail}
            </StyledHeaderRegularTitle>
        </Flex>
    </Flex>
)
