import { adaptAdditionalDataDTO } from 'entities/counterparties/model/adapters/adaptAdditionalDataDTO'
import { CounterpartyDto } from '../dtoTypes'
import { Counterparty } from '../types'

export const adaptCounterpartyDTO = (dto: CounterpartyDto): Counterparty => ({
    id: dto.id,
    type: dto.type,
    phone: dto.phone,
    email: dto.email,
    profilePhoto: {
        hashName: dto?.profile_photo?.hash_name ?? null,
        readUrl: dto?.profile_photo?.read_url ?? null,
    },
    contact: {
        surname: dto?.contacts?.[0]?.surname ?? null,
        firstName: dto?.contacts?.[0]?.first_name ?? null,
        patronymic: dto?.contacts?.[0]?.patronymic ?? null,
        phone: dto?.contacts?.[0]?.phone ?? null,
        email: dto?.contacts?.[0]?.email ?? null,
    },
    discount: parseFloat(dto.discount),
    isBlackList: dto.is_black_list,
    comment: dto.comment,
    bookingHours: dto.booking_hours,
    additionalData: adaptAdditionalDataDTO(dto?.additional_data),
})
