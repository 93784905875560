export const parseTimeRange = (timeRanges: string) => {
    const ranges = timeRanges.split(',').map(range => range.trim());
    const parsedRanges = ranges.map(timeRange => {
        const [start, end] = timeRange.split(' - ').map(time => time.trim());
        const startTime = `${start}:00.000Z`;
        const endTime = `${end}:00.000Z`;

        return { start: startTime, end: endTime };
    });

    return parsedRanges;
};
