import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { GeneralData } from "../model/generalData";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { validateSchema } from "../model/validateSchema";
import { postAreas } from "../api/postAreas";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { AREASPROFILE } from "shared/consts/routingConsts";
import { parseTimeRange } from "features/Nomenclature/utils/parseTimeRange";
import { removeEmptyFields } from "shared/utils/removeEmptyFields";
import { ToggleSwitch } from "shared/components/ToggleSwitch";
import { UploadFile } from "entities/uploadFiles";

interface IPropsEmployeesCreate {
    setDisabled: (e: boolean) => void;
    setLoading: (e: boolean) => void;
    formikRef: React.Ref<FormikHelpers<any>>;
}

interface IPropsFile {
    id: number;
    name: string;
    file?: File;
}

export const AreasCreate = ({
    setDisabled,
    setLoading,
    formikRef
}:IPropsEmployeesCreate) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [files, setFiles] = useState<IPropsFile[]>([]);
    const [isBlocked, setIsBlocked] = useState(false);
    const [photo, ] = useState<File | null>(null);
    
    const { handleFileUpload } = useFileUploadUp();

    const onChangeToggleSwitch = () => {
        setIsBlocked(!isBlocked);
    }

    const handleFileChange = (newFiles: IPropsFile[]) => {
        setFiles(newFiles);
    };

    const handleSubmit = async (values: any, actions: any) => {
        setLoading(true);

        try {
            const { ad_price_info, ad_booking_info } = values;
            const morningHours = ad_price_info && ad_price_info.morning_hours ? parseTimeRange(ad_price_info.morning_hours) : null;
            const eveningHours = ad_price_info && ad_price_info.evening_hours ? parseTimeRange(ad_price_info.evening_hours) : null;
            const available_booking_time = ad_booking_info && ad_booking_info.available_booking_time ? parseTimeRange(ad_booking_info.available_booking_time) : null;
            const available_online_booking_time = ad_booking_info && ad_booking_info.available_online_booking_time ? parseTimeRange(ad_booking_info.available_online_booking_time) : null;
            const { uploadedFilesInfo } = await handleFileUpload(photo, files?.map(file => file.file!) || []);

            

            const filteredValues = removeEmptyFields({
                ...values,
                ad_booking_info: ad_booking_info ? {
                    ...ad_booking_info,
                    available_booking_time: available_booking_time ? available_booking_time : null,
                    available_online_booking_time: available_booking_time ? available_online_booking_time : null,
                } : null,
                ad_price_info: ad_price_info ? {
                    ...ad_price_info,
                    morning_hours: morningHours ? morningHours[0] : null,
                    evening_hours: eveningHours ? eveningHours[0] : null,
                } : null,
                photos_hash_names: uploadedFilesInfo && files
                ? uploadedFilesInfo.map((info) => info.hash_name)
                : [],
                is_locked: isBlocked,
            });

            const profileResponse = await postAreas({
                ...filteredValues
            });


            if (profileResponse.status !== 201) {
                dispatch(showNotification({
                    message: "Ошибка",
                    type: "error"
                }));
                return;
            }

            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));

            navigate(`${AREASPROFILE}/${profileResponse.data.id}`);
            actions.setSubmitting(false);
        } catch (err) {
            console.error(err);
            actions.setSubmitting(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            innerRef={formikRef as any}
            key="personalForm"
            initialValues={{}}
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
        >
            {({ isValid }) => {
                setDisabled(!isValid);
                return (
                    <Form>
                        <WrapperFormRow title="Помещение" formData={GeneralData}  />
                        <ToggleSwitch active={isBlocked} title="Функция блокирования" onChange={onChangeToggleSwitch} />
                        <div className="container">
                            <div className="row">
                                <UploadFile 
                                    displayPhoto={true}
                                    file={[]} 
                                    disabled={true} 
                                    onChangeFile={handleFileChange}
                                />
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}