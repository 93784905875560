const SettingThemeIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_3692_72838" fill="white">
            <path d="M19 12C20.6569 12 22.043 10.6276 21.5534 9.04476C21.2898 8.19291 20.9123 7.37586 20.4274 6.61681C19.4005 5.00915 17.9351 3.72876 16.2043 2.92673C14.4734 2.1247 12.5492 1.83449 10.6588 2.09035C8.76837 2.3462 6.99057 3.13745 5.53517 4.37072C4.07976 5.60399 3.00747 7.22783 2.44483 9.05063C1.88219 10.8734 1.85268 12.8191 2.35976 14.6582C2.86685 16.4972 3.88939 18.1528 5.30671 19.4297C5.97589 20.0325 6.7199 20.5391 7.51695 20.9388C8.99798 21.6816 10.5793 20.5395 10.8512 18.9051L11.5828 14.5076C11.8235 13.0607 13.0753 12 14.5421 12H19Z"/>
        </mask>
        <path d="M19 12C20.6569 12 22.043 10.6276 21.5534 9.04476C21.2898 8.19291 20.9123 7.37586 20.4274 6.61681C19.4005 5.00915 17.9351 3.72876 16.2043 2.92673C14.4734 2.1247 12.5492 1.83449 10.6588 2.09035C8.76837 2.3462 6.99057 3.13745 5.53517 4.37072C4.07976 5.60399 3.00747 7.22783 2.44483 9.05063C1.88219 10.8734 1.85268 12.8191 2.35976 14.6582C2.86685 16.4972 3.88939 18.1528 5.30671 19.4297C5.97589 20.0325 6.7199 20.5391 7.51695 20.9388C8.99798 21.6816 10.5793 20.5395 10.8512 18.9051L11.5828 14.5076C11.8235 13.0607 13.0753 12 14.5421 12H19Z" stroke="#282A42" stroke-width="2" mask="url(#path-1-inside-1_3692_72838)"/>
        <circle cx="15.5" cy="7.5" r="1.5" fill="#282A42"/>
        <circle cx="10.5" cy="6.5" r="1.5" fill="#282A42"/>
        <circle cx="6.5" cy="10.5" r="1.5" fill="#282A42"/>
        <circle cx="7.5" cy="15.5" r="1.5" fill="#282A42"/>
    </svg>
);

export default SettingThemeIcon;