export const NOMENCLATURELIST = "/nomenclature";
export const NOMENCLATUREPROFILE = `${NOMENCLATURELIST}/profile`;
export const NOMENCLATURECREATE = `${NOMENCLATURELIST}/create`;

export const EMPLOYEESLIST = "/employees";
export const EMPLOYEESPROFILE = `${EMPLOYEESLIST}/profile`;
export const EMPLOYEECREATE = `${EMPLOYEESLIST}/create/`;

export const CLIENTSLIST = "/clients";
export const CLIENTSPROFILE = `${CLIENTSLIST}/profile`;
export const CLIENTSCREATE = `${CLIENTSLIST}/create`;

export const AREASLIST = "/areas";
export const AREASPROFILE = `${AREASLIST}/profile`;
export const AREASCREATE = `${AREASLIST}/create`;

export const SETTING = "/setting";
export const SETTINGPROFILE = `${SETTING}/profile`;
export const SETTINGTHEME = `${SETTING}/theme`;
export const SETTINGNOTIFICATION = `${SETTING}/notification`;
export const SETTINGBOOKINGS = `${SETTING}/bookings`;
export const SETTINGROLE = `${SETTING}/role`;

export const SETTINGORG = `${SETTING}/org`;
export const SETTINGORGCREATE = `${SETTING}/org/create`;
export const SETTINGORGPROFILE = `${SETTING}/org/profile`;