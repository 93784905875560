export const removeEmptyFields = (obj: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (value !== '' && value !== null && value !== undefined) {
            if (typeof value === 'object' && !Array.isArray(value)) {
            const nestedObject = removeEmptyFields(value);
            if (Object.keys(nestedObject).length > 0) {
                newObj[key] = nestedObject;
            }
            } else {
            newObj[key] = value;
            }
        }
    });

    return newObj;
};