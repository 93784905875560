import { FC, PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'

import { closeModal } from 'shared/redux/slice/modalSlice'
import { useScrollbarWidth } from 'shared/hooks/useScrollbarWidth'

import {
    ModalClose,
    ModalContentTop,
    WrapperModal,
    WrapperModalContent,
} from './style'
import { Icon } from '../Icons'
import { Button } from '../Button'

interface IPropsBtnsModal {
    onClick: () => void
    title: string
    active: boolean
    loading?: boolean
    message?: string
}

interface IPropsModal {
    isOpen: boolean
    isFullScreen?: boolean
    className?: string
    contentClassName?: string
    childrenClassName?: string
    title: string
    btns?: IPropsBtnsModal[]
}

interface IPropsModalContent {
    className?: string
    childrenClassName?: string
    title: string
    isFullScreen?: boolean
}

export const ModalContent: FC<PropsWithChildren<IPropsModalContent>> = ({
    children,
    className,
    childrenClassName,
    title,
    isFullScreen,
}) => {
    const scrollbarWidth = useScrollbarWidth()
    const dispatch = useDispatch()

    useEffect(() => {
        const listenerHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                dispatch(closeModal())
            }
        }

        document.addEventListener('keydown', listenerHandler)
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = `${scrollbarWidth}px`

        return () => {
            document.removeEventListener('keydown', listenerHandler)
            document.body.style.removeProperty('overflow')
            document.body.style.paddingRight = '0px'
        }
    }, [scrollbarWidth])

    return (
        <WrapperModalContent className={className} isFullScreen={isFullScreen}>
            <ModalContentTop isFullScreen={isFullScreen}>
                <div className='modal-title'>{title}</div>
                <ModalClose onClick={() => dispatch(closeModal())}>
                    <Icon name='close' />
                </ModalClose>
            </ModalContentTop>
            <div
                className={childrenClassName}
                style={isFullScreen ? { height: '100%' } : undefined}
            >
                {children}
            </div>
        </WrapperModalContent>
    )
}

export const Modal: FC<PropsWithChildren<IPropsModal>> = ({
    isOpen = false,
    isFullScreen,
    className,
    contentClassName,
    childrenClassName,
    children,
    title,
    btns,
}) => {
    if (!isOpen) return null

    return createPortal(
        <WrapperModal className={className} isFullScreen={isFullScreen}>
            <ModalContent
                title={title}
                className={contentClassName}
                childrenClassName={childrenClassName}
                isFullScreen={isFullScreen}
            >
                <>
                    {children}
                    {!isFullScreen && (
                        <div className='modalcontent__btns'>
                            {btns &&
                                btns.map((item, index) => (
                                    <Button
                                        key={index}
                                        onClick={item.onClick}
                                        className={
                                            item.active
                                                ? ''
                                                : 'modalcontent__btns--outline'
                                        }
                                        loading={!!item.loading}
                                    >
                                        {item.title}
                                        <p>{item.message}</p>
                                    </Button>
                                ))}
                        </div>
                    )}
                </>
            </ModalContent>
        </WrapperModal>,
        document.body as HTMLElement,
    )
}
