import { axiosApi } from 'shared/api/axios'
import { OrderEndpoint } from './constants'
import { UpdateOrderRequest } from '../model'

export const updateOrder = async ({
    counterpartyId,
    orderId,
}: UpdateOrderRequest) => {
    try {
        await axiosApi.patch(`${OrderEndpoint.order}${orderId}`, {
            counterparty_id: counterpartyId,
        })
        return true
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
