import { useNavigate } from "react-router-dom";
import { WrapperSettingOrg } from "./style";
import { SETTINGORGCREATE } from "shared/consts/routingConsts";
import { Button } from "shared/components/Button";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "shared/redux/slice/modalSlice";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { useQueryClient } from "@tanstack/react-query";
import { axiosApi } from "shared/api/axios";
import { SettingOrg } from "features/Setting";

export const SettingOrgPages = () => {
    const queryClient = useQueryClient()
    
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleClick = (to: string) => {
        navigate(to);
    }

    const handleDelete = async (selectedRows: any[]) => {
        const arraySelected = selectedRows.map((item) => item.id);
        try {
            const response = await axiosApi.delete(`/api/v1/organizations/organization/`, {
                data: {
                    organization_ids: arraySelected
                }
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                setSelectedRows([]);
                await queryClient.refetchQueries({
                    queryKey: ['getSettingOrg'],
                    exact: false,
                });
                dispatch(closeModal());
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (error) {
            dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
        }
    }

    const hasSelectedRows = useMemo(() => {
        return selectedRows.length > 0;
    }, [selectedRows]);
    

    return (
        <WrapperSettingOrg>
            {!hasSelectedRows ? (
                <Button onClick={() => handleClick(SETTINGORGCREATE)}>+Добавить организацию</Button>
            ) : (
                <Button 
                    themeType="delete" 
                    onClick={() => dispatch(openModal({
                        isOpen: true,
                        content: (
                            <>
                                Вы уверены, что хотите удалить организацию? После нажатия на кнопку “Да”, она будет удалена.
                            </>
                        ),
                        config: {
                            title: "Удалить организацию?",
                            btns: [
                                {
                                    onClick: () => handleDelete(selectedRows),
                                    title: "Да",
                                    active: true,
                                },
                                {
                                    onClick: () => dispatch(closeModal()),
                                    title: "Нет",
                                    active: false
                                }
                            ]
                        }
                    }))}
                >Удалить организацию</Button>
            )}
            <SettingOrg
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
            />
        </WrapperSettingOrg>
    )
}