import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { GetAllPaymentsListResponseDto } from 'entities/payments/model/dtoTypes'
import { adaptPaymentDTO } from 'entities/payments/model/adapters/adaptPaymentDTO'

export const getAllPaymentsList = async () => {
    try {
        const response = await axiosApi.get<GetAllPaymentsListResponseDto>(
            PaymentEndpoint.GetPayments,
        )
        return response.data.map(adaptPaymentDTO)
    } catch (err) {
        console.error('Ошибка получения платежей', err)
        return null
    }
}
