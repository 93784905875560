import styled from 'styled-components'

export const StyledCalendarHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    position: relative;
    gap: 10px;
    &--start {
        justify-content: flex-start;
        gap: 30px;
    }
    &-title {
        color: #282a42;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .calendar-tabs-header {
        border-radius: 8px;
        overflow: hidden;
        background: #f4f3fa;
        display: flex;
        gap: 6px;
        left: 0;
        right: 0;
        width: fit-content;
        position: relative;
        margin: 0;
    }

    .calendar_right_button {
        display: flex;
        align-items: center;
        gap: 10px;
        button {
            height: 40px;
        }
    }
`
