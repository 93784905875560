import styled from "styled-components";

export const SettingWrapper = styled.div`
    display: flex;
    height: 100%;
    padding: 20px;
`;

export const SettingSidebarStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 283px;
    border-right: 1px solid #D8D8D8;
    padding-right: 20px;
    margin-right: 20px;
    gap: 20px;
    a {
        display: flex;
        padding: 10px 20px;
        gap: 10px;
        align-items: center;
        color: #282A42;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        text-wrap: nowrap;
        svg {
            width: 24px;
            height: 24px;
        }
        &.active {
            background-color: #EEF3F9;
            border-radius: 8px;
        }
    }
`;