import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers, Formik, Form } from 'formik'
import { useState, useEffect, useMemo, Ref } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFileUploadUp } from 'shared/hooks/useFileUploadUp'
import { getAreasSingle } from '../api/getAreasSingle'
import { patchAreas } from '../api/patchAreas'
import { WrapperFormRow } from 'shared/components/WrapperFormRow'
import { GeneralData } from '../model/generalData'
import { Loader } from 'shared/components/Loader'
import { getInitialValues } from '../model/initialValues'
import { parseTimeRange } from 'features/Nomenclature/utils/parseTimeRange'
import { ToggleSwitch } from 'shared/components/ToggleSwitch'
import { UploadFile } from 'entities/uploadFiles'
import { Avatar } from 'shared/components/Avatar'
import { StyledLine } from 'shared/styles/GlobalStyle'
import { validateSchema } from '../model/validateSchema'

interface IPropsFile {
    id: number
    name: string
    hash_name?: string
    read_url?: string
    file?: any
}

interface IPropsEmployeesProfile {
    isEditable: boolean
    formikRef: Ref<FormikHelpers<any>>
    setIsEditable: (e: boolean) => void
    setEditButtonText: (e: string) => void
    setDisabled: (e: boolean) => void
}

export const AreasProfile = ({
    isEditable,
    formikRef,
    setIsEditable,
    setEditButtonText,
    setDisabled,
}: IPropsEmployeesProfile) => {
    const { id } = useParams()

    const queryClient = useQueryClient()

    const [isBlocked, setIsBlocked] = useState(false)
    const [newFiles, setNewFiles] = useState<IPropsFile[]>([])
    const [removedFiles, setRemovedFiles] = useState<IPropsFile[]>([])

    const { handleFileUpload } = useFileUploadUp()

    const dispatch = useDispatch()

    const { isLoading, data, refetch } = useQuery({
        queryKey: ['getAreas', id],
        queryFn: async () => await getAreasSingle(id),
        enabled: !!id,
    })

    const photos = data?.photos ?? []
    const cachedPhotos = useMemo(() => photos, [photos])

    const onSubmit = async (values: any) => {
        const { uploadedFilesInfo } = await handleFileUpload(
            null,
            newFiles?.map(file => file.file!) || [],
        )

        const { ad_price_info, ad_booking_info } = values
        const morningHours =
            ad_price_info && ad_price_info.morning_hours
                ? parseTimeRange(ad_price_info.morning_hours)
                : null
        const eveningHours =
            ad_price_info && ad_price_info.evening_hours
                ? parseTimeRange(ad_price_info.evening_hours)
                : null
        const available_booking_time =
            ad_booking_info && ad_booking_info.available_booking_time
                ? parseTimeRange(ad_booking_info.available_booking_time)
                : null
        const available_online_booking_time =
            ad_booking_info && ad_booking_info.available_online_booking_time
                ? parseTimeRange(ad_booking_info.available_online_booking_time)
                : null

        const filteredValues = {
            ...values,
            ad_booking_info: ad_booking_info
                ? {
                      ...ad_booking_info,
                      available_booking_time: available_booking_time
                          ? available_booking_time
                          : null,
                      available_online_booking_time:
                          available_online_booking_time
                              ? available_online_booking_time
                              : null,
                      blocking_before_minutes:
                          ad_booking_info.blocking_before_minutes
                              ? ad_booking_info.blocking_before_minutes
                              : null,
                      blocking_after_minutes:
                          ad_booking_info.blocking_after_minutes
                              ? ad_booking_info.blocking_after_minutes
                              : null,
                      unavailable_refund_period_days:
                          ad_booking_info.unavailable_refund_period_days
                              ? ad_booking_info.unavailable_refund_period_days
                              : null,
                  }
                : null,
            ad_price_info: ad_price_info
                ? {
                      ...ad_price_info,
                      morning_hours: morningHours ? morningHours[0] : null,
                      evening_hours: eveningHours ? eveningHours[0] : null,
                      evening_price_per_hour:
                          ad_price_info.evening_price_per_hour
                              ? ad_price_info.evening_price_per_hour
                              : null,
                      more_than_three_hour_price_per_hour:
                          ad_price_info.more_than_three_hour_price_per_hour
                              ? ad_price_info.more_than_three_hour_price_per_hour
                              : null,
                      morning_price_per_hour:
                          ad_price_info.morning_price_per_hour
                              ? ad_price_info.morning_price_per_hour
                              : null,
                  }
                : null,
            prepayment: values.prepayment ? values.prepayment : 0,
            is_locked: isBlocked,
        }

        if (removedFiles.length > 0) {
            filteredValues.removed_document_photos_hash_names = removedFiles
                .map(file => file?.hash_name)
                .filter(
                    hash_name => hash_name !== null && hash_name !== undefined,
                )
            setRemovedFiles([])
        }

        if (newFiles.length > 0) {
            filteredValues.new_document_photos_hash_names =
                uploadedFilesInfo.map(file => file.hash_name)
            setNewFiles([])
        }

        patchAreas(
            filteredValues as any,
            refetch,
            setIsEditable,
            setEditButtonText,
            dispatch,
            id,
        ).then(() => {
            queryClient.invalidateQueries({
                queryKey: ['getAreasList'],
                refetchType: 'active',
            })
        })
    }

    const onChangeToggleSwitch = () => {
        setIsBlocked(!isBlocked)
    }

    useEffect(() => {
        setIsEditable(false)
        setEditButtonText('Редактировать')
    }, [id])

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {!isLoading && data && (
                <Formik
                    key={id}
                    innerRef={formikRef as any}
                    initialValues={getInitialValues(data)}
                    validationSchema={validateSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({ isValid }) => {
                        setDisabled(!isValid)
                        return (
                            <Form>
                                <Avatar
                                    photo={
                                        cachedPhotos.length > 0
                                            ? cachedPhotos[0].read_url || ''
                                            : ''
                                    }
                                    name={data?.name}
                                />
                                <StyledLine margin='20px 0' />
                                <WrapperFormRow
                                    title='Общие данные'
                                    formData={GeneralData.map(row =>
                                        row.map(item => ({
                                            ...item,
                                            disabled: !isEditable,
                                            placeholder: isEditable
                                                ? item.placeholder
                                                : '',
                                        })),
                                    )}
                                />
                                {isEditable && (
                                    <ToggleSwitch
                                        active={data?.is_locked}
                                        title='Функция блокирования'
                                        onChange={onChangeToggleSwitch}
                                    />
                                )}
                                <div className='container'>
                                    <div className='row'>
                                        <UploadFile
                                            displayPhoto={true}
                                            file={cachedPhotos}
                                            disabled={isEditable}
                                            onNewFilesChange={setNewFiles}
                                            onRemovedFilesChange={
                                                setRemovedFiles
                                            }
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )}
        </>
    )
}
