import { DetailBookingDTO } from 'entities/bookings/model/dtoTypes'
import { DetailBooking } from 'entities/bookings/model'

export const adaptDetailBookingDto = (
    source: DetailBookingDTO,
): DetailBooking => ({
    id: source.id,
    comment: source.comment,
    endTime: new Date(source.end_time),
    startTime: new Date(source.start_time),
    areaPrice: parseFloat(source.area_price),
    totalPrice: parseFloat(source.total_price),
    totalPriceWithDiscount: parseFloat(source.total_price_with_discount),
    areaPercentDiscount: parseFloat(source.area_percent_discount),
    areaAbsolutDiscount: parseFloat(source.area_absolut_discount),
    areaId: source.area.id,
    areaName: source.area.name,
    nomenclatures:
        source?.nomenclatures?.map(nomenclature => ({
            id: nomenclature.id,
            name: nomenclature.name,
            code: nomenclature.code,
            price: parseFloat(nomenclature.price),
            absolutDiscount: parseFloat(nomenclature.absolut_discount),
            count: nomenclature.count,
        })) ?? [],
    counterparty: {
        id: source.counterparty.id,
        fullName: source.counterparty.full_name,
        phone: parseInt(source.counterparty.phone),
        type: source.counterparty.type,
    },
    orderId: source.order_id,
})
