import { AdditionalDataDTO } from 'entities/counterparties/model/dtoTypes'
import { AdditionalData } from 'entities/counterparties/model'

export const adaptAdditionalDataDTO = (
    source?: AdditionalDataDTO,
): AdditionalData => ({
    documents: {
        serial: source?.documents?.serial ?? null,
        divisionCode: source?.documents?.division_code ?? null,
        issuedBy: source?.documents?.issued_by ?? null,
        issueDate: source?.documents?.issue_date ?? null,
        city: source?.documents?.city ?? null,
        street: source?.documents?.street ?? null,
        houseNumber: source?.documents?.house_number ?? null,
        apartmentNumber: source?.documents?.apartment_number ?? null,
        index: source?.documents?.index ?? null,

        organizationName: source?.documents?.organization_name ?? null,
        inn: source?.documents?.inn ?? null,
        bankName: source?.documents?.bank_name ?? null,
        bik: source?.documents?.bik ?? null,
        kpp: source?.documents?.kpp ?? null,
        checkingAccount: source?.documents?.checking_account ?? null,
        legalAddress: source?.documents?.legal_address ?? null,
        registrationDate: source?.documents?.registration_date ?? null,
        correspondentAccount: source?.documents?.correspondent_account ?? null,
    },
})
