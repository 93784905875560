import React, { FC } from 'react'
import { useDocumentFormController } from 'features/Booking/model/hooks'
import { LoaderPreview } from 'features/OrderPreview/ui'
import { Icon } from 'shared/components/Icons'
import { Flex } from 'shared/components/Flex'
import {
    StyledFileDownloadButton,
    StyledFileInput,
    StyledFileInputText,
    StyledFilePreview,
    StyledFilePreviewText,
    StyledFileRemoveButton,
} from './styles'

interface DocumentsFormProps {
    isPreview: boolean
}

export const DocumentsForm: FC<DocumentsFormProps> = ({ isPreview }) => {
    const {
        isLoading,
        fileInputRef,
        documents,
        onFileInputClick,
        onChange,
        onFileDropClick,
        onOverDnD,
        removeFile,
        downloadFile,
    } = useDocumentFormController()

    return (
        <div className='content'>
            {!isPreview && !isLoading && (
                <StyledFileInput
                    onClick={onFileInputClick}
                    onDragOver={onOverDnD}
                    onDragEnter={onOverDnD}
                    onDrop={onFileDropClick}
                >
                    <StyledFileInputText>
                        Загрузить документ
                    </StyledFileInputText>{' '}
                    <Icon name='download' />
                </StyledFileInput>
            )}
            <input
                ref={fileInputRef}
                onChange={onChange}
                type='file'
                multiple
                style={{ display: 'none' }}
                disabled={isPreview || isLoading}
            />
            {documents.length > 0 && (
                <Flex flexDirection='column' gap={5}>
                    {documents.map(document => (
                        <Flex gap={5} alignItems='end' key={document.id}>
                            <StyledFilePreview isPreview={isPreview}>
                                <StyledFilePreviewText>
                                    {document.fileName}
                                </StyledFilePreviewText>
                            </StyledFilePreview>
                            <StyledFileDownloadButton
                                onClick={() =>
                                    downloadFile(
                                        document.uploadUrl,
                                        document.fileName,
                                    )
                                }
                                disabled={isLoading}
                            >
                                <Icon name='download' />
                            </StyledFileDownloadButton>
                            {!isPreview && (
                                <StyledFileRemoveButton
                                    onClick={() => removeFile(document.id)}
                                    disabled={isLoading}
                                >
                                    <Icon name='delete' />
                                </StyledFileRemoveButton>
                            )}
                        </Flex>
                    ))}
                </Flex>
            )}
            {isLoading && (
                <Flex padding={10}>
                    <LoaderPreview />
                </Flex>
            )}
        </div>
    )
}
