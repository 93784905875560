import { DashboardLayout } from "app/layout";
import { NomenclatureProfile } from "features/Nomenclature/ui/NomenclatureProfile";
import { FormikHelpers } from "formik";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { axiosApi } from "shared/api/axios";
import { BackButton } from "shared/components/BackButton";
import { Button } from "shared/components/Button";
import { NOMENCLATURELIST } from "shared/consts/routingConsts";
import { closeModal, openModal } from "shared/redux/slice/modalSlice";
import { StyledItem } from "./style";
import { Input } from "shared/components/Input";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { useQueryClient } from "@tanstack/react-query"

export const NomenclatureProfilePages = () => {
    const {id} = useParams();
    
    const queryClient = useQueryClient()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    const [type, setType] = useState<number | null>();
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const cancelEdit = async () => {
        setIsEditable(false);
        setEditButtonText("Редактировать");
        formikRef.current?.resetForm();
        dispatch(closeModal());
    }

    const handleDeleteProfile = () => {
        axiosApi.delete(`/api/v1/nomenclatures/nomenclature/`, {
            data: [id]
        });
        dispatch(closeModal());
        navigate(NOMENCLATURELIST)
    }

    const handleGoBack = () => {
        navigate(NOMENCLATURELIST);
    }

    const handleAddCountProduct = async (count: number, newCount: number) => {
        try {
            const response = await axiosApi.patch(`/api/v1/nomenclatures/nomenclature/${id}`, {
                "count": newCount + count 
            });
            if (response.status === 200) {
                await queryClient.invalidateQueries(
                    {
                        queryKey: ["getNomenclatureSingle"],
                        refetchType: 'active',
                    },
                );
                dispatch(closeModal())
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={handleGoBack}
                    />
                    <div className="dashboard__header-title">{type === 0 ? "Оборудование" : "Расходные материалы"}</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                {isEditable ? (
                        <>
                            <Button themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit} disabled={disabled}>{editButtonText}</Button>
                            <Button themeType={"outline-2"} onClick={cancelEdit}>Отменить</Button>
                        </>
                    ) : (
                        <>
                            <Button 
                                onClick={() => {
                                let newCount = "";
                                dispatch(openModal({
                                isOpen: true,
                                content: (
                                    <div className="blockWithComment">
                                        <Input label="Сколько товара добавить?" type="number" placeholder="Введите кол-во"  onChange={(e) => newCount = (e.target as HTMLInputElement).value}/>
                                    </div>
                                ),
                                config: {
                                    title: "Добавить товар",
                                    btns: [
                                        {
                                            onClick: () => handleAddCountProduct(count, Number(newCount)),
                                            title: "Добавить",
                                            active: true
                                        },
                                        {
                                            onClick: () => dispatch(closeModal()),
                                            title: "Отменить",
                                            active: false
                                        }
                                    ]
                                }
                            }))}}
                            >Дополнить товар</Button>
                            <Button themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                            <Button 
                                themeType="delete" 
                                onClick={() => dispatch(openModal({
                                    isOpen: true,
                                    content: (
                                        <>
                                            Вы точно хотите удалить оборудование?
                                        </>
                                    ),
                                    config: {
                                        title: "Вы точно хотите удалить?",
                                        btns: [
                                            {
                                                onClick: () => handleDeleteProfile(),
                                                title: "Да",
                                                active: true
                                            },
                                            {
                                                onClick: () => dispatch(closeModal()),
                                                title: "Нет",
                                                active: false
                                            }
                                        ]
                                    }
                                }))}
                            >
                                Удалить
                            </Button>

                        </>
                    )
                }
                </div>
            </div>
        }>
            <NomenclatureProfile
                setDisabled={setDisabled}
                setType={setType}
                isEditable={isEditable}
                formikRef={formikRef}
                setIsEditable={setIsEditable}
                setEditButtonText={setEditButtonText}
                setCount={setCount}
            />
        </DashboardLayout>
    )
}