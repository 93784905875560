import { motion } from "framer-motion";
import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const TabHeaders = styled.div`
  display: flex;
  position: relative;
`;

export const TabHeader = styled.div<{ isActive: boolean }>`
  display: flex;
  color: #282A42;
  color: ${(props) => (props.isActive ? "#282A42" : "#8181A5")};;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  cursor: pointer;
  justify-content: center;
  width: 100%;
`;

export const TabContent = styled.div`
`;

export const Underline = styled(motion.div)`
  position: absolute;
  bottom: -5px;
  height: 1px;
  background: ${({ theme }) => theme.themeColors.main};
`;