import { PaymentAccountDTO } from 'entities/paymentAccount/model/dtoTypes'
import { PaymentAccount } from 'entities/paymentAccount/model/types'

export const adaptPaymentAccountDTO = (
    source: PaymentAccountDTO,
): PaymentAccount => ({
    id: source.id,
    name: source.name,
    bik: source.bik,
    number: source.number,
    correspondentNumber: source.correspondent_number,
    bankName: source.bank_name,
    organizationId: source.organization_id,
})
