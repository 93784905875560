import { useMediaQuery as useMediaQueryLib } from '@uidotdev/usehooks'

export const useMediaQuery = () => {
    const isSmallDevice = useMediaQueryLib(
        'only screen and (max-width : 768px)',
    )
    const isMediumDevice = useMediaQueryLib(
        'only screen and (min-width : 769px) and (max-width : 992px)',
    )
    const isLargeDevice = useMediaQueryLib(
        'only screen and (min-width : 993px) and (max-width : 1200px)',
    )
    const isExtraLargeDevice = useMediaQueryLib(
        'only screen and (min-width : 1201px)',
    )

    return {
        isSmallDevice,
        isMediumDevice,
        isLargeDevice,
        isExtraLargeDevice,
    }
}
