export const ProfileFormData = [
    [
        {
            name: "user_ad_personal_info.first_name",
            label: "Имя", 
            placeholder: "Иван", 
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            disabled: true,
            required: true,
        },
        { 
            name: "user_ad_personal_info.surname", 
            label: "Фамилия", 
            placeholder: "Фамилия", 
            type: "text",
            disabled: true,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            required: true,
        },
        { 
            name: "user_ad_personal_info.patronymic", 
            label: "Отчество", 
            placeholder: "Отчество", 
            type: "text",
            disabled: true,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
        },
    ],
    [
        { 
            name: "phone", 
            label: "Телефон", 
            placeholder: "+7-999-999-9999", 
            maskConfig: { mask: '+{7}-000-000-00-00' }, 
            disabled: true, 
            type: "text",
            required: true
        },
        { 
            name: "email", 
            label: "Почта", 
            type: "email",
            disabled: true,
            placeholder: "",
            required: true
        },
        { 
            name: "password", 
            label: "Пароль", 
            type: "email",
            disabled: true,
            placeholder: "",
            required: true
        },
    ]
];