import { axiosApi } from "shared/api/axios";

export const getOrderClients = async (id?: string): Promise<any> => {
    try {
        const url = id 
            ? `/api/v1/bookings/order/?counterparty_filter=${id}`
            : `/api/v1/bookings/order/?limit=3`;
        
        const response = await axiosApi.get<any>(url);
        return response.data;
    } catch(err) {
        console.error(err);
        throw new Error("Ошибка получения заказа");
    }
}