import { AheadBookingDTO } from 'entities/bookings/model/dtoTypes'
import { AheadBooking } from 'entities/bookings/model'

export const adaptAheadBookingDTO = (dto: AheadBookingDTO): AheadBooking => ({
    id: dto.id,
    startTime: dto?.start_time ? new Date() : null,
    endTime: dto?.start_time ? new Date(dto?.start_time) : null,
    counterpartyName: dto?.counterparty_name ?? null,
    counterpartyPhone: dto?.counterparty_name ?? null,
    areaName: dto?.area_name ?? null,
    status: dto?.status ?? null,
    totalPrice: dto?.total_price ?? null,
    comment: dto?.comment ?? null,
})