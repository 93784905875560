import { axiosApi } from 'shared/api/axios'
import { PaymentAccountsEndpoint } from 'entities/paymentAccount/api/constants'
import { GetPaymentAccountResponseDTO } from 'entities/paymentAccount/model/dtoTypes'
import { adaptPaymentAccountDTO } from 'entities/paymentAccount/model/adapters/adaptPaymentAccountDTO'

export const getPaymentAccounts = async (organization_id: number) => {
    try {
        const response = await axiosApi.get<GetPaymentAccountResponseDTO>(
            PaymentAccountsEndpoint.paymentAccount,
            {
                params: {
                    organization_id,
                },
            },
        )

        return response.data.payment_accounts.map(adaptPaymentAccountDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получения организаций')
    }
}
