import { axiosApi } from 'shared/api/axios'
import { BookingEndpoint } from 'entities/bookings/api/constants'
import { GetAheadBookingsDTO } from 'entities/bookings/model/dtoTypes'
import { adaptAheadBookingDTO } from 'entities/bookings/model/adapters/adaptAheadBookingDTO'

export const getBookingAhead = async () => {
    try {
        const response = await axiosApi.get<GetAheadBookingsDTO>(
            BookingEndpoint.ahead,
        )
        return response.data.ahead_bookings.map(adaptAheadBookingDTO)
    } catch (err) {
        console.error('Ошибка получения бронирований', err)
        return null
    }
}
