import { AdPriceInfoDTO } from '../dtoTypes'
import { AdPriceInfo } from '../types'
import { adaptTimePeriodDTO } from './adaptTimePeriodDTO'

export const adaptAdPriceInfoDTO = (infoDTO: AdPriceInfoDTO): AdPriceInfo => {
    return {
        moreThanThreeHourPricePerHour:
            infoDTO?.more_than_three_hour_price_per_hour ?? null,
        morningPricePerHour: infoDTO?.morning_price_per_hour ?? null,
        eveningPricePerHour: infoDTO?.evening_price_per_hour ?? null,
        morningHours: infoDTO?.morning_hours
            ? adaptTimePeriodDTO(infoDTO?.morning_hours)
            : null,
        eveningHours: infoDTO?.evening_hours
            ? adaptTimePeriodDTO(infoDTO?.evening_hours)
            : null,
    }
}
