import { useThemeContext } from "app/providers/providers";

const FourBlockIcon = () => {
    const { theme } = useThemeContext();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M4.12451 6.42639C4.4043 5.25224 5.32505 4.33792 6.50112 4.06636L6.55598 4.0537C7.58841 3.81531 8.66159 3.81531 9.69402 4.0537L9.74888 4.06636C10.925 4.33792 11.8457 5.25225 12.1255 6.42639V6.42639C12.3717 7.4597 12.3717 8.5364 12.1255 9.5697V9.5697C11.8457 10.7439 10.925 11.6582 9.74888 11.9297L9.69402 11.9424C8.66159 12.1808 7.58841 12.1808 6.55598 11.9424L6.50112 11.9297C5.32505 11.6582 4.4043 10.7439 4.12451 9.56971V9.56971C3.87828 8.5364 3.87829 7.4597 4.12451 6.42639V6.42639Z" stroke={theme.themeColors.main}/>
            <path d="M4.12451 19.9615C4.4043 18.7874 5.32505 17.8731 6.50112 17.6015L6.55598 17.5889C7.58841 17.3505 8.66159 17.3505 9.69402 17.5889L9.74888 17.6015C10.925 17.8731 11.8457 18.7874 12.1255 19.9615V19.9615C12.3717 20.9949 12.3717 22.0716 12.1255 23.1049V23.1049C11.8457 24.279 10.925 25.1933 9.74888 25.4649L9.69402 25.4776C8.66159 25.7159 7.58841 25.7159 6.55598 25.4776L6.50112 25.4649C5.32505 25.1933 4.4043 24.279 4.12451 23.1049V23.1049C3.87829 22.0716 3.87829 20.9949 4.12451 19.9615V19.9615Z" stroke={theme.themeColors.main}/>
            <path d="M17.8745 6.42639C18.1543 5.25224 19.075 4.33792 20.2511 4.06636L20.306 4.0537C21.3384 3.81531 22.4116 3.81531 23.444 4.0537L23.4989 4.06636C24.675 4.33792 25.5957 5.25225 25.8755 6.42639V6.42639C26.1217 7.4597 26.1217 8.5364 25.8755 9.5697V9.5697C25.5957 10.7439 24.675 11.6582 23.4989 11.9297L23.444 11.9424C22.4116 12.1808 21.3384 12.1808 20.306 11.9424L20.2511 11.9297C19.075 11.6582 18.1543 10.7439 17.8745 9.56971V9.56971C17.6283 8.5364 17.6283 7.4597 17.8745 6.42639V6.42639Z" stroke={theme.themeColors.main}/>
            <path d="M17.8745 19.9615C18.1543 18.7874 19.075 17.8731 20.2511 17.6015L20.306 17.5889C21.3384 17.3505 22.4116 17.3505 23.444 17.5889L23.4989 17.6015C24.675 17.8731 25.5957 18.7874 25.8755 19.9615V19.9615C26.1217 20.9949 26.1217 22.0716 25.8755 23.1049V23.1049C25.5957 24.279 24.675 25.1933 23.4989 25.4649L23.444 25.4776C22.4116 25.7159 21.3384 25.7159 20.306 25.4776L20.2511 25.4649C19.075 25.1933 18.1543 24.279 17.8745 23.1049V23.1049C17.6283 22.0716 17.6283 20.9949 17.8745 19.9615V19.9615Z" stroke={theme.themeColors.main}/>
        </svg>
    )
};

export default FourBlockIcon;