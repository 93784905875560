import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { BookingsApi } from '../../api'
import { QueryKeys } from './constants'
import { AheadBooking } from '../types'

export const useGetAheadBookingsQuery = (options?: UseQueryOptions) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.Ahead],
            queryFn: () => BookingsApi.getBookingAhead(),
            ...(options ?? {}),
        })

    return {
        aheadBookings: data as AheadBooking[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
