import { axiosApi } from 'shared/api/axios'
import { BookingEndpoint } from './constants'
import { DeleteNomenclaturesRequest } from '../model'

export const deleteNomenclaturesFromBooking = async ({
    bookingNomenclatureId,
    orderId,
    bookingId,
}: DeleteNomenclaturesRequest) => {
    try {
        await axiosApi.delete(
            `${BookingEndpoint.nomenclature}${bookingNomenclatureId}`,
            {
                params: {
                    order_id: orderId,
                    booking_id: bookingId,
                },
            },
        )
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
