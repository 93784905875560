export const formatNumber = (value: number | string): string => {
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
        return '';
    }

    return Math.floor(numericValue).toLocaleString('ru-RU');
}

