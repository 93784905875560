import { OrderDTO } from '../dtoTypes'
import { Order } from '../types'

export const adaptOrderDTO = (orderDTO: OrderDTO): Order => ({
    id: orderDTO.id,
    status: orderDTO.status,
    totalPrice: parseFloat(orderDTO.total_price),
    totalPriceWithDiscount: parseFloat(orderDTO.total_price_with_discount),
    totalAmountPaid: parseFloat(orderDTO.total_amount_paid),
    counterpartyId: orderDTO.counterparty.id,
})
