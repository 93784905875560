import { DashboardLayout } from 'app/layout'
import { ReactBigCalendar } from 'features/ReactBigCalendar'
import {
    useCalendarController,
    useCalendarScrollController,
    ViewMode,
} from 'features/BookingCalendarController/model'
import { Button } from 'shared/components/Button'
import { TabLink } from 'shared/components/TabLink'
import { linksCalendar } from 'shared/consts/linksCalendar'
import { PeriodSelector } from 'shared/components/PeriodSelector'
import { views } from './constants'
import { Icon } from 'shared/components/Icons'
import { PeriodSelectorWrapper } from 'shared/components/PeriodSelector/style'
import { StyledCalendarHeaderContainer } from 'pages/calendarDay/style'

export const CalendarDayPage = () => {
    const {
        currentDate,
        areasResources,
        bookingEvents,

        onSelectSlot,
        onDropSlot,
        onClickEvent,
        onEventResize,

        handleDateChange,
        onNewBookingClick,
    } = useCalendarController({ view: ViewMode.Day })
    const { needsScroll, scrollRight, scrollLeft } =
        useCalendarScrollController({ depsForUpdate: [areasResources] })

    return (
        <DashboardLayout
            header={
                <StyledCalendarHeaderContainer>
                    <TabLink
                        className='calendar-tabs-header'
                        list={linksCalendar}
                    />
                    <PeriodSelector period='day' />
                    <div className='dashboard__right-menu'>
                        {needsScroll && (
                            <PeriodSelectorWrapper>
                                <div
                                    className='arrow arrow--left'
                                    onClick={scrollLeft}
                                >
                                    <Icon name='arrowRight' />
                                </div>
                                <div
                                    className='arrow arrow--right'
                                    onClick={scrollRight}
                                >
                                    <Icon name='arrowRight' />
                                </div>
                            </PeriodSelectorWrapper>
                        )}

                        <Button onClick={onNewBookingClick}>
                            + Новая бронь
                        </Button>
                    </div>
                </StyledCalendarHeaderContainer>
            }
        >
            <ReactBigCalendar
                views={views}
                defaultView='day'
                currentDate={currentDate}
                onDateChange={handleDateChange}
                events={bookingEvents}
                resources={areasResources}
                onSelectSlot={onSelectSlot}
                onDropSlot={onDropSlot}
                onEventResize={onEventResize}
                onClickEvent={onClickEvent}
            />
        </DashboardLayout>
    )
}
