import { FC } from "react";
import { Icon } from "../Icons";
import { WrapperBackButton, BackButtonIcon, BackButtonText } from "./style";
import { useNavigate } from "react-router-dom";


interface IPropsBackButton {
    iconColor?: string;
    reverseOrder?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    text: string;
    goBack?: boolean;
}

export const BackButton: FC<IPropsBackButton> = ({
    iconColor, 
    reverseOrder = false, 
    onClick, 
    disabled = false, 
    text,
    goBack = true
}) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if (disabled) return;
        
        if (goBack) {
            navigate(-1);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <WrapperBackButton onClick={handleClick} disabled={disabled} reverseorder={reverseOrder}>
            <BackButtonIcon iconColor={iconColor}>
                <Icon name="arrowRight" />
            </BackButtonIcon>
            <BackButtonText>{text}</BackButtonText>
        </WrapperBackButton>
    );
};