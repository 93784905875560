import { axiosApi } from 'shared/api/axios'
import { OrderEndpoint } from './constants'
import { DeleteOrderRequest } from '../model'

export const deleteOrder = async ({ orderId }: DeleteOrderRequest) => {
    try {
        await axiosApi.delete(`${OrderEndpoint.order}${orderId}`)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
