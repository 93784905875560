import { BookingDTO } from '../dtoTypes'
import { Booking } from '../types'

export const adaptBookingDTO = (bookingDTO: BookingDTO): Booking => ({
    id: bookingDTO.id,
    comment: bookingDTO.comment,
    endTime: new Date(bookingDTO.end_time),
    startTime: new Date(bookingDTO.start_time),
    areaPrice: bookingDTO.area_price,
    nomenclaturesPrice: bookingDTO.nomenclatures_price,
    orderId: bookingDTO.order_id,
    area: {
        id: bookingDTO?.area?.id ?? null,
        name: bookingDTO?.area?.name ?? null,
    },
    counterparty: {
        id: bookingDTO.counterparty.id,
        fullName: bookingDTO.counterparty.full_name,
        phone: bookingDTO.counterparty.phone,
        type: bookingDTO.counterparty.type,
    },
})
