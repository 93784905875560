const PluseIcon = () => (
    <svg
        width='8'
        height='8'
        viewBox='0 0 8 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M3.44318 7.28613V0.723633H4.55682V7.28613H3.44318ZM0.71875 4.5617V3.44806H7.28125V4.5617H0.71875Z'
            fill='white'
        />
    </svg>
)

export default PluseIcon
