export const PlaceData = [
    [
        { 
            name: "user_ad_personal_info.legal_entity_data.city", 
            label: "Город", 
            placeholder: "Москва", 
            type: "text",
            disabled: true,
        },
        { 
            name: "user_ad_personal_info.legal_entity_data.street", 
            label: "Улица", 
            placeholder: "Пушкина", 
            type: "text",
            disabled: true,
        },
        { 
            name: "user_ad_personal_info.legal_entity_data.house", 
            label: "Дом", 
            placeholder: "10", 
            type: "text",
            disabled: true,
        },
        { 
            name: "user_ad_personal_info.legal_entity_data.appartment_number", 
            label: "Квартира", 
            placeholder: "95",
            type: "text",
            maskConfig: {
                mask: Number
            },
            disabled: true,
        },
        { 
            name: "user_ad_personal_info.legal_entity_data.index", 
            label: "Индекс", 
            placeholder: "440231", 
            type: "text",
            maskConfig: {
                mask: Number
            },
            disabled: true,
        },
    ],
];