import styled from 'styled-components'

export const PeriodSelectorWrapper = styled.div`
    display: flex;
    gap: 18px;
    align-items: center;

    .arrow {
        border-radius: 8px;
        border: 0.848px solid #e1e4ea;
        background: #fff;
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &--right {
            transform: rotate(180deg);
        }
    }
    span {
        color: #282a42;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }
`
