import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "features/auth/services/AuthServices";

interface IPropsInitialState {
  isAuth: boolean | null;
  isAuthInProgress: boolean;
  error: any;
}

const initialState: IPropsInitialState = {
  isAuth: null,
  isAuthInProgress: false,
  error: null,
};

export const login = createAsyncThunk('/api/oauth/v1/auth/login', async ({ login, password }: any, { rejectWithValue }) => {
  try {
    const response = await AuthService.login(login, password);
    return response.data;
  } catch (err) {
      return rejectWithValue('Ошибка входа!');
  }
});

export const checkAuth = createAsyncThunk('/api/oauth/v1/user/info', async (_, { rejectWithValue }) => {
  try {
    const response = await AuthService.auth();
    return response.data;
  } catch (err) {
    return rejectWithValue('Ошибка авторизации');
  }
});

export const logout = createAsyncThunk('/api/oauth/v1/auth/logout', async (_, { rejectWithValue }) => {
  try {
      await AuthService.logout();
      return {};
  } catch (err) {
      return rejectWithValue('Ошибка выхода');
  }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(login.pending, (state) => {
            state.isAuthInProgress = true;
            state.error = null;
          })
          .addCase(login.fulfilled, (state, action) => {
            state.isAuthInProgress = false;
            state.isAuth = true;
            localStorage.setItem('token', action.payload.tokens.access);
            localStorage.setItem('refresh', action.payload.tokens.refresh);
          })
          .addCase(login.rejected, (state, action) => {
            state.isAuthInProgress = false;
            state.error = action.payload;
          })

          .addCase(checkAuth.pending, (state) => {
            state.isAuthInProgress = true;
            state.isAuth = false;
            state.error = null;
          })
          .addCase(checkAuth.fulfilled, (state) => {
            state.isAuthInProgress = false;
            state.isAuth = true;
            // localStorage.setItem('token', action.payload.accessToken);
          })
          .addCase(checkAuth.rejected, (state, action) => {
            state.isAuthInProgress = false;
            state.isAuth = false;
            state.error = action.payload;
          })

          .addCase(logout.pending, (state) => {
            state.isAuthInProgress = true;
            state.error = null;
          })
          .addCase(logout.fulfilled, (state) => {
            state.isAuthInProgress = false;
            state.isAuth = false;
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
          })
          .addCase(logout.rejected, (state, action) => {
            state.isAuthInProgress = false;
            state.error = action.payload;
          });
      },
});

export default authSlice.reducer;