import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Icon } from 'shared/components/Icons'
import { useAppDispatch } from 'shared/redux/hooks'
import { logout } from 'features/auth/model/authSlice'
import { Box, StyledItem } from './style'
import { sidebarMenuData } from 'shared/consts/sidebarMenuData'

export const LeftSidebar: React.FC = () => {
    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(logout())
    }

    return (
        <Box>
            <div>
                <Link to='/'>
                    <Icon name='logo' />
                </Link>
                {sidebarMenuData.map((item, index) => (
                    <StyledItem key={index}>
                        <NavLink
                            to={item.link}
                            className={({ isActive }) =>
                                isActive ? 'active' : undefined
                            }
                        >
                            <Icon name={item.icon} />
                        </NavLink>
                    </StyledItem>
                ))}
            </div>
            <StyledItem>
                <Link to='/auth/sign-in' onClick={() => handleClick()}>
                    <Icon name='exit' />
                </Link>
            </StyledItem>
        </Box>
    )
}
