import React from 'react'

const deleteRoundedIcon = () => (
    <svg
        width='7'
        height='7'
        viewBox='0 0 7 7'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M6.37399 5.771C6.41393 5.81081 6.44562 5.85812 6.46725 5.91021C6.48887 5.9623 6.5 6.01815 6.5 6.07455C6.5 6.13096 6.48887 6.1868 6.46725 6.23889C6.44562 6.29099 6.41393 6.33829 6.37399 6.37811C6.29315 6.45793 6.18412 6.50269 6.07053 6.50269C5.95693 6.50269 5.84791 6.45793 5.76706 6.37811L3.5 4.11037L1.23294 6.37811C1.15209 6.45793 1.04307 6.50269 0.929473 6.50269C0.815878 6.50269 0.706854 6.45793 0.626008 6.37811C0.586068 6.33829 0.554377 6.29099 0.532754 6.23889C0.511131 6.1868 0.5 6.13096 0.5 6.07455C0.5 6.01815 0.511131 5.9623 0.532754 5.91021C0.554377 5.85812 0.586068 5.81081 0.626008 5.771L2.89307 3.50326L0.626008 1.23553C0.545524 1.15502 0.500309 1.04583 0.500309 0.931977C0.500309 0.875602 0.511409 0.819778 0.532977 0.767694C0.554545 0.71561 0.586157 0.668286 0.626008 0.628422C0.66586 0.588559 0.71317 0.556937 0.765239 0.535363C0.817308 0.51379 0.873114 0.502686 0.929473 0.502686C1.04329 0.502686 1.15245 0.547914 1.23294 0.628422L3.5 2.89616L5.76706 0.628422C5.84755 0.547914 5.95671 0.502686 6.07053 0.502686C6.18435 0.502686 6.29351 0.547914 6.37399 0.628422C6.45448 0.70893 6.49969 0.818122 6.49969 0.931977C6.49969 1.04583 6.45448 1.15502 6.37399 1.23553L4.10693 3.50326L6.37399 5.771Z'
            fill='white'
        />
    </svg>
)

export default deleteRoundedIcon
