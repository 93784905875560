import { Form, Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SETTINGORGPROFILE } from "shared/consts/routingConsts";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { postOrgs } from "../api/postOrgs";
import { validateSchema } from "../model/validateSchema";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { GeneralData } from "../model/formData";

interface IPropsSettingOrgCreate {
    setDisabled: (e: boolean) => void;
    setLoading: (e: boolean) => void;
    formikRef: React.Ref<FormikHelpers<any>>;
}

export const SettingOrgCreate = ({
    setDisabled,
    setLoading,
    formikRef
}:IPropsSettingOrgCreate) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleSubmit = async (values: any, actions: any) => {
        setLoading(true);
        try {

            const profileResponse = await postOrgs({
                ...values
            })

            if (profileResponse.status !== 201) {
                dispatch(showNotification({
                    message: "Ошибка",
                    type: "error"
                }));
                return;
            }

            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));

            navigate(`${SETTINGORGPROFILE}/${profileResponse.data.id}`);
            actions.setSubmitting(false);
        } catch (err) {
            console.error(err);
            actions.setSubmitting(false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Formik
            innerRef={formikRef as any}
            key="personalForm"
            initialValues={{
                name: "",
                inn: "",
                kpp: "",
                ceo: "",
                legal_address: ""
            }}
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
        >
            {({ isValid, dirty, errors }) => {
                console.log(errors);
                setDisabled(!(isValid && dirty));
                return (
                    <Form>
                        <WrapperFormRow title="Общие данные" formData={GeneralData}  />
                    </Form>
                )
            }}
        </Formik>
    )
}