import styled from 'styled-components'

export const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
`

export const StyledLoaderContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const BoldText = styled.div`
    color: #282a42;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`

export const BookingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
`
