import moment from 'moment'
import { useParams } from 'react-router-dom'
import React from 'react'

import { useGetPaymentsQuery } from 'entities/payments/model'

import { useGetDetailOrderQuery } from 'entities/orders/model'
import { Line } from 'shared/components/Line'
import { Flex } from 'shared/components/Flex'

import {
    InvoiceDetailsTable,
    InvoiceHeader,
    InvoiceSubHeader,
    InvoiceDetailsOverview,
    InvoicePaymentsTable,
} from './components'
import { StyledContainer, StyledHeaderTitle } from './components/styles'
import { OrderPaidStatus } from 'features/OrderPreview/ui/components/OrderPaidStatus'
import { useMediaQuery } from 'shared/hooks/useMediaQuery'
import { OrderTotalPricePreview, PaymentsPreview } from 'features/OrderPreview/ui'
import { LoaderPreview } from 'features/OrderPreview/ui'

export const InvoicePage = () => {
    const { id } = useParams()
    const orderId = Number(id)
    const { detailOrder, isFetching } = useGetDetailOrderQuery(orderId)
    const { isSmallDevice, isMediumDevice } = useMediaQuery()

    const { payments, isFetching: isPaymentFetching } = useGetPaymentsQuery()
    const currentPayments = payments?.filter(p => p.orderId === orderId) ?? []

    if (isFetching || isPaymentFetching) {
        return <LoaderPreview />
    }

    if (!id || !detailOrder) {
        return <div>Заказ не найден</div>
    }

    const createdAt = moment(detailOrder.createdAt).format('DD.MM.YYYY HH:MM')

    return (
        <Flex justifyContent='center' minHeight='100vh'>
            <StyledContainer>
                <InvoiceHeader id={id} createdAt={createdAt} />
                <InvoiceSubHeader />
                <Line />

                <InvoiceDetailsOverview detailOrder={detailOrder} />
                <Flex flexDirection='column' gap={10} flex={1}>
                    <StyledHeaderTitle>Смета</StyledHeaderTitle>
                    {isSmallDevice || isMediumDevice ? (
                        <OrderTotalPricePreview detailOrder={detailOrder} hideStatus />
                    ) : (
                        <InvoiceDetailsTable bookings={detailOrder.bookings} />
                    )}
                </Flex>
                <Flex flexDirection='column' gap={10}>
                    <StyledHeaderTitle>История транзакций</StyledHeaderTitle>
                    {isSmallDevice || isMediumDevice ? (
                        <PaymentsPreview payments={currentPayments} allowPayments isOpenByDefault />
                    ) : (
                        <InvoicePaymentsTable
                            payments={currentPayments ?? []}
                        />
                    )}
                </Flex>
                <Flex justifyContent='end' gap={10}>
                    <OrderPaidStatus
                        total={detailOrder.totalPriceWithDiscount}
                        paid={detailOrder.totalAmountPaid}
                    />
                </Flex>
            </StyledContainer>
        </Flex>
    )
}
//
// const currentPayments: Payment[] = [
//     {
//         id: 0,
//         amount: 1000,
//         orderId: 0,
//         createdAt: new Date(),
//         status: PaymentStatus.Pending,
//
//         method: {
//             type: PaymentMethodName.Online,
//         },
//         comment: 'string',
//         paymentUrl: 'string',
//     },
//     {
//         id: 0,
//         amount: 1000,
//         orderId: 0,
//         createdAt: new Date(),
//         status: PaymentStatus.Completed,
//
//         method: {
//             type: PaymentMethodName.Online,
//         },
//         comment: 'string',
//         paymentUrl: 'string',
//     },
//     {
//         id: 0,
//         amount: 1000,
//         orderId: 0,
//         createdAt: new Date(),
//         status: PaymentStatus.Pending,
//
//         method: {
//             type: PaymentMethodName.Account,
//         },
//         comment: 'string',
//         paymentUrl: 'string',
//     },
//     {
//         id: 0,
//         amount: 1000,
//         orderId: 0,
//         createdAt: new Date(),
//         status: PaymentStatus.Completed,
//
//         method: {
//             type: PaymentMethodName.Certificate,
//         },
//         comment: 'string',
//         paymentUrl: 'string',
//     },
// ]
//
// const detailOrder: DetailOrder = {
//     counterpartyDiscount: 0,
//     counterpartyEmail: '',
//     counterpartyFullName: '',
//     counterpartyId: 0,
//     counterpartyPhone: '',
//     createdAt: '',
//     id: 0,
//     status: '',
//     totalPrice: 0,
//     totalPriceWithDiscount: 2000,
//     totalAmountPaid: 1000,
//     bookings: [
//         {
//             id: 0,
//             area: { id: 0, name: 'string' },
//             membersCount: 2,
//             comment: 'yth',
//             endTime: new Date(),
//             startTime: new Date(),
//             areaPrice: 1800,
//             areaAbsolutDiscount: 200,
//             areaPercentDiscount: 10,
//             areaTotalPrice: 200,
//             areaTotalPriceWithDiscount: 1800,
//             nomenclaturePrice: 0,
//             nomenclaturePriceWithDiscount: 0,
//             totalPrice: 2000,
//             totalPriceWithDiscount: 2000,
//             nomenclatures: [],
//         },
//     ],
// }
// const createdAt = moment().format('DD.MM.YYYY HH:MM')
