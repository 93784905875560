import React from 'react'

const AddRoundedIcon = () => (
    <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M3.35288 9.45287C4.00437 6.67545 6.17301 4.50681 8.95043 3.85532C10.9563 3.38482 13.0437 3.38482 15.0496 3.85532C17.827 4.50681 19.9956 6.67545 20.6471 9.45288C21.1176 11.4587 21.1176 13.5462 20.6471 15.552C19.9956 18.3294 17.827 20.4981 15.0496 21.1496C13.0437 21.6201 10.9563 21.6201 8.95044 21.1496C6.17301 20.4981 4.00437 18.3294 3.35288 15.552C2.88237 13.5462 2.88237 11.4587 3.35288 9.45287Z'
            stroke='white'
        />
        <path
            d='M14.5 12.5024H9.5M12 15.0024L12 10.0024'
            stroke='white'
            strokeLinecap='round'
        />
    </svg>
)

export default AddRoundedIcon
