import { Form, Formik, FormikHelpers } from "formik"
import { initialValues } from "../model/createProfile/initialValues"
import { validateSchema } from "../model/createProfile/validateSchema"
import { useState } from "react";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { StyledLine } from "shared/styles/GlobalStyle";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { GeneralData } from "../model/createProfile/GeneralData";
import { DocumentsData } from "../model/createProfile/DocumentsData";
import { PlaceData } from "../model/createProfile/PlaceData";
import { UploadFile } from "entities/uploadFiles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { postEmployees } from "../api/postEmployees";
import { EMPLOYEESPROFILE } from "shared/consts/routingConsts";

interface IPropsEmployeesCreate {
    setDisabled: (e: boolean) => void;
    setLoading: (e: boolean) => void;
    formikRef: React.Ref<FormikHelpers<any>>;
}

interface IPropsFile {
    id: number;
    name: string;
    file?: File;
}

export const EmployeesCreate = ({
    setDisabled,
    setLoading,
    formikRef
}:IPropsEmployeesCreate) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [files, setFiles] = useState<IPropsFile[]>([]);
    const [photo, setPhoto] = useState<File | null>(null);
    
    const { handleFileUpload } = useFileUploadUp();

    const handlePhotoChange = (photo: File) => {
        setPhoto(photo);
    };

    const handleSubmit = async (values: any, actions: any) => {
        setLoading(true);
        try {
            const { uploadedPhotoInfo, uploadedFilesInfo } = await handleFileUpload(photo, files?.map(file => file.file!) || []);
            
            const profileResponse = await postEmployees({
                ...values,
                profile_photo_hash_name: uploadedPhotoInfo ? uploadedPhotoInfo.hash_name : null,
                profile_photo_file_name: uploadedPhotoInfo ? uploadedPhotoInfo.hash_name : null,
                role: 2,
                user_ad_personal_info: {
                    ...values.user_ad_personal_info,
                    document_photos_to_create_hash_names: uploadedFilesInfo && files
                    ? uploadedFilesInfo.map((info, index) => ({
                        hash_name: info.hash_name,
                        file_name: files[index].name
                    }))
                    : [],
                }
            });


            if (profileResponse.status !== 201) {
                dispatch(showNotification({
                    message: "Ошибка",
                    type: "error"
                }));
                return;
            }

            dispatch(showNotification({
                message: "Успешно",
                type: "success"
            }));

            navigate(`${EMPLOYEESPROFILE}/${profileResponse.data.id}`);
            actions.setSubmitting(false);
        } catch (err) {
            console.error(err);
            actions.setSubmitting(false);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (newFiles: IPropsFile[]) => {
        setFiles(newFiles);
    };

    return (
        <Formik
            innerRef={formikRef as any}
            key="personalForm"
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validateSchema}
        >
            {({ isValid, dirty }) => {
                setDisabled(!(isValid && dirty));
                return (
                    <Form>
                        <UploadAvatar 
                            initialPhoto={null}
                            onPhotoChange={handlePhotoChange}
                        />
                        <StyledLine margin="20px 0"/>
                        <WrapperFormRow title="Общие данные" formData={GeneralData}  />
                        <WrapperFormRow title="Документы" formData={DocumentsData}>
                            <div className="row">
                                <UploadFile 
                                    file={[]} 
                                    disabled={true} 
                                    onChangeFile={handleFileChange}
                                />
                            </div>
                        </WrapperFormRow>
                        <WrapperFormRow title="Место прописки" formData={PlaceData}  />
                    </Form>
                )
            }}
        </Formik>
    )
}