import { DetailOrderDTO } from '../dtoTypes'
import { DetailOrder } from '../types'
import { adaptOrderDTO } from './adaptOrderDTO'
import { adaptBookingDTO } from './adaptBookingDTO'

export const adaptDetailOrderDTO = ({
    bookings,
    ...order
}: DetailOrderDTO): DetailOrder => ({
    ...adaptOrderDTO(order),
    counterpartyPhone: order.counterparty.phone,
    counterpartyEmail: order.counterparty.email,
    counterpartyFullName: order.counterparty.full_name,
    counterpartyDiscount: order.counterparty_discount,
    bookings: bookings?.map(adaptBookingDTO) ?? [],
    createdAt: order.created_at,
})
