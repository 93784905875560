import styled from "styled-components";

export const CheckoutPaymentsStyled = styled.div`
    background-color: #fff;
    .row {
        padding: 60px 80px;
    }
    .line {
        margin: 24px 0;
        width: 100%;
        height: 1px;
        background-color: #E3E8EF;
    }
`

export const DetailsPaymentsFormStyled = styled.div`
    width: 100%;
    max-width: 668px;
    margin: 0 auto;
    .row {
        padding-top: 60px;
    }
    .form {
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 20px 30px;
        .header {
            .title {
                color: #111729;
                font-size: 20px;
                font-weight: 600;
                line-height: 125%;
            }
        }
        .contentAccordionForm {
            padding-top: 20px;
        }
        .rowForm {
            display: flex;
            gap: 25px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            label {
                color: #111729;
                font-size: 18px;
                line-height: 165%;
            }
        }
    }
`

export const DetailsPaymentsTitle = styled.div`
    color: #111729;
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 125%;
    font-weight: bold;
`

export const WrapperItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
    .item {
        display: flex;
        justify-content: space-between;
        color: #8181A5;
        font-size: 16px;
        line-height: 165%;
        &__left {
            display: flex;
        }
        &-total {
            color: #282A42;
            margin-left: 10px;
        }
    }
`

export const Total = styled.div`
    display: flex;
    justify-content: space-between;
    color: #111729;
    font-size: 16px;
    font-weight: 600;
`