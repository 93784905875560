import moment from "moment"

export const initialValues = {
    name: '',
    square: '',
    price_per_hour: '',
    prepayment: '',
    photos_hash_names: [],
    ad_booking_info: {
        available_booking_time: '',
        available_online_booking_time: '',
        unavailable_refund_period_days: '',
        blocking_before_minutes: '',
        blocking_after_minutes: ''
    },
    ad_price_info: {
        more_than_three_hour_price_per_hour: '',
        morning_price_per_hour: '',
        evening_price_per_hour: '',
        morning_hours: '',
        evening_hours: '',
    }
}

export const getInitialValues = (data: any) => ({
    name: data.name || "",
    square: data.square || 0,
    price_per_hour: data.price_per_hour || null,
    prepayment: data.prepayment || null,
    ad_booking_info: data.ad_booking_info ? {
        available_booking_time: data.ad_booking_info.available_booking_time ? data.ad_booking_info.available_booking_time.map((item: any) => {
            return `${moment.utc(item.start, 'HH:mm:ss').format("HH:mm")} - ${moment.utc(item.end, 'HH:mm:ss').format("HH:mm")}`
        }).join(", ") : "",
        available_online_booking_time: data.ad_booking_info.available_online_booking_time ? data.ad_booking_info.available_online_booking_time.map((item: any) => {
            return `${moment.utc(item.start, 'HH:mm:ss').format("HH:mm")} - ${moment.utc(item.end, 'HH:mm:ss').format("HH:mm")}`
        }).join(", ") : "",
        unavailable_refund_period_days: data.ad_booking_info.unavailable_refund_period_days || null,
        blocking_before_minutes: data.ad_booking_info.blocking_before_minutes ? data.ad_booking_info.blocking_before_minutes : null,
        blocking_after_minutes: data.ad_booking_info.blocking_after_minutes ? data.ad_booking_info.blocking_after_minutes : null
    } : null,
    ad_price_info: data.ad_price_info ? {
        more_than_three_hour_price_per_hour: data.ad_price_info.more_than_three_hour_price_per_hour ? data.ad_price_info.more_than_three_hour_price_per_hour : null,
        morning_price_per_hour: data.ad_price_info.morning_price_per_hour || null,
        evening_price_per_hour: data.ad_price_info.evening_price_per_hour || null,
        morning_hours: data.ad_price_info.morning_hours ? `${moment.utc(data.ad_price_info.morning_hours.start, 'HH:mm:ss').format('HH:mm')} - ${moment.utc(data.ad_price_info.morning_hours.end, 'HH:mm:ss').format('HH:mm')}` : null,
        evening_hours: data.ad_price_info.evening_hours ? `${moment.utc(data.ad_price_info.evening_hours.start, 'HH:mm:ss').format('HH:mm')} - ${moment.utc(data.ad_price_info.evening_hours.end, 'HH:mm:ss').format('HH:mm')}` : null,
    } : null,
    photos: []
});
