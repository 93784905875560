import { AuthWrapper, Box, Wrapper, Title, ForgotPasswordLink } from "../style";
import { Input } from "shared/components/Input";
import { Button } from "shared/components/Button";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from "shared/redux/hooks";
import { login } from "features/auth/model/authSlice";
import { RootState } from "shared/redux/store";

interface IPropsAuthData {
    login: string;
    password: string;
}

interface IPropsLoginForm {
    setIsForgotPassword: (value: boolean) => void;
}

export const LoginForm = ({ setIsForgotPassword }: IPropsLoginForm) => {
    const dispatch = useAppDispatch();
    const { isAuthInProgress, error } = useAppSelector((state: RootState) => state.auth);

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: Yup.object({
            login: Yup.string().min(2).required("Логин обязателен!"),
            password: Yup.
                string().min(6).max(22)
                .matches(/^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/).required("Пароль обязателен!"),
        }),
        onSubmit: (values: IPropsAuthData, { setSubmitting }) => {
            const formattedUsername = values.login.replace(/\s+/g, '');
            dispatch(login({ login: formattedUsername, password: values.password }));
            setSubmitting(false);
        },
    });

    return (
        <AuthWrapper>
            <Box>
                <Wrapper onSubmit={formik.handleSubmit}>
                    <Title>Добро пожаловать!</Title>
                    <div className="wrapper-input">
                        <Input 
                            $beforeIcon="userLogin" 
                            type="phone" 
                            label="Телефон / email" 
                            placeholder="Введите email или номер телефона"
                            onChange={formik.handleChange}
                            value={formik.values.login}
                            name="login"
                            id="login"
                            autoComplete="off"
                            className={(formik.errors.login || error === "Ошибка входа!") ? "error" : ""}
                        />
                        <Input 
                            $beforeIcon="lock"
                            $afterIcon="hidden" 
                            type="password" 
                            label="Пароль" 
                            placeholder="Введите ваш пароль"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            name="password"
                            id="password"
                            className={(formik.errors.password || error === "Ошибка входа!") ? "error" : ""}
                        />  
                        {error === "Ошибка входа!" && (
                            <div className="error-message">Не верный логин или пароль</div>
                        )}
                    </div>
                    <ForgotPasswordLink onClick={() => setIsForgotPassword(true)}>
                        Забыли пароль?
                    </ForgotPasswordLink>
                    <Button className="buttonAuth" type="submit" disabled={!formik.isValid || !formik.dirty || isAuthInProgress}>Войти</Button>
                </Wrapper>
            </Box>
        </AuthWrapper>
    );
}
