import moment from "moment";

export const GeneralData = [
    [
        { 
            name: "user_ad_personal_info.first_name", 
            label: "Имя", 
            placeholder: "Иван", 
            type: "text",
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            disabled: false,
            required: true,
        },
        { 
            name: "user_ad_personal_info.surname", 
            label: "Фамилия", 
            placeholder: "Иванов", 
            type: "text",
            disabled: false,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
            required: true,
        },
        { 
            name: "user_ad_personal_info.patronymic", 
            label: "Отчество", 
            placeholder: "Иванович", 
            type: "text",
            disabled: false,
            maskConfig: { mask: /^[A-Za-zА-Яа-яЁё\s]*$/ },
        },
        { 
            name: "role", 
            label: "Роль", 
            placeholder: "Укажите роль",
            type: "text",
            disabled: true
        },
    ],
    [
        { 
            name: "phone", 
            label: "Телефон", 
            placeholder: "+7-999-999-9999", 
            maskConfig: { mask: '+{7}-000-000-00-00' }, 
            disabled: false, 
            type: "text",
            required: true
        },
        { 
            name: "email", 
            label: "Почта", 
            type: "email",
            disabled: false,
            placeholder: "Ivanov.Ivan@gmail.com",
            required: true
        },
        { 
            name: "username", 
            label: "Логин", 
            type: "text",
            disabled: false,
            placeholder: "Введите логин",
            required: true
        },
        { 
            name: "password", 
            label: "Пароль", 
            type: "password",
            disabled: false,
            placeholder: "123456789",
            required: true
        },
        { 
            name: "at_create", 
            label: "Дата регистрации",
            value: moment().format("DD.MM.YYYY"),
            disabled: true,
            placeholder: moment().format("DD.MM.YYYY"), 
            type: "text" 
        },
    ]
];