import { axiosApi } from 'shared/api/axios'
import { adaptCounterpartyDTO } from 'entities/counterparties/model/adapters/adaptCounterpartyDTO'
import { CounterpartyDto } from '../model/dtoTypes'
import { Counterparty } from '../model'

export const getCounterpartyById = async (
    id: number,
): Promise<Counterparty> => {
    try {
        const response = await axiosApi.get<CounterpartyDto>(
            `/api/v1/counterparties/counterparty/${id}`,
        )
        return adaptCounterpartyDTO(response.data)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка')
    }
}
