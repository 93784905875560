import React, { FC } from 'react'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import { Autocomplete } from 'shared/components/Autocomplete'
import { useGeneralInfoController } from '../../../model/hooks'
import { AutocompleteRow, BoldText } from './styles'
import { useCounterpartiesOptions } from '../../../model/hooks/useGeneralInfoCounterpartiesHelpers'

interface GenericInfoFormProps {
    isPreview: boolean
    isCompany?: boolean
}

export const GenericInfoForm: FC<GenericInfoFormProps> = ({
    isCompany,
    isPreview,
}) => {
    const {
        isLoading,
        orderId,
        isCreate,
        isButtonDisabled,
        isOneOfTheBookingsAlreadyCreated,
        onGoNext,
        onCounterpartyChange,
        createCounterpartyAndChange,
    } = useGeneralInfoController(isCompany)

    const {
        optionInputValue,
        currentCounterpartyOption,
        counterpartiesOptions,
        currentCounterparty,
        fetchNextPage,
        isFetching,
        onChangeNumber,
        onChangeName,
        onChangeInn,
    } = useCounterpartiesOptions(isCompany)

    return (
        <div className='content'>
            {!isCreate && (
                <div className='row' style={{ flexWrap: 'nowrap' }}>
                    <Input
                        name=''
                        label='Номер заказа'
                        value={orderId?.toString()}
                        disabled
                    />
                    <Input name='' value='Редактирование' disabled />
                </div>
            )}
            <AutocompleteRow>
                <Autocomplete
                    inputValue={optionInputValue.phone}
                    disabled={isLoading || isPreview}
                    value={currentCounterpartyOption.phone ?? null}
                    onChange={onCounterpartyChange}
                    options={counterpartiesOptions.phone}
                    label='Контактный телефон'
                    placeholder='Введите номер телефона...'
                    onLoadMore={fetchNextPage}
                    onInputChange={onChangeNumber}
                    isLoading={isFetching}
                    onCreateOption={phone =>
                        createCounterpartyAndChange({ phone })
                    }
                    required
                />
            </AutocompleteRow>

            {isCompany ? (
                <>
                    <Autocomplete
                        inputValue={optionInputValue.name}
                        disabled={isLoading || isPreview}
                        value={currentCounterpartyOption.name ?? null}
                        onChange={onCounterpartyChange}
                        options={counterpartiesOptions.name}
                        label='Наименование юр. лица'
                        placeholder='Введите наименование...'
                        onLoadMore={fetchNextPage}
                        onInputChange={onChangeName}
                        isLoading={isFetching}
                        onCreateOption={organizationName =>
                            createCounterpartyAndChange({ organizationName })
                        }
                        required
                    />
                    <Autocomplete
                        inputValue={optionInputValue.inn}
                        disabled={isLoading || isPreview}
                        value={currentCounterpartyOption.inn ?? null}
                        onChange={onCounterpartyChange}
                        options={counterpartiesOptions.inn}
                        label='ИНН'
                        placeholder='Введите ИНН...'
                        onLoadMore={fetchNextPage}
                        onInputChange={onChangeInn}
                        isLoading={isFetching}
                        onCreateOption={inn =>
                            createCounterpartyAndChange({ inn })
                        }
                        required
                    />
                    <BoldText>Контактные данные связующего лица</BoldText>
                    <Input
                        label='Контактный телефон'
                        value={currentCounterparty.contactPhone ?? ''}
                        required
                        disabled
                    />
                    <Input
                        label='Контактный e-mail'
                        value={currentCounterparty.contactEmail ?? ''}
                        required
                        disabled
                    />
                    <Input
                        label='Контактный ФИО'
                        value={currentCounterparty.name ?? ''}
                        required
                        disabled
                    />
                </>
            ) : (
                <>
                    <Input
                        label='ФИО'
                        value={currentCounterparty.name ?? ''}
                        required
                        disabled
                    />
                    <Input
                        label='Контактный Email'
                        value={currentCounterparty.email ?? ''}
                        required
                        disabled
                    />
                </>
            )}

            {!isPreview && (
                <Button disabled={isButtonDisabled} onClick={onGoNext}>
                    {isOneOfTheBookingsAlreadyCreated ? 'Сохранить' : 'Далее'}
                </Button>
            )}
        </div>
    )
}
