// @ts-expect-error lib doesn't have types
import StringMask from 'string-mask'

export const useNumberMask = (
    mask: string,
    stringToExcludeOnUnmask?: string,
) => {
    const numberFormatter = new StringMask(mask)

    const unmask = (value: string) =>
        stringToExcludeOnUnmask && value.startsWith(stringToExcludeOnUnmask)
            ? value.replace(stringToExcludeOnUnmask, '').replace(/\D/g, '')
            : value.replace(/\D/g, '')

    const onInput = (value: string) => {
        const unmaskedValue = unmask(value)
        return numberFormatter.apply(unmaskedValue)
    }

    const onKeyDown = (value: string) => {
        const actualValue = onInput(value)
        const lastChar = actualValue.slice(-1)
        const isSpecialSymbol = Number.isNaN(Number(lastChar))

        if (isSpecialSymbol) {
            return value.slice(0, -1)
        }
        return onInput(value)
    }

    return { onInput, onKeyDown }
}
