import { OrganisationDTO } from 'entities/organisations/model/dtoTypes'
import { Organisation } from 'entities/organisations/model/types'

export const adaptOrganisationDto = (
    source: OrganisationDTO,
): Organisation => ({
    id: source.id,
    name: source.name,
    ceo: source.ceo,
    inn: source.inn,
    legalAddress: source.legal_address,
})
