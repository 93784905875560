import { useThemeContext } from 'app/providers/providers';
import React from 'react';


const UserIcon = () => {
    const { theme } = useThemeContext();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M3.75 23.0413C3.75 20.5385 5.56856 18.4067 8.04004 18.0123L8.26266 17.9768C10.2417 17.6609 12.2583 17.6609 14.2373 17.9768L14.46 18.0123C16.9314 18.4067 18.75 20.5385 18.75 23.0413C18.75 24.1231 17.8731 25 16.7913 25H5.70871C4.62694 25 3.75 24.1231 3.75 23.0413Z" stroke={theme.themeColors.main}/>
            <path d="M15.625 9.375C15.625 11.7912 13.6662 13.75 11.25 13.75C8.83375 13.75 6.875 11.7912 6.875 9.375C6.875 6.95875 8.83375 5 11.25 5C13.6662 5 15.625 6.95875 15.625 9.375Z" stroke={theme.themeColors.main} />
            <path d="M18.75 13.75C21.1662 13.75 23.125 11.7912 23.125 9.375C23.125 6.95875 21.1662 5 18.75 5M21.7373 25H24.2913C25.3731 25 26.25 24.1231 26.25 23.0413C26.25 20.5385 24.4314 18.4067 21.96 18.0123V18.0123C21.8118 17.9886 21.6617 17.9768 21.5116 17.9768C21.1119 17.9768 20.9878 17.9768 20.3015 17.9768" stroke={theme.themeColors.main} strokeLinecap="round"/>
        </svg>
    )
}

export default UserIcon;