import { CreateCertificatePaymentRequestDTO } from 'entities/payments/model/dtoTypes'
import { CreateCertificatePaymentRequest } from 'entities/payments/model/types'

export const adaptCreateCertificatePaymentRequest = (
    body: CreateCertificatePaymentRequest,
): CreateCertificatePaymentRequestDTO => ({
    order_id: body.orderId,
    certificate_id: body.certificateId,
    comment: body.comment,
})
