import { axiosApi } from 'shared/api/axios'
import { PaymentEndpoint } from 'entities/payments/api/contants'
import { PaymentMethodName, RefundPaymentRequest } from 'entities/payments/model'

export const refundOrder = async (orderId: number, body: RefundPaymentRequest) => {
    try {
        await axiosApi.post(
            PaymentEndpoint.RefundPayment(orderId),
            {
                ...body,
                type: PaymentMethodName.Cash
            }
        )
    } catch (err) {
        console.error('Ошибка возврата', err)
        return null
    }
}
