export const trafic = [
    {
        name: "Google",
        procent: "13"
    },
    {
        name: "YouTube",
        procent: "13"
    },
    {
        name: "Instagram",
        procent: "13"
    },
    {
        name: "Pinterest",
        procent: "13"
    },
    {
        name: "Facebook",
        procent: "13"
    },
    {
        name: "Twitter",
        procent: "13"
    },
    {
        name: "Google",
        procent: "13"
    },
];

export const card = [ 
    { 
        icon: "add", 
        title: "Выручка", 
        keyData: "total_revenue", 
        showCurrency: true, 
    }, 
    { 
        icon: "remove", 
        title: "Отменено броней", 
        amount: "2", 
        keyData: "total_canceled_bookings" 
    }, 
    { 
        icon: "rotate", 
        title: "Возвраты", 
        keyData: "total_returns", 
        showCurrency: true, 
    }, 
    { 
        icon: "chart", 
        title: "Количество заказов", 
        keyData: "total_day_orders" 
    }, 
];

export const dropdownPeriod = ["Сегодня", "Неделя", "Месяц"];