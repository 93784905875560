import { useEffect, useState } from "react";
import { StyledUploadAvatar } from "./style"
import { Icon } from "shared/components/Icons";

interface IPropsUploadAvatar {
    initialPhoto?: string | null;
    onPhotoChange: (file: File) => void;
    onPhotoChangeWithBase64?: (base64: string, file: Blob) => void;
}

export const UploadAvatar = ({
    initialPhoto,
    onPhotoChange,
    onPhotoChangeWithBase64
}:IPropsUploadAvatar) => {
    const [image, setImage] = useState<string | null>(initialPhoto ?? null);
    const [localImage, setLocalImage] = useState<string | null>(null);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            onPhotoChange(file);
            const reader = new FileReader();
            reader.onload = (loadEvent) => {
                if (loadEvent.target) {
                    const base64 = loadEvent.target.result as string;
                    setLocalImage(base64);
                    setImage(base64);
                    onPhotoChangeWithBase64 && onPhotoChangeWithBase64(base64, file);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (!localImage) {
            setImage(initialPhoto ?? null);
        }
    }, [initialPhoto, localImage]);

    return (
        <StyledUploadAvatar>
            <div className="avatar">
                {localImage ? (
                    <img src={localImage} alt="локальное фото" />
                ) : (
                    image ? (
                        <img src={image} alt="фото" />
                    ) : (
                        <div className="circle"></div>
                    )
                )}
            </div>
            <div className="uploadAvatarButton">
                <label htmlFor="fileInput">
                    <span>Загрузить фотографию</span>
                    <Icon name="download" />
                    <input 
                        type="file" 
                        style={{ display: 'none' }} 
                        accept="image/*" 
                        onChange={handleImageChange} 
                        id="fileInput"
                    />
                </label>
            </div>
        </StyledUploadAvatar>
    )
}