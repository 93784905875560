import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { OrdersApi } from 'entities/orders/api'
import {
    BookingMode,
    BookingStep,
    selectBookingCounterpartyId,
    selectBookingMode,
    selectBookingOrderId,
    selectBookingOriginalOrder,
    setBookingCounterpartyId,
    setBookingCounterpartyName,
    setBookingCurrentStep,
    setBookingOrderId,
} from '../slices'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'
import { CLIENTSCREATE } from 'shared/consts/routingConsts'

import { DynamicCreationReducersType } from 'pages/clientsCreate/model/dynamicCreationReducers'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { showNotification } from 'shared/redux/slice/notificationSlice'

export const useGeneralInfoController = (isCompany?: boolean) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const orderId = useSelector(selectBookingOrderId)
    const originalOrder = useSelector(selectBookingOriginalOrder)

    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const mode = useSelector(selectBookingMode)
    const isCreate = useMemo(() => mode === BookingMode.Create, [mode])
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const [isLoading, setIsLoading] = useState(false)

    const { isOneOfTheBookingsAlreadyCreated } = useIsBookingsAlreadyCreated()

    const isButtonDisabled = !counterpartyId

    const onCounterpartyChange = (option: AutocompleteOption) => {
        const newOption = option ? Number(option.value) : null
        dispatch(setBookingCounterpartyId({ id: newOption }))
        dispatch(setBookingCounterpartyName({ name: option.label ?? '' }))
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const showErrorAlert = () => {
        dispatch(
            showNotification({
                message: 'Ошибка',
                type: 'error',
            }),
        )
    }

    const onGoNext = async () => {
        try {
            if (isEdit) {
                dispatch(setBookingCurrentStep({ step: BookingStep.Booking }))
                if (
                    !counterpartyId ||
                    !orderId ||
                    !originalOrder?.counterpartyId
                ) {
                    return
                }

                if (counterpartyId === originalOrder?.counterpartyId) {
                    return
                }

                setIsLoading(true)
                await OrdersApi.updateOrder({
                    orderId,
                    counterpartyId: Number(counterpartyId),
                })
                setIsLoading(false)
                showSuccessAlert()
                return
            }

            if (!counterpartyId) {
                return
            }

            dispatch(setBookingCurrentStep({ step: BookingStep.Booking }))

            if (isOneOfTheBookingsAlreadyCreated && orderId) {
                setIsLoading(true)
                await OrdersApi.updateOrder({
                    orderId,
                    counterpartyId: Number(counterpartyId),
                })
                setIsLoading(false)
                showSuccessAlert()
                return
            }

            setIsLoading(true)
            const result = await OrdersApi.createOrder({
                counterpartyId: Number(counterpartyId),
            })
            dispatch(setBookingOrderId({ orderId: result.orderId }))
            showSuccessAlert()
        } catch {
            showErrorAlert()
        } finally {
            setIsLoading(false)
        }
    }

    const createCounterpartyAndChange = ({
        phone,
        organizationName,
        inn,
    }: {
        phone?: string
        organizationName?: string
        inn?: string
    }) => {
        navigate(CLIENTSCREATE, {
            state: {
                initPersonFormData: {
                    phone: phone ?? '',
                },
                initCompanyFormData: {
                    phone: phone ?? '',
                    additional_data: {
                        documents: {
                            organization_name: organizationName ?? '',
                            inn: inn ?? '',
                        },
                    },
                },
                defaultActiveTab: isCompany ? 1 : 0,
                dynamicCreationReducerType: DynamicCreationReducersType.Booking,
            },
        })
    }

    return {
        isLoading,
        orderId,
        isCreate,
        isEdit,
        isButtonDisabled,
        isOneOfTheBookingsAlreadyCreated,
        onGoNext,
        onCounterpartyChange,
        createCounterpartyAndChange,
    }
}
