import React, { FC } from 'react'

import { BookingPreview, LoaderPreview } from 'features/OrderPreview/ui'
import { useGetDetailBookingQuery } from 'entities/bookings/model'
import { useGetCounterpartyByIdQuery } from 'entities/counterparties/model'
import { useGetDetailOrderQuery } from 'entities/orders/model'

interface CardBookingProps {
    id: number
}

// TODO: remove extra hooks when API will be aligned
export const CardBooking: FC<CardBookingProps> = ({ id }) => {
    const { booking } = useGetDetailBookingQuery(id)

    const { detailOrder } = useGetDetailOrderQuery(booking?.orderId ?? null)
    const currentBooking = detailOrder?.bookings?.find(bkg => bkg.id === id)

    const { counterparty } = useGetCounterpartyByIdQuery(
        booking?.counterparty?.id ?? null,
    )

    if (!currentBooking || !counterparty) {
        return <LoaderPreview />
    }

    return (
        <BookingPreview
            detailOrder={detailOrder}
            bookingToPreview={currentBooking}
            counterparty={counterparty}
        />
    )
}
