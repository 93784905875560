import { FC } from 'react'
import { Counterparty } from 'entities/counterparties/model'
import { DetailOrder } from 'entities/orders/model'
import { DetailsPaymentsForm } from 'features/DetailsPayments/ui/DetailsPaymentsForm'
import { CheckoutPayments } from 'features/DetailsPayments/ui/CheckoutPayments'
import { CreateAccountPaymentRequest } from 'entities/payments/model'

export type AccountDetails = Omit<CreateAccountPaymentRequest, 'orderId' | 'comment'>
export type onPaymentProcess = (details: AccountDetails) => void

export interface DetailsPaymentsModalProps {
    detailOrder: DetailOrder
    counterparty: Counterparty
    onPaymentProcess: onPaymentProcess
    details?: AccountDetails
}

export const DetailsPaymentsModal: FC<DetailsPaymentsModalProps> = ({
    detailOrder,
    counterparty,
    onPaymentProcess,
    details,
}) => (
    <div
        style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            height: '100%',
            gap: 10,
            overflowY: 'auto'
        }}
    >
        <DetailsPaymentsForm
            detailOrder={detailOrder}
            counterparty={counterparty}
            onPaymentProcess={onPaymentProcess}
            details={details}
        />
        <CheckoutPayments
            detailOrder={detailOrder}
            counterparty={counterparty}
        />
    </div>
)
