import { FormikInput } from "../FormikInput";
import { WrapperFormRowStyled } from "./style"

interface IPropsData {
    value?: string | number;
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    type: 'text' | 'email' | 'password' | 'number' | string; 
    required?: boolean;
    maskConfig?: any;
    component?: React.ComponentType<any>;
}

interface IPropsWrapperFormRow {
    title?: string;
    formData: Array<Array<IPropsData>>;
    children?: React.ReactNode
}

export const WrapperFormRow = ({
    title,
    formData,
    children
}: IPropsWrapperFormRow) => {
    return (
        <WrapperFormRowStyled>
            {title && <div className="title">{title}</div>}
            <div className="container">
                {formData.map((dataRow, index) => (
                    <div className="row" key={index}>
                        {dataRow.map((item, itemIndex) => {
                            if (item.type === 'custom' && item.component) {
                                const CustomComponent = item.component;
                                return (
                                    <CustomComponent
                                        key={itemIndex}
                                        name={item.name}
                                        label={item.label}
                                        placeholder={item.placeholder}
                                        disabled={item.disabled}
                                        value={item.value}
                                    />
                                );
                            }
                            return (
                                <FormikInput
                                    required={item.required}
                                    key={itemIndex}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    disabled={item.disabled ?? false}
                                    value={item.value ?? ""}
                                    maskConfig={item.maskConfig}
                                    className="inputForm"
                                    $afterIcon="clearInput"
                                />
                            );
                        })}
                    </div>
                ))}
                {children}
            </div>
        </WrapperFormRowStyled>
    );
};
