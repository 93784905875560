import styled from "styled-components";

export const StyledItem = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    .wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            color: #8181A5;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.06px;
        }
    }
    a, button {
        background: none;
        cursor: pointer;
        height: 39px;
        width: 39px;
        border: 1px solid #E1E4EA;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s linear;
        &:hover {
            background: linear-gradient(0deg, rgba(94, 129, 244, 0.10) 0%, rgba(94, 129, 244, 0.10) 100%),#FFF;
            border: none;
            transition: all 0.4s linear;
        }
        div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;