import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import {
    selectActiveBookingId,
    selectBookings,
    selectTimeFilter,
} from '../slices'
import { useGetBookingsListQuery } from 'entities/bookings/model'
import { OrderBooking } from 'entities/orders/model'

export const useIsBookingsAlreadyCreated = () => {
    const activeBookingId = useSelector(selectActiveBookingId)
    const bookings = useSelector(selectBookings)
    const activeBooking = useMemo(
        () => bookings.find(a => a.id === activeBookingId),
        [bookings, activeBookingId],
    )

    const timeFilter = useSelector(selectTimeFilter)
    const {
        bookings: alreadyCreatedBookings,
        refetch: refetchAlreadyCreatedBookings,
    } = useGetBookingsListQuery(timeFilter)

    const checkIsBookingAlreadyCreated = (booking?: OrderBooking | null) =>
        alreadyCreatedBookings.some(bkg => bkg.id === booking?.id)

    const isActiveBookingAlreadyCreated =
        checkIsBookingAlreadyCreated(activeBooking)

    const isOneOfTheBookingsAlreadyCreated = bookings.some(bkg =>
        checkIsBookingAlreadyCreated(bkg),
    )

    return {
        isActiveBookingAlreadyCreated,
        isOneOfTheBookingsAlreadyCreated,

        checkIsBookingAlreadyCreated,
        refetchAlreadyCreatedBookings,
    }
}
