import { axiosApi } from 'shared/api/axios'
import { OrderEndpoint } from './constants'
import { CreateOrderRequest } from '../model'
import { CreateOrderResponseDTO } from '../model/dtoTypes'

export const createOrder = async ({ counterpartyId }: CreateOrderRequest) => {
    try {
        const response = await axiosApi.post<CreateOrderResponseDTO>(
            OrderEndpoint.order,
            {
                counterparty_id: counterpartyId,
            },
        )
        return { orderId: response.data.order_id }
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
