import { getAllPaymentsList } from 'entities/payments/api/getAllPaymentsList'
import { createCashPayment } from 'entities/payments/api/createCashPayment'
import { createOnlinePayment } from 'entities/payments/api/createOnlinePayment'
import { createCertificatePayment } from 'entities/payments/api/createCertificatePayment'
import { getPaymentsByOrderId } from 'entities/payments/api/getPaymentsByOrderId'
import { getPaymentMethods } from 'entities/payments/api/getPaymentMethods'
import { createAccountPayment } from 'entities/payments/api/createAccountPayment'
import { confirmAccountPayment } from 'entities/payments/api/confirmAccountPayment'
import { refundOrder } from 'entities/payments/api/refundOrder'

export const PaymentApi = {
    getAllPaymentsList,
    createCashPayment,
    createCertificatePayment,
    createOnlinePayment,
    getPaymentsByOrderId,
    getPaymentMethods,
    createAccountPayment,
    confirmAccountPayment,
    refundOrder,
}
