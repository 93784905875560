import { FC } from 'react'

import { CounterpartyType } from '../../../../entities/counterparties/model'
import { Input } from 'shared/components/Input'

import { BoldText } from './styles'
import { CounterpartyDetailsProps } from '../BookingPreview/types'

export const CounterpartyDetails: FC<CounterpartyDetailsProps> = ({
    counterparty,
}) => {
    if (counterparty?.type === CounterpartyType.Company) {
        return <CompanyCounterpartyDetails counterparty={counterparty} />
    }
    return <PersonCounterpartyDetails counterparty={counterparty} />
}

const CompanyCounterpartyDetails: FC<CounterpartyDetailsProps> = ({
    counterparty,
}) => (
    <>
        <Input
            name=''
            label='Номер Телефона'
            value={counterparty.phone ?? ''}
            disabled
        />
        <Input
            name=''
            label='Наименование юр. лица'
            value={
                counterparty?.additionalData?.documents?.organizationName ?? ''
            }
            disabled
        />
        <Input
            name=''
            label='ИНН'
            value={counterparty?.additionalData?.documents?.inn ?? ''}
            disabled
        />
        <BoldText>Контактные данные связующего лица</BoldText>
        <Input
            label='Контактный телефон'
            value={counterparty?.contact?.phone ?? ''}
            disabled
        />
        <Input
            label='Контактный e-mail'
            value={counterparty?.contact?.email ?? ''}
            disabled
        />
        <Input
            label='Контактный ФИО'
            value={
                counterparty
                    ? [
                          counterparty?.contact?.surname,
                          counterparty?.contact?.firstName,
                          counterparty?.contact?.patronymic,
                      ].join(' ')
                    : ''
            }
            disabled
        />
    </>
)

const PersonCounterpartyDetails: FC<CounterpartyDetailsProps> = ({
    counterparty,
}) => (
    <>
        <Input
            label='ФИО'
            value={
                counterparty
                    ? [
                          counterparty?.contact?.surname,
                          counterparty?.contact?.firstName,
                          counterparty?.contact?.patronymic,
                      ].join(' ')
                    : ''
            }
            disabled
        />
        <Input
            label='Контактный Email'
            value={counterparty?.email ?? ''}
            disabled
        />
    </>
)
