export const GeneralData = [
    [
        {
            name: "bankName",
            label: "Название банка", 
            placeholder: "Название банка", 
            type: "text",
            disabled: false,
            required: true,
        },
        { 
            name: "bik", 
            label: "БИК банка", 
            placeholder: "БИК банка", 
            type: "text",
            disabled: false,
            required: true,
            maskConfig: {
                mask: Number
            },
        },
        { 
            name: "number", 
            label: "Расчетный счет", 
            placeholder: "Расчетный счет", 
            type: "text",
            disabled: false,
            required: true,
            maskConfig: {
                mask: Number,
            },
        },
        { 
            name: "correspondentNumber",
            label: "Кореспонденческий счет", 
            placeholder: "Кореспонденческий счет", 
            type: "text",
            disabled: false,
            required: true,
            maskConfig: {
                mask: Number,
            },
        },
    ],
];