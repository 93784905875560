import React from 'react'
import { InputContainer, Label } from 'shared/components/Input/style'
import { StyledSelect, StyledCreatableSelect } from './styles'

export type AutocompleteOption<T extends object = object> = {
    value: string
    label: string
    payload?: T
}

export interface AutocompleteProps<T extends object> {
    options: AutocompleteOption<T>[]
    onChange: (option: AutocompleteOption<T>) => void // Используем тот же T для onChange
    value?: AutocompleteOption<T> | null
    label?: string
    placeholder?: string
    disabled?: boolean
    required?: boolean

    onCreateOption?: (text: string) => void
    onLoadMore?: () => void
    onInputChange?: (text: string) => void
    inputValue?: string
    isLoading?: boolean
}

export const Autocomplete = <T extends object>({
    value,
    options,
    onChange,
    placeholder,
    disabled,
    label,
    required,
    inputValue,
    onLoadMore,
    onInputChange,
    isLoading,
    onCreateOption,
}: AutocompleteProps<T>) => {
    const props = {
        classNames: {
            control: () => 'control',
            input: () => 'input',
            placeholder: () => 'placeholder',
        },
        value,
        onChange: onChange as any,
        options,
        isClearable: true,
        placeholder,
        isDisabled: disabled,
        onMenuScrollToBottom: onLoadMore,
        onInputChange,
        isLoading,
        inputValue,
    }

    const SelectComponent = onCreateOption ? (
        <StyledCreatableSelect {...props} onCreateOption={onCreateOption} />
    ) : (
        <StyledSelect {...props} />
    )

    return (
        <InputContainer>
            {label && (
                <Label>
                    {required && <span className='required'>*</span>}
                    {label}
                </Label>
            )}
            {SelectComponent}
        </InputContainer>
    )
}
