import { NomenclatureDTO } from '../dtoTypes'
import { Nomenclature } from '../types'

export const adaptNomenclatureDTO = (
    nomenclatureDTO: NomenclatureDTO,
): Nomenclature => ({
    id: nomenclatureDTO.id,
    name: nomenclatureDTO?.name ?? null,
    code: nomenclatureDTO.code,
    photo: {
        hashName: nomenclatureDTO.photo?.hash_name ?? null,
        readUrl: nomenclatureDTO.photo?.read_url ?? null,
    },
    type: nomenclatureDTO.type,
    count: nomenclatureDTO.count,
    price: nomenclatureDTO.price,
    comment: nomenclatureDTO.comment,
    areaId: nomenclatureDTO.area_id_id,
    totalEarned: nomenclatureDTO.total_earned,
    avgSalesPerDay: nomenclatureDTO.avg_sales_per_day,
})
