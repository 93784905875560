import { axiosApi } from 'shared/api/axios'
import { adaptDetailOrderDTO } from '../model/adapters/adaptDetailOrder'
import { OrderEndpoint } from './constants'
import { DetailOrderDTO } from '../model/dtoTypes'

export const getDetailOrder = async (orderId: number) => {
    try {
        const response = await axiosApi.get<DetailOrderDTO>(
            `${OrderEndpoint.order}${orderId}`,
        )
        return adaptDetailOrderDTO(response.data)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получения списка заказов')
    }
}
