import { ChangeEvent, DragEvent, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectBookingOrderId } from 'features/Booking/model/slices'
import { useGetAllDocumentsQuery } from 'entities/orderAttachments/model'
import { AttachmentsApi } from 'entities/orderAttachments/api'
import { uploadFile } from 'shared/utils/upload'
import useDownloadFile from 'shared/hooks/useDownloadFile'
import { showNotification } from 'shared/redux/slice/notificationSlice'

export const useDocumentFormController = () => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const orderId = useSelector(selectBookingOrderId)
    const downloadFile = useDownloadFile()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const { documents, refetch } = useGetAllDocumentsQuery(orderId)

    const onFileInputClick = () => {
        fileInputRef.current?.click()
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const showErrorAlert = () => {
        dispatch(
            showNotification({
                message: 'Ошибка',
                type: 'error',
            }),
        )
    }

    const handleFiles = async (fileList: FileList) => {
        if (!orderId) {
            return
        }
        try {
            setIsLoading(true)
            const uploadedFiles = await Promise.all(
                Array.from(fileList).map(async file => {
                    const uploadedFile = await uploadFile(file)
                    console.log('uploadedFile', uploadedFile)

                    return {
                        hashName: uploadedFile.hash_name,
                        fileName: file.name,
                    }
                }),
            )
            await AttachmentsApi.addDocumentsToOrder(orderId, uploadedFiles)
            await refetch()
            showSuccessAlert()
        } catch (error) {
            console.log(error)
            showErrorAlert()
        } finally {
            setIsLoading(false)
        }
    }

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (e.target.files) {
            await handleFiles(e.target.files)
        }
    }

    const onFileDropClick = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (e.dataTransfer.files) {
            await handleFiles(e.dataTransfer.files)
        }
    }

    const onOverDnD = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }

    const removeFile = async (id: number) => {
        try {
            setIsLoading(true)
            await AttachmentsApi.deleteDocumentFromOrder(id)
            await refetch()
            showSuccessAlert()
        } catch (error) {
            console.log(error)
            showErrorAlert()
        } finally {
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        fileInputRef,
        documents,
        onFileInputClick,
        onChange,
        onFileDropClick,
        onOverDnD,
        removeFile,
        downloadFile,
    }
}
