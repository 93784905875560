import { getBookings } from './getBookings'
import { updateBookingNomenclature } from './updateBookingNomenclature'
import { updateBookings } from './updateBooking'
import { deleteNomenclaturesFromBooking } from './deleteNomenclatureFromBooking'
import { addNomenclaturesToBooking } from './addNomenclaturesToBooking'
import { getBookingAhead } from 'entities/bookings/api/getBookingAhead'
import { getDetailBooking } from 'entities/bookings/api/getDetailBooking'

export const BookingsApi = {
    getBookings,
    updateBookingNomenclature,
    updateBookings,
    deleteNomenclaturesFromBooking,
    addNomenclaturesToBooking,
    getBookingAhead,
    getDetailBooking,
}
