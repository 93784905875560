import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/react'
import { dropdownPeriod } from 'pages/dashboard/statics'
import { Fragment } from 'react'
import { DropdownButton } from './style'

interface IPropsDropdownPeriod {
    selected: Iterable<any>
    setSelected: (value: string) => void
}

export const DropdownPeriod = ({
    selected,
    setSelected,
}: IPropsDropdownPeriod) => {
    return (
        <Listbox value={selected} onChange={setSelected}>
            <ListboxButton as={Fragment}>
                <DropdownButton>
                    {selected}
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='25'
                        viewBox='0 0 24 25'
                        fill='none'
                    >
                        <path
                            d='M17 10L12 15L7 10'
                            stroke='#363853'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                </DropdownButton>
            </ListboxButton>
            <ListboxOptions className='dropdown-block' anchor='bottom'>
                {dropdownPeriod.map((item, index) => (
                    <ListboxOption
                        className={`${item === selected ? 'active' : ''} dropdown-block__item`}
                        key={index}
                        value={item}
                    >
                        {item}
                    </ListboxOption>
                ))}
            </ListboxOptions>
        </Listbox>
    )
}
